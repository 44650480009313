@import 'src/assets/styles/utils/index.module.scss';

.container {
  &.graphInRow {
    display: flex;

    & > div {
      flex: 1;

      &:first-of-type {
        margin-right: 10px;
      }

      &:nth-of-type(2) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 10px;
      }
    }
  }
}

.tradeContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  padding-top: 15px;
  border-radius: 5px;
  background: linear-gradient(7.23deg, $color-white 19.12%, #fbfbfb 87.09%);

  .withPadding {
    padding-right: 8px;
    padding-left: 15px;
  }

  &.graphInRow {
    background: transparent;
  }
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 16px;
}

.headerWithSubtitle {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.headerTitle {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.6rem;
}

.headerSubtitle {
  margin-left: 5px;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 1.5rem;
}

.housesTags {
  margin-top: 10px;
}

.bidsAndOfferTags {
  margin-bottom: 15px;
}

.chartsWrapper {
  &.chartsInRow {
    display: flex;

    & > div {
      flex: 1;
    }
  }
}
