.selfChartsWrapper {
  & > div {
    margin-bottom: 20px;
  }
}

.rowDisplay {
  display: flex;

  & > div {
    margin-bottom: 0;

    &:first-of-type {
      margin-right: 10px;
    }

    &:last-of-type {
      margin-left: 10px;
    }
  }
}
