@import 'src/assets/styles/utils/index.module.scss';

.container {
  display: flex;
  height: 100%;

  button {
    height: 23px;
    margin-left: 10px;
    font-size: 12px;
    line-height: 14px;
    color: $color-pretty-dark;
    border-radius: 15px;
  }

  .dateButton {
    background: transparent;

    &.selected {
      background: $color-oh-so-green;
    }
  }
}
