@import 'src/assets/styles/utils/index.module.scss';

.container {
  position: absolute;
  top: 30px;
  right: 65px;
}

.modal {
  position: relative;
  padding: 15px 12px;
  border-radius: 8px;
  background: linear-gradient(10.13deg, #211e21 0.29%, rgba(33, 30, 33, 0.94) 99.02%);
  backdrop-filter: blur(20.3526px);
}

.heading {
  max-width: 170px;
  margin-top: 18px;
  color: $color-snow-in-april;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1.9rem;
  text-align: center;
}

.text {
  margin-top: 8px;
  color: $color-metal-grey;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.4rem;
  text-align: center;

  strong {
    margin-right: 4px;
    color: $color-oh-so-green;
    font-weight: 700;
  }
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.buildButton {
  width: 100%;
}

.closeButton {
  position: absolute;
  top: 9px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background-color: $color-metal-grey;
  color: $color-snow-in-april;
}

.logo {
  width: 70px;
  height: 30px;
  padding-top: 4px;
}

.globeParticlesContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.globeParticles {
  width: 183px;
  height: 185px;
}

.globeParticlesLogos {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 84px;
  height: 28px;
  transform: translate(-50%, -50%);
}
