@import 'src/assets/styles/utils/index.module.scss';

.container {
}

.profileBtn {
  overflow: hidden;
  width: 35px;
  height: 35px;
  margin-left: 20px;
  border-radius: 50%;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .nameInitials {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: $color-metal-grey;
    color: $color-white;
  }
}

.profileMenu {
  z-index: $z-header + 10;
  min-width: 160px;
}

.closeBtn {
  position: absolute;
  top: 12px;
  right: 12px;
}

.menuItem {
  padding: 12px;
  background-color: transparent;
  color: $color-white;
  font-weight: $fw-light;
  font-size: 1.2rem;
  font-family: $ff-sans;
  line-height: 1.3rem;
}

.username {
  overflow: hidden;
  font-weight: $fw-medium;
  font-size: 1.4rem;
  text-align: center;
  text-overflow: ellipsis;
}

.logout {
  cursor: pointer;
  direction: rtl;
}

.picture {
  margin: auto;
}

.menuItemBottomBorder {
  border-bottom: 1px solid $color-kinda-dirty;
}
