@import 'src/assets/styles/utils/index.module.scss';

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  font-weight: $fw-medium;
  white-space: nowrap;
}

.theme-primary {
  background: $color-oh-so-green;
  color: $color-pretty-dark;
}

.theme-secondary {
  background: $color-ace-blue;
  color: $color-white;
}

.theme-tertiary {
  background: $color-singularity-red;
  color: $color-white;
}

.theme-quaternary {
  background: $color-ashy;
  color: $color-metal-grey;
}

.theme-grey {
  background: $color-vape;
  color: $color-black;
}

.theme-grey2 {
  background: $color-metal-grey;
  color: $color-snow-in-april;
}

.theme-grey3 {
  background: $color-metal-grey;
  color: $color-pretty-dark;
}

.theme-grey-border {
  background: transparent;
  border: thin solid $color-london-fog;
  color: $color-london-fog;
}

.theme-dark-transparent {
  border: 1px solid $color-pretty-dark;
  background: transparent;
  color: $color-pretty-dark;
}

.button:not(.preserveStyleWhenDisabled):disabled {
  background: $color-vape;
  color: $color-metal-grey;
  font-size: 14px;
}

.icon {
  flex-shrink: 0;
}

.size-normal {
  height: 35px;
  padding: 0 15px;
  font-size: 1.6rem;

  .icon {
    width: 15px;
    height: 15px;
    margin-right: 10px;
  }
}

.size-small {
  height: 25px;
  padding: 0 15px;
  font-size: 1.1rem;

  .icon {
    width: 11px;
    height: 11px;
    margin-right: 7px;
  }
}

.size-mini {
  height: 20px;
  padding: 0 10px;
  font-size: 0.9rem;

  .icon {
    width: 10px;
    height: 10px;
    margin-right: 4px;
  }
}

.size-medium {
  height: 30px;
  padding: 0 13px;
  font-size: 13px;

  .icon {
    width: 13px;
    height: 13px;
    margin-right: 3px;
  }
}
