@import 'src/assets/styles/utils/index.module.scss';

@mixin baseButtonSquare($type) {
  $color: '';
  $bg: '';
  $bg-border: '';
  $bg-active: '';
  $bg-active-border: '';

  @if $type == gradient {
    $color: $color-oh-so-green;
    $bg: linear-gradient(156.01deg, #fefdfd 21.34%, #ececec 134.27%);
    $bg-border: linear-gradient(155.23deg, #eaeaea 42.55%, #d3d3db 97.69%, #b6b6c4 133.75%);
    $bg-active: linear-gradient(303.54deg, #fefdfd 14.58%, #ededed 87.75%);
    $bg-active-border: linear-gradient(109.77deg, #eee 3.07%, #e0e0e4 91.46%),
      linear-gradient(139.09deg, #fff 7.68%, rgba(255, 255, 255, 0) 92.97%);
  }

  @if $type == flat-green {
    $color: $color-pretty-dark;
    $bg: $color-oh-so-green;
    $bg-border: linear-gradient(180deg, $color-burn-my-eyes 0%, $color-oh-so-green 100%);
    $bg-active: linear-gradient(303.54deg, $color-burn-my-eyes 14.58%, #5de273 92.71%);
    $bg-active-border: linear-gradient(109.77deg, $color-burn-my-eyes 3.07%, #5de273 91.46%),
      linear-gradient(139.09deg, $color-burn-my-eyes 7.68%, #5de273 92.97%);
  }

  @if $type == flat-dark {
    $color: $color-oh-so-green;
    $bg: $color-ashy;
    $bg-border: rgba(75, 70, 75, 1);
    $bg-active: linear-gradient(303.54deg, #414141 14.58%, #333 87.75%);
    $bg-active-border: linear-gradient(135deg, #605f5f 0%, #adadb5 100%),
      linear-gradient(45deg, #353536 0%, #5d5c5f 100%);
  }

  @if $type == flat-dark-soft {
    $color: $color-metal-grey;
    $bg: $color-ashy;
    $bg-border: rgba(75, 70, 75, 1);
    $bg-active: linear-gradient(303.54deg, #414141 14.58%, #333 87.75%);
    $bg-active-border: linear-gradient(135deg, #605f5f 0%, #adadb5 100%),
      linear-gradient(45deg, #353536 0%, #5d5c5f 100%);
  }

  @if $type == flat-blue {
    $color: $color-white;
    $bg: $color-ace-blue;
    $bg-border: $color-ace-blue;
    $bg-active: $color-ace-blue;
    $bg-active-border: $color-ace-blue;
  }

  @if $type == flat-gray {
    $color: $color-white;
    $bg: $color-metal-grey;
    $bg-border: $color-metal-grey;
    $bg-active: $color-metal-grey;
    $bg-active-border: $color-metal-grey;
  }

  @if $type == flat-ashy {
    $color: $color-white;
    $bg: $color-ashy;
    $bg-border: $color-ashy;
    $bg-active: $color-ashy;
    $bg-active-border: $color-ashy;
  }

  @if $type == flat-gray-light {
    $color: $color-metal-grey;
    $bg: transparentize($color-metal-grey, 0.73);
    $bg-border: transparentize($color-metal-grey, 0.53);
    $bg-active: transparentize($color-metal-grey, 0.73);
    $bg-active-border: transparentize($color-metal-grey, 0.43);
  }

  @if $type == flat-gray-dark {
    $color: $color-ashy;
    $bg: $color-metal-grey;
    $bg-border: $color-metal-grey;
    $bg-active: $color-metal-grey;
    $bg-active-border: $color-metal-grey;
  }

  @if $type == flat-red {
    $color: $color-white;
    $bg: $color-singularity-red;
    $bg-border: $color-singularity-red;
    $bg-active: $color-singularity-red;
    $bg-active-border: $color-singularity-red;
  }

  background: $bg;
  color: $color;

  &:hover {
    background: $bg-border;

    &::before {
      background: $bg;
    }
  }

  &:active {
    background: $bg-active-border;

    &::before {
      background: $bg-active;
    }
  }
}

.container {
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  svg {
    position: relative;
    z-index: 1;
  }
}

.size-1 {
  width: 33px;
  height: 33px;
}

.size-2 {
  width: 23px;
  height: 23px;
}

.size-3 {
  width: 36px;
  height: 38px;
}

.size-breadcrumbs {
  width: 32px;
  height: 32px;
}

.inactive {
  background: $color-vape;
  color: $color-metal-grey;

  &:hover:not(:disabled) {
    background: transparent;
  }
}

.gradient {
  @include baseButtonSquare(gradient);
}

.flat-green {
  @include baseButtonSquare(flat-green);
}

.flat-dark {
  @include baseButtonSquare(flat-dark);
}

.flat-dark-soft {
  @include baseButtonSquare(flat-dark-soft);
}

.flat-blue {
  @include baseButtonSquare(flat-blue);
}

.flat-gray {
  @include baseButtonSquare(flat-gray);
}

.flat-ashy {
  @include baseButtonSquare(flat-ashy);
}

.flat-red {
  @include baseButtonSquare(flat-red);
}

.flat-gray-light {
  @include baseButtonSquare(flat-gray-light);
}

.flat-gray-dark {
  @include baseButtonSquare(flat-gray-dark);
}

.gradient,
.flat-green,
.flat-dark {
  // box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.15); // TODO: Flat shouldnt have box-shadow -> flat-green btn in Header doesnt have shadow.

  &::before {
    content: '';
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
    border-radius: 4px;
  }

  &:active {
    box-shadow: none;
  }
}

.svg-size-1 {
  svg {
    max-width: 17px;
    max-height: 17px;
  }
}

.svg-size-2 {
  svg {
    max-width: 15px;
    max-height: 15px;
  }
}

.svg-size-3 {
  svg {
    max-width: 11px;
    max-height: 11px;
  }
}
