@import 'src/assets/styles/utils/index.module.scss';

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.header {
  display: flex;
  justify-content: space-between;
  height: 25px;
  margin-bottom: 10px;

  & > div {
    display: flex;

    &:first-of-type {
      span {
        color: $color-ashy;
        font-size: 2rem;
        line-height: 2.2rem;

        &:last-of-type {
          margin-left: 10px;
          font-weight: $fw-bold;
        }
      }
    }
  }
}

.modalControlButtons,
.simActionButtons {
  display: flex;

  & > button {
    margin-left: 8px;

    svg[class^='icon-minimize'] {
      position: relative;
      top: 5px;
    }
  }
}

.content {
  display: flex;
  flex: 1;

  .detailsAndControls {
    position: relative;
    width: 120px;
    padding-top: 10px;

    ul li {
      margin-bottom: 4px;
      font-size: 0.8rem;

      span {
        font-weight: $fw-bold;
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 33px;
      height: 1px;
      background-color: $color-metal-grey;
    }
  }

  .graph {
    display: flex;
    flex: 1;
    align-items: center;
    flex-flow: row wrap;
    width: 100%;

    // & > div {
    &:first-child {
      position: relative;
      width: 100%;
      height: 100px;
    }
  }
}

.arrrowButtonsWrapper {
  position: absolute;
  bottom: 0;
}
