@import 'src/assets/styles/utils/index.module.scss';

.buttonContainer {
  position: absolute;
  right: 20px;
  bottom: 107px;
}

.legend {
  &::before {
    content: '\2022';
    font-weight: bold;
    font-size: 30px;
    line-height: 20px;

    display: inline-block;
    position: absolute;
    width: 20px;
    margin: -3px 0 0 -16px;
  }

  &.ExportingEnergy {
    &::before {
      color: $color-oh-so-green;
    }
  }

  &.Neutral {
    &::before {
      color: $color-ace-blue;
    }
  }

  &.ImportingEnergy {
    &::before {
      color: $color-singularity-red;
    }
  }
}

.scmMapLegends {
  position: absolute;
  right: 20px;
  bottom: 10px;

  .legend {
    display: flex;
    font-family: $ff-sans;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 4px;

    color: $color-snow-in-april;
    height: 25px;
    min-width: 140px;
    border-radius: 4px;
    background: $color-ashy;
    padding: 6px 12px 6px 26px;
  }
}

ul.mapLegends {
  position: absolute;
  right: 20px;
  top: 85px;
  background-color: $color-white;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  padding: 6px 12px 6px 26px;
  border-radius: 5px;

  li {
    margin-right: 30px;
    &:nth-last-child(1) {
      margin-right: 0;
    }
  }
}

ul.mapLegends .title {
  color: $color-snow-in-april;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 125%;
}
