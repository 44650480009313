@import 'src/assets/styles/utils/index.module.scss';

.searchWrapper {
  position: relative;
}

.inputSearch {
  width: 100%;
  height: 100%;
  color: $color-ashy;
  font-weight: $fw-medium;
  font-size: 1.3rem;
  line-height: 1.4rem;

  &::placeholder {
    color: inherit;
  }
}
