@import 'src/assets/styles/utils/index.module.scss';

.saveSettings {
  width: 100%;
  height: 45px;
  position: absolute;
  left: 0;
  // bottom: 0;
  margin-top: 15px;
  background-color: $color-oh-so-green;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}
