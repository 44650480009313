@import 'src/assets/styles/utils/index.module.scss';

.container {
  top: $header-height;
  left: 56px;
  background-color: white;
  position: absolute;
  width: calc(100vw - 56px);
  height: calc(100vh - #{$header-height});
  overflow-y: scroll;
  z-index: 1000;
}
