@import 'src/assets/styles/utils/index.module.scss';

.content {
  display: flex;
  flex: 1;
  background-size: 75%;
  background-repeat: no-repeat;
  background-position: bottom right;
  position: relative;
  flex-direction: column;
  overflow-y: scroll;
}

.content::after {
  position: fixed;
  content: ' ';
  bottom: 0;
  right: 0;
  width: 50%;
  z-index: 1;
  background-image: linear-gradient(transparent, #211e21);
  height: 120px;

  @media only screen and (min-width: 300px) and (max-width: 900px) {
    width: 100%;
  }
}

.wrapperPeople {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 0;

  @media only screen and (max-width: 600px) {
    position: fixed;
    bottom: 5%;
    width: 100%;
    justify-content: space-between;
  }
}

.info {
  display: flex;
  flex-direction: column;
  z-index: 2;

  p {
    color: $color-oh-so-green;
    font-size: 2.4rem;
    line-height: 3.6rem;
    font-family: $ff-sans-landingPage;
    font-weight: $fw-regular;
    -webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 3s; /* Firefox < 16 */
    -ms-animation: fadein 3s; /* Internet Explorer */
    -o-animation: fadein 3s; /* Opera < 12.1 */
    animation: fadein 3s;
    margin-top: 12px;

    @media only screen and (max-width: 600px) {
      margin-top: 0;
      z-index: 2;
    }
  }
}

.boxPeople {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-bottom: 30px;
}

.photo {
  position: relative;
  display: flex;
  width: 33.3%;
  height: 100vh;
  max-height: 262px;
  border: solid 4px transparent;
  clip-path: polygon(
    calc(0% + 4px) calc(0% + 4px),
    /* top left */ calc(100% - 4px) calc(0% + 4px),
    /* top right */ calc(100% - 4px) calc(100% - 4px),
    /* bottom right */ calc(0% + 4px) calc(100% - 4px) /* bottom left */
  );
  transition: clip-path 0.6s linear;
  -webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 3s; /* Firefox < 16 */
  -ms-animation: fadein 3s; /* Internet Explorer */
  -o-animation: fadein 3s; /* Opera < 12.1 */
  animation: fadein 3s;

  @media only screen and (max-width: 600px) {
    width: 50%;
  }

  span {
    position: absolute;
    align-self: flex-start;
    left: 20px;
    top: 20px;
    font-size: 2.4rem;
    line-height: 2.8rem;
    max-width: 100px;
    font-weight: $fw-medium;
    font-family: $ff-sans-landingPage;
    color: $color-london-fog;
    cursor: pointer;

    @media only screen and (max-width: 600px) {
      left: 8px;
      top: 8px;
      word-wrap: break-word;
      max-width: unset;
    }
  }

  &:hover {
    border: solid 4px $color-oh-so-green;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);

    span {
      color: $color-oh-so-green;
    }
  }

  @media only screen and (min-width: 1400px) {
    max-height: 362px;
  }
}

.boxPhoto {
  display: flex;
  width: 100%;
  cursor: pointer;
  background-position: center;
  background-size: cover;
  background-image: url('../../assets/images/LandingPage/jutta-member.png');
  opacity: 0.3 !important;
  filter: grayscale(1) brightness(0.4);
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &:hover {
    opacity: 1 !important;
    filter: grayscale(1) brightness(1);
  }
}
