@import 'src/assets/styles/utils/index.module.scss';

.inline {
  white-space: nowrap;
}

.tooltipTop {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.tooltiptext {
  box-sizing: content-box;
  background-color: $color-ashy;
  color: $color-snow-in-april;
  text-align: center;
  border-radius: 5px;
  padding: 14px 16px;
  z-index: 1;
  font-weight: $fw-medium;
  font-size: 1.6rem;
  line-height: 1rem;
}

.tooltipTop .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -12px;
  border-width: 12px;
  border-style: solid;
  border-color: $color-ashy transparent transparent transparent;
}

.tooltipTop:hover .tooltiptext {
  visibility: visible;
}

// bottom

.tooltipBottom {
  position: relative;
  display: inline-block;
}

.tooltipBottom .tooltiptext {
  box-sizing: content-box;
  visibility: visible;
  background-color: $color-ashy;
  color: $color-snow-in-april;
  text-align: center;
  border-radius: 5px;
  padding: 14px 10px;
  position: absolute;
  z-index: 1;
  top: 155%;
  left: 50%;
  margin-left: -60px;

  font-weight: $fw-medium;
  font-size: 1.6rem;
  line-height: 1rem;
}

.tooltipBottom .tooltiptext::after {
  content: '';
  position: absolute;
  top: -43%;
  left: 50%;
  margin-left: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: $color-ashy transparent transparent transparent;
  transform: rotate(180deg);
}

.tooltipBottom:hover .tooltiptext {
  visibility: visible;
}

// left

.tooltipLeft {
  position: relative;
  display: inline-block;
}

.tooltipLeft .tooltiptext {
  box-sizing: content-box;
  visibility: visible;
  background-color: $color-ashy;
  color: $color-snow-in-april;
  text-align: center;
  border-radius: 5px;
  padding: 14px 10px;
  position: absolute;
  z-index: 1;
  left: 155%;
  left: 50%;
  margin-left: -60px;

  font-weight: $fw-medium;
  font-size: 1.6rem;
  line-height: 1rem;
}

.tooltipLeft .tooltiptext::after {
  content: '';
  position: absolute;
  top: 35%;
  right: -10%;
  margin-left: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: $color-ashy transparent transparent transparent;
  transform: rotate(-90deg);
}

.tooltipLeft:hover .tooltiptext {
  visibility: visible;
}
