@import 'src/assets/styles/utils/index.module.scss';

// todo - check final designs and correct styling

.formWrapper {
  padding: 12px;
}

.formWrapperTop {
  padding-bottom: 24px;
}

.formWrapperBottom {
  padding-top: 30px;
}

.editPassword {
  margin-bottom: 30px;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: $color-ashy;
}

.inputEdit {
  margin-bottom: 30px;
}

.queryMessage {
  margin-bottom: 10px;
}

.successMessage {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  width: 100%;
  height: 35px;
  padding: 0 5px;
  font-size: 1.4rem;
  border: 2px solid $color-oh-so-green;
  color: $color-snow-in-april;
}

.loader {
  height: 35px;
}

.buttonLogin {
  width: 100%;
}
