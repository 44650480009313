@import 'src/assets/styles/utils/index.module.scss';

.profilePicture {
  display: flex;

  .signUpSection {
    margin: 10px;
    color: $color-white;

    .notMandatory {
      color: $color-metal-grey;
      font-size: 1rem;
    }
  }
}

.profileIcon {
  position: relative;

  label {
    display: inline-block;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: $color-ashy;

    &:hover {
      cursor: pointer;
    }

    .picture {
      width: 100%;
      height: 100%;
      -moz-border-radius: 50px;
      border-radius: 50px;
    }

    .icon {
      margin: 10px auto;
    }

    .nameInitials {
      display: inline-block;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: rgb(random(255), random(255), random(255));
      color: #fff;
      font-size: 2.9rem;
    }
  }

  .additionalIcon {
    position: absolute;
    right: 10%;
    bottom: 0%;
    display: inline-block;
    padding: 4px;
    border-radius: 50%;
  }

  .plusIcon {
    background-color: $color-oh-so-green;
  }

  .closeIcon {
    background-color: $color-singularity-red;
  }

  .plusPersonIcon {
    right: 15%;
    bottom: 15%;
  }

  .input {
    display: none;
  }
}
