@import 'src/assets/styles/utils/index.module.scss';

.list {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: $z-toast;
}

.listItem {
  & + & {
    margin-top: 15px;
  }
}

.singleToast {
  position: relative;
  width: 35vw;
  min-width: 280px;
  max-width: 775px;
  padding: 15px 45px 15px 15px;
  border-radius: 7px;
  font-weight: $fw-medium;
  line-height: 1.36;
}

.type-error {
  background: $color-error;
  color: $color-white;
}

.type-success {
  background: $color-success;
  color: $color-cod-grey;
}

.closeBtn {
  position: absolute;
  top: 15px;
  right: 15px;
}
