@import 'src/assets/styles/utils/index.module.scss';

.container {
  width: 370px;
  padding: 16px 12px;
  display: flex;
  flex-direction: column;
  box-shadow: 6px 6px 14px 0px rgba(0, 0, 0, 0.09);
  position: relative;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  h3 {
    font-size: 1.6rem;
    line-height: 20px;
    font-weight: 500;
    border-bottom: 2px solid $color-oh-so-green;
    cursor: pointer;
  }
  & > div {
    display: flex;
    button {
      margin-left: 15px;
    }
  }
}
