@import 'src/assets/styles/utils/index.module.scss';

.container {
  position: absolute;
  top: 10px;
  right: 0;
  overflow: hidden;
  width: 25px;
  height: auto;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: $color-snow-in-april;
  transform: translateX(100%);

  &.disabled {
    background-color: $color-metal-grey !important;

    button svg {
      color: $color-london-fog;
    }
  }

  // Trick for pointer-events none in button
  div[data-disabled] {
    cursor: not-allowed;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: $color-ashy;
    background-color: $color-white;

    &:disabled {
      color: darken($color: $color-vape, $amount: 10);
      pointer-events: none;
    }

    &.active {
      background-color: $color-soft-not-so-green;
      color: $color-ashy;
    }
  }
}

.tooltipModal {
  padding: 10px;
  border-radius: 4px;
  background-color: $color-pretty-dark;
  color: $color-vape;
}
