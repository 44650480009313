@import 'src/assets/styles/utils/index.module.scss';

.container {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  background-color: $color-vape;
}

.itemCard {
  justify-content: unset;
  height: auto;
  cursor: pointer;

  .title {
    flex: 1;
    margin: unset;
    font-weight: 500;
    font-size: 14px;
  }

  .iconsWrapper {
    padding: unset;
  }

  .iconMainWrapper {
    margin-right: 8px;
    padding: 6px;
    border-radius: 3px;
    background: $color-ace-blue;
    color: $color-snow-in-april;
  }

  .iconMainWrapperCanary {
    background: $color-oh-so-green;
    color: $color-black;
  }

  .upperRow {
    display: flex;
    align-items: center;

    .infoWrapper {
      display: flex;
      width: 100%;
    }
  }

  .simulationWave {
    margin: 14px 0;

    img {
      object-fit: contain;
      width: 100%;
    }
  }

  .location {
    position: relative;
    display: flex;
    align-items: center;
    margin: 10px 0 14px;
    font-weight: 400;
    font-size: 11px;

    .iconLocation {
      margin-right: 7px;
    }

    p {
      position: absolute;
      top: 2px;
      left: 24px;
    }
  }

  .simulationSummary {
    display: flex;
    align-items: center;

    p {
      margin-right: 14px;
      text-transform: capitalize;

      &:last-child {
        margin-right: 0;
      }
    }

    .priceSummary,
    .volumeSummary {
      padding: 2px 5px;
    }

    .priceSummary {
      border-left: 2px solid $color-oh-so-green;
    }

    .volumeSummary {
      border-left: 2px solid $color-ace-blue;
    }
  }

  .assetsSummary {
    display: flex;
  }

  .assetsList {
    display: flex;
    margin-top: 15px;
    padding-right: 84px;
    color: $color-snow-in-april;

    li {
      margin-bottom: unset;
    }
  }

  .assetSquareBtn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin-right: 7px;
    border-radius: 4px;
    background: $color-metal-grey;
  }

  .assetsAmount {
    position: absolute;
    bottom: -5px;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 17px;
    height: 9px;
    padding: 0 4px;
    border-radius: 35px;
    background: $color-ashy;
    color: $color-snow-in-april;
    font-weight: $fw-medium;
    font-size: 0.7rem;
    transform: translateX(-50%);
  }
}
