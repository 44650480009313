@import 'src/assets/styles/utils/index.module.scss';

.imageFloating {
  max-height: 100vh;
  position: absolute;
  z-index: 13;
}

.boxContent {
  position: absolute;
  background-color: $color-ace-blue;
  max-width: 95vw;
  max-height: 297px;
  left: 2.5%;
  right: 2.5%;
  bottom: 5%;
  border-radius: 2px;
  z-index: 16;

  @media only screen and (min-width: 992px) and (max-width: 1600px) {
    margin-top: 385px;
    max-width: 335px;
  }
}

.boxText {
  margin: 6%;
  font-family: $ff-sans-landingPage;
  color: $color-snow-in-april;

  h3 {
    font-size: 2.2rem;
    line-height: 2.4rem;
    font-weight: $fw-medium;
    letter-spacing: 1.5px;
    margin-bottom: 4%;
    text-transform: capitalize;

    @media only screen and (min-width: 992px) and (max-width: 1600px) {
      font-size: 2rem;
    }
  }

  p {
    font-size: 1.6rem;
    line-height: 2.8rem;
    font-weight: $fw-regular;

    a {
      font-weight: $fw-medium;
      color: $color-oh-so-green;
      border-bottom: 2px solid $color-oh-so-green;
    }

    @media only screen and (min-width: 992px) and (max-width: 1600px) {
      font-size: 1.5rem;
      line-height: 2.27rem;
    }
  }
}

.grayBox {
  position: absolute;
  background-color: $color-ashy;
  max-width: 255px;
  width: 100%;
  border-radius: 2px;
  left: 10px;
  top: 24%;
  // margin-top: 690px;
  // margin-left: 900px;
  z-index: 17;

  @media only screen and (min-width: 992px) and (max-width: 1600px) {
    margin-top: 420px;
    margin-left: 580px;
  }
}

.grayBoxText {
  margin: 6%;
  font-family: $ff-sans-landingPage;
  font-weight: $fw-regular;
  color: $color-snow-in-april;

  p {
    font-size: 1.4rem;
    line-height: 2.4rem;

    a {
      cursor: pointer;
      color: $color-oh-so-green;
    }
  }
}

.city {
  right: 2%;
  top: 48%;
  width: 95%;
}

.ringOne {
  right: 30.5%;
  top: -58%;
  width: 7.2%;
}

.ringTwo {
  right: 28.5%;
  top: -28%;
  width: 7.2%;
}

.ringThree {
  right: 21.5%;
  top: -28%;
  width: 7.2%;
}

.ringFour {
  right: 15%;
  top: -45%;
  width: 7.2%;
}

.ringFive {
  right: 10.5%;
  top: -19.5%;
  width: 7.2%;
}
