@import 'src/assets/styles/utils/index.module.scss';

.modal {
  top: 60px;
  left: 0;
  padding: 50px 25px 25px;
  width: 100%;
  height: 100%;
  background: rgba(33, 30, 33, 0.8);
  backdrop-filter: blur(20px);

  .closeBtn {
    position: absolute;
    top: 15px;
    right: 15px;
  }
}
