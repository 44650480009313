@import 'src/assets/styles/utils/index.module.scss';

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.switchContainer {
  display: flex;
  align-items: center;

  span {
    margin-right: 6px;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1rem;
  }
}

.headerWithSubtitle {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.headerTitle {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.6rem;
}

.headerSubtitle {
  margin-left: 5px;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 1.5rem;
}

.housesTags {
  margin-top: 10px;

  & > * {
    margin-bottom: 10px;
  }
}

.svgChart {
  width: 100%;
  height: 295px;
}
