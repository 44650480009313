@import 'src/assets/styles/utils/index.module.scss';

.container {
  height: 100%;

  &::after {
    content: '';
    position: absolute;
    left: 20px;
    bottom: -10px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: rgba(33, 30, 33, 0.94) transparent transparent transparent;
  }
}

.closeIcon {
  width: 14px;
  height: 14px;
  position: absolute;
  right: 0;
  z-index: 9999;
}

.modal {
  background: linear-gradient(17.86deg, #211e21 -60.33%, rgba(33, 30, 33, 0.94) 98.42%);
  border-radius: 8px;
  padding: 8px 10px;
  color: $color-snow-in-april;
  position: absolute !important;
}

.content {
  position: relative;
  // background: linear-gradient(17.86deg, #211e21 -60.33%, rgba(33, 30, 33, 0.94) 98.42%);
  // border-radius: 8px;

  .capital {
    text-transform: uppercase;
  }

  .added {
    color: $color-oh-so-green;
  }

  .edited {
    color: $color-ace-blue;
  }

  .removed {
    color: $color-singularity-red;
  }

  .active {
    min-width: 120px;
    padding: 5px 0 2px 0;

    .date {
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      color: $color-snow-in-april;
      margin-bottom: 4px;
    }

    .time {
      font-family: 'NB Akademie Std';
      font-size: 11px;
      line-height: 12px;
      margin-bottom: 8px;
    }

    .titleStatusContainer {
      background: #211e21;
      border-radius: 4px;
      padding: 4px;
      margin-bottom: 3px;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
    }
  }

  .inActive {
    .date {
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: $color-snow-in-april;
      margin-bottom: 6px;
    }
  }
}
