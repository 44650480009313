@import 'src/assets/styles/utils/index.module.scss';

.container {
  width: 100%;

  button {
    width: 100%;
    height: 32px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 1rem;
  }

  .fbButton {
    background: #005de8;
    color: $color-snow-in-april;
  }

  .googleButton {
    background: #f5655a;
    color: $color-white;
  }
}
