@import 'src/assets/styles/utils/index.module.scss';

.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: linear-gradient(0deg, rgba(102, 238, 102, 0.5) 0%, rgba(102, 238, 102, 1) 100%);
}

.avatar {
  position: absolute;
  top: 50%;
  left: 50%;
  object-fit: cover;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.outlineNone {
  background: none;
}
