@import 'src/assets/styles/utils/index.module.scss';

.imageFloating {
  max-height: 100vh;
  position: absolute;
  z-index: 13;
}

.boxContent {
  position: absolute;
  background-color: $color-ace-blue;
  max-width: 95vw;
  max-height: 297px;
  left: 2.5%;
  right: 2.5%;
  bottom: 5%;
  border-radius: 2px;
  z-index: 14;
}

.boxText {
  margin: 6%;
  font-family: $ff-sans-landingPage;
  color: $color-snow-in-april;

  h3 {
    font-size: 2.2rem;
    line-height: 2.4rem;
    font-weight: $fw-medium;
    letter-spacing: 1.5px;
    margin-bottom: 4%;
    text-transform: capitalize;
  }

  p {
    font-size: 1.6rem;
    line-height: 2.8rem;
    font-weight: $fw-regular;
  }
}

.basePlaqueOne {
  right: 0%;
  top: 70%;
  width: 100%;
}

.linesPlaque {
  right: 0%;
  top: 55%;
  width: 90%;
}

.middlePlaque {
  right: 5%;
  top: 50%;
  width: 100%;
}

.linksPlaque {
  right: 20%;
  top: 37%;
  width: 60%;
}

.topPlaque {
  right: 0%;
  top: 27%;
  width: 100%;
}

.boxVoltage {
  background-color: $color-ashy;
  // width: 450px;
  max-width: 100%;
  border-radius: 5px;
  padding: 12px;
  position: absolute;
  left: 0%;
  top: 84%;
  z-index: 18;

  span {
    color: $color-snow-in-april;
    font-size: 2rem;
    letter-spacing: 1px;
    font-weight: $fw-medium;
  }
}

.voltage {
  display: flex;
  z-index: 4;

  button {
    background-color: $color-pretty-dark;
    opacity: 0.5;
    border-radius: 5px;
    height: 42px;
    max-height: 100%;
    padding: 12px;
    color: $color-metal-grey;
    font-weight: $fw-medium;
    letter-spacing: 1px;
    margin-right: 12px;
    margin-top: 12px;

    :first-child {
      margin: none;
    }
  }
}

.marketPopper {
  min-width: 140px;
  min-height: 35px;
  border-radius: 5px;
  border: 1px solid $color-white;
  background: #211e21;
  padding: 12px 10px;
  position: absolute;
  right: 30%;
  bottom: 8%;
  width: 23%;
  z-index: 13;

  div {
    color: $color-snow-in-april;
    font-size: 1.25rem;
    letter-spacing: 1px;
    font-weight: $fw-medium;
    text-align: center;
  }
}

.marketTwoTooltip {
  position: absolute;
  right: 45%;
  top: 40%;
  width: 15%;
  z-index: 14;

  img {
    width: 25px;
    height: 20px;
    position: relative;
    left: -5px;
    object-fit: contain;
  }

  span {
    font-size: 1.4rem;
    font-weight: $fw-medium;
    min-width: 150px;
  }
}

.descriptionTooltip {
  font-size: 1.2rem;
  font-weight: $fw-regular;
}

.plaqueMarketTwo {
  right: 0%;
  top: 46%;
  width: 100%;
}

.grayBoxForDoc {
  top: 22% !important;
}

.grayBox {
  position: absolute;
  z-index: 1;
  background-color: $color-ashy;
  max-height: 60px;
  width: calc(100% - 20px);
  height: 100%;
  border-radius: 2px;
  top: 35%;
  left: 10px;
  right: 10px;
}

.grayBoxText {
  margin: 3%;
  font-size: $ff-sans-landingPage;
  font-weight: $fw-regular;
  color: $color-snow-in-april;

  h3 {
    font-size: 1.6rem;
    line-height: 1.6rem;
    letter-spacing: 1px;
    margin-bottom: 4%;
  }

  p {
    font-size: 1.4rem;
    line-height: 1.6rem;
  }
}

.marketThreeTooltip {
  position: absolute;
  right: 40%;
  top: 34%;
  width: 23%;
  z-index: 14;

  img {
    width: 25px;
    height: 20px;
    position: relative;
    left: -5px;
    object-fit: contain;
  }

  span {
    font-size: 1.4rem;
    font-weight: $fw-medium;
  }
}

.basePlaqueMarket {
  right: 0%;
  top: 60%;
  width: 100%;
}

.basePlaqueMarketThree {
  right: 0%;
  top: 60%;
  width: 100%;
}

.linesMarketThree {
  right: 0%;
  top: 45%;
  width: 90%;
}

.topPlaqueMarketThree {
  right: 0%;
  top: 42%;
  width: 100%;
}

.grayBoxThird {
  position: absolute;
  z-index: 1;
  background-color: $color-ashy;
  max-height: 95px;
  width: calc(100% - 20px);
  border-radius: 2px;
  top: 35%;
  left: 10px;
  right: 10px;
}

.basePlaqueNext {
  right: 0%;
  top: 75%;
  width: 100%;
  filter: blur(3px);
  z-index: 3;
}

.linesPlaqueNext {
  right: 0%;
  top: 60%;
  width: 90%;
  filter: blur(2px);
}

.middlePlaqueNext {
  right: 5%;
  top: 52%;
  width: 100%;
}

.linksPlaqueNext {
  right: 20%;
  top: 39%;
  width: 60%;
}

.topPlaqueNext {
  right: 5%;
  top: 27%;
  width: 100%;
}

.marketNextTooltip {
  position: absolute;
  right: 40%;
  top: 25%;
  width: 23%;
  z-index: 14;

  img {
    width: 25px;
    height: 20px;
    position: relative;
    left: -5px;
    object-fit: contain;
  }

  span {
    font-size: 1.4rem;
    font-weight: $fw-medium;
  }
}

.grayBoxNext {
  position: absolute;
  z-index: 1;
  background-color: $color-ashy;
  max-height: 95px;
  width: calc(100% - 20px);
  height: 100%;
  border-radius: 2px;
  top: 35%;
  left: 10px;
  right: 10px;
}
