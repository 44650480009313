@import 'src/assets/styles/utils/index.module.scss';

.root {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  // width: 100%; // DON'T ADD W100% TO THE ROOT
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.label {
  margin-right: auto;
  padding-right: 14px;
  font-weight: $fw-medium;
  font-size: 1.2rem;
  line-height: 1.3rem;
}

.error {
  width: 100%;
}

.disabled {
  &,
  * {
    cursor: not-allowed !important;
  }
}

.theme-gray {
  background: rgba($color-metal-grey, 0.27);
  color: $color-metal-grey;

  .active-item {
    background: $color-ashy;
    color: $color-snow-in-april;
  }

  &.theme-contrast .active-item {
    background: $color-pretty-dark;
  }
}

.theme-green {
  background: rgba($color-metal-grey, 0.27);
  color: $color-metal-grey;

  .active-item {
    background: $color-ashy;
    color: $color-pretty-dark;
  }

  &.theme-contrast .active-item {
    background: $color-oh-so-green;
  }
}

.theme-softred {
  background: rgba($color-metal-grey, 0.27);
  color: $color-metal-grey;

  .active-item {
    background: $color-ashy;
    color: $color-pretty-dark;
  }

  &.theme-contrast .active-item {
    background: $color-soft-singularity-red;
  }
}

.theme-gray-custom {
  background: rgba($color-metal-grey, 0.27);
  color: $color-black;

  .active-item {
    background: $color-ashy;
    color: $color-oh-so-green;
  }
}

.theme-dark {
  background: $color-ashy;
  color: $color-snow-in-april;

  .active-item {
    background: $color-snow-in-april;
    color: $color-pretty-dark;
  }
}

.theme-light {
  background: $color-snow-in-april;
  color: $color-metal-grey;

  .active-item {
    background: $color-ashy;
    color: $color-snow-in-april;
  }

  &.theme-contrast .active-item {
    background: $color-pretty-dark;
  }
}

.theme-gradient-dark {
  background: linear-gradient(20deg, #262626 3.13%, #000 105.3%);
  box-shadow: inset 3px 4px 8px rgba(0, 0, 0, 0.0882);

  .active-item {
    background: linear-gradient(20deg, #000 3.13%, #323232 112.22%);
    color: $color-oh-so-green;
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.07);
  }
}

.origin-horizontal {
  &.size-big {
    width: 74px;
    height: 34px;
    padding: 3px 5px;

    svg {
      max-width: 15px;
      max-height: 15px;
    }
  }

  &.size-small {
    width: 50px;
    height: 22px;
    padding: 2px 3px;

    svg {
      max-width: 10px;
      max-height: 10px;
    }
  }

  &.size-edge {
    width: 52px;
    height: 27px;
    border-radius: 7px;

    .active-item {
      border-radius: 6px;
    }

    svg {
      max-width: 13px;
      max-height: 13px;
    }
  }

  .active-item {
    width: 50%;
    height: 100%;

    &[data-active-index='1'] {
      transform: translateX(100%);

      .item {
        transform: translateX(-100%);
      }
    }

    .item {
      &:nth-child(1) {
        left: 0;
      }

      &:nth-child(2) {
        left: 100%;
      }
    }
  }
}

.origin-vertical {
  width: 34px;
  height: 74px;
  padding: 5px 3px;

  svg {
    max-width: 15px;
    max-height: 15px;
  }

  .items {
    flex-direction: column;
  }

  .active-item {
    width: 100%;
    height: 50%;

    &[data-active-index='1'] {
      transform: translateY(100%);

      .item {
        transform: translateY(-100%);
      }
    }

    .item {
      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2) {
        top: 100%;
      }
    }
  }
}

.items-wrapper {
  position: relative;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
}

.items {
  display: flex;
  height: 100%;
}

.item {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 100%;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}

.active-item {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  border-radius: 4px;
  pointer-events: none;
  transition: 0.3s;

  .item {
    position: absolute;
    width: 100%;
    transition: 0.3s;
  }
}

.lengthTooltip {
  position: relative;
  top: 2px;
  margin-left: 8px;
}
