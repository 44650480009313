@import 'src/assets/styles/utils/index.module.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  header {
    display: flex;
    align-items: center;
    padding: 15px 22px;
    .back {
      margin-right: 12px;
    }
    p {
      position: relative;
      flex: 1;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -2px;
        width: 123px;
        border-bottom: 2px solid $color-oh-so-green;
      }
    }
    .button {
      margin-left: 7px;
    }
  }
  .profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
    .avatar {
      width: 132px;
      height: 132px;
      border-radius: 100%;
      background-size: cover;
      background-position: center;
      margin-bottom: 11px;
    }
    .avatarLetter {
      margin-bottom: 11px;
      width: 132px;
      height: 132px;
      border-radius: 100%;
      background-size: cover;

      font-size: 6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      border: 1px solid $color-metal-grey;
    }
    h3 {
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 11px;
    }
    .roleSelector {
      margin-bottom: 18px;
    }
    p {
      font-size: 13px;
      line-height: 15px;
      color: $color-metal-grey;
    }
  }
}

.collapsedList {
  width: 100%;
  .collapseArrow {
    transform: rotate(180deg);
  }
  &.collapsed {
    .collapseArrow {
      transform: rotate(0deg);
    }
  }
  header {
    display: flex;
    cursor: pointer;
    h3 {
      flex: 1;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
    }
  }
  .list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 22px;
  }
  .listItem {
    position: relative;
    width: 105px;
    height: 140px;
    background-color: $color-vape;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    padding: 15px 0 10px;
    & > div {
      width: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
    }
    h5 {
      font-size: 1.2rem;
      font-weight: bold;
      margin-bottom: 5px;
      text-align: center;
    }
    span {
      margin-bottom: 2px;
    }
    span {
    }
  }
}

.threeDots {
  position: absolute;
  top: 5px;
  right: 5px;
}

.infoButton {
  width: auto !important;
  position: absolute !important;
  top: 6px;
  left: 5px;
}
