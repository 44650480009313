@import 'src/assets/styles/utils/index.module.scss';

.orientationBasic {
  position: relative;

  &.light {
    background-color: $color-vape;
    border-radius: 10px;
    .item {
      .label {
        color: $color-ashy;
      }
    }
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 15px 10px;
    color: $color-white;
    font-size: 1.6rem;

    &.menuItemBottomBorder {
      border-bottom: 1px solid $color-kinda-dirty;
    }

    .optionContainer {
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        margin-right: 16px;
      }
    }

    .label {
      font-weight: bold;
      font-size: 14px;
      line-height: 14px;
      color: $color-snow-in-april;
      font-family: $ff-sans-secondary;
      margin-bottom: 10px;
    }

    .description {
      font-family: Roboto;
      font-size: 12px;
      line-height: 7px;
      color: $color-metal-grey;
      font-family: $ff-sans;
    }
  }

  input {
    width: 16px;
    height: 16px;
    margin-left: 20px;
    -webkit-appearance: auto;
    appearance: auto;
  }

  input[type='radio']::after {
    content: '';
    position: relative;
    top: 0;
    left: 0;
    display: inline-block;
    width: 16px;
    height: 16px;
    // border: 1px solid $color-oh-so-green;
    border-radius: 15px;
    background-color: $color-pretty-dark;
    visibility: visible;
  }

  input[type='radio']:checked::after {
    content: '';
    position: relative;
    top: 0;
    left: 0;
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 4px solid $color-pretty-dark;
    border-radius: 15px;
    background-color: $color-oh-so-green;
    visibility: visible;
  }
}
