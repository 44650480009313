@import 'src/assets/styles/utils/index.module.scss';

.tagsWrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-right: -8px;
  margin-bottom: -5px;
}

.tagButton {
  height: 20px;
  margin-right: 8px;
  margin-bottom: 5px;
  padding: 0 10px;
  border-radius: 15px;
  //background-color: $color-white;
  background-color: $color-vape;
  color: $color-cod-grey;
  font-weight: $fw-medium;
  font-size: 1.1rem;
  line-height: 1.2rem;

  &.inactive {
    background-color: $color-snow-in-april;

    .tagDot {
      background-color: $color-metal-grey !important;
    }
  }
}

.buttonContent {
  display: flex;
  align-items: center;
}

.tagDot {
  width: 6px;
  height: 6px;
  margin-right: 4px;
  border-radius: 50%;
  background-color: $color-oh-so-green;
}

.viewAllButton {
  display: flex;
  align-items: center;
  padding: 5px 13px;
  border-radius: 15px;
  background-color: $color-ashy;

  span {
    color: $color-snow-in-april;
    font-size: 0.7rem;
    line-height: 0.8rem;
  }
}
