@import 'src/assets/styles/utils/index.module.scss';

.content {
  display: flex;
  flex: 1;
  background-size: 75%;
  background-repeat: no-repeat;
  background-position: bottom right;
  align-items: flex-start;
  padding: 0 30px;
  position: relative;
  margin-top: 10px;
  justify-content: center;
  flex-direction: column;
  -webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 3s; /* Firefox < 16 */
  -ms-animation: fadein 3s; /* Internet Explorer */
  -o-animation: fadein 3s; /* Opera < 12.1 */
  animation: fadein 3s;

  @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }

  /* Firefox < 16 */
  @-moz-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }

  /* Internet Explorer */
  @-ms-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }

  /* Opera < 12.1 */
  @-o-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }
}

.titleCommunity {
  display: flex;
  margin: 12px 0;
  color: $color-oh-so-green;
  justify-content: center;
  align-self: flex-start;
  cursor: pointer;
  
  span {
    font-size: 2.2rem;
    line-height: 30px;
    font-family: $ff-sans-landingPage;
    font-weight: $fw-regular;
    border-bottom: 2px solid $color-oh-so-green;
  }

  a {
    margin-right: 20px;
  }

  svg {
    transform: translateY(60%);
  }
}

.inputTalkToUs {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.inputHalf {
  display: flex;
  flex-direction: row;
}

.inputField {
  width: 100%;
  margin-bottom: 16px;

  &:first-child {
    margin-right: 10px;
  }
}

.heading {
  font-family: $ff-sans-landingPage;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 155%;
  color: $color-snow-in-april;
  margin-bottom: 20px;
}

.actions {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;

  button {
    color: $color-ace-blue;
    min-width: 100px;
    text-transform: uppercase;
  }
}

.arrowForm {
  color: $color-snow-in-april;
}