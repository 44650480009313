@import 'src/assets/styles/utils/index.module.scss';

.imageFloating {
  max-height: 100vh;
  position: absolute;
  z-index: 13;
}

.icons {
  color: $color-snow-in-april;
}

.boxContent {
  position: absolute;
  background-color: $color-ace-blue;
  max-width: 95vw;
  max-height: 297px;
  left: 2.5%;
  right: 2.5%;
  bottom: 5%;
  border-radius: 2px;
  z-index: 15;

  .closeBtn {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 10;

    svg {
      color: $color-snow-in-april !important;
    }
  }
}

.boxText {
  margin: 6%;
  font-family: $ff-sans-landingPage;
  color: $color-snow-in-april;

  h3 {
    font-size: 2.2rem;
    line-height: 2.4rem;
    font-weight: $fw-medium;
    letter-spacing: 1.5px;
    margin-bottom: 4%;
  }

  p {
    font-size: 1.6rem;
    line-height: 2.8rem;
    font-weight: $fw-regular;
  }
}

.lightBarBlue {
  right: 40%;
  top: 28%;
  width: 20%;
  height: 35%;
}

.shadowBlue {
  right: 1.5%;
  top: 50%;
}

.houseBlue {
  right: 17.5%;
  top: 55%;
  width: 60vw;
}

.effectShadown img:first-child {
  position: absolute;
  mix-blend-mode: color;
}

.houseBlueTooltip {
  position: absolute;
  right: 20%;
  top: 48%;
  width: 60vw;
  z-index: 13;
}

.solarPanel {
  right: 71%;
  top: 55%;
  width: 20%;
}

.car {
  right: 70%;
  top: 76%;
  width: 20%;
}

.battery {
  right: 17%;
  top: 78%;
  width: 20%;
}

.consumption {
  right: 5%;
  top: 62%;
  width: 20%;
}

.diamondTooltip {
  position: absolute;
  left: 15%;
  top: 40%;
  width: 18%;
  z-index: 14;

  img {
    position: relative;
    left: -5px;
    top: 5px;
    width: 20px;
    height: 30px;
    object-fit: contain;
  }
}

.descriptionTooltip {
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: $fw-regular;
}

.diamond {
  right: 0%;
  top: 40%;
  width: 100%;
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}

.singleCommunity {
  right: 5%;
  top: 50%;
  width: 90%;
}

.linksCommunity {
  right: 15%;
  top: 54%;
  width: 70%;
}

.lineDiamondTooltip {
  position: absolute;
  right: 30%;
  top: 43%;
  z-index: 15;
}

.singleDiamondOne {
  right: 50%;
  top: 50%;
  width: 10%;
  z-index: 13;
}

.singleDiamondTwo {
  right: 70%;
  top: 55%;
  width: 10%;
  z-index: 13;
}

.singleDiamondThree {
  right: 51%;
  top: 71%;
  width: 10%;
  z-index: 13;
}

.singleDiamondFour {
  right: 72%;
  top: 68%;
  width: 10%;
  z-index: 13;
}

.singleDiamondFive {
  right: 32%;
  top: 72%;
  width: 10%;
  z-index: 13;
}

.singleDiamondSix {
  right: 41%;
  top: 81%;
  width: 10%;
  z-index: 13;
}

.singleDiamondSeven {
  right: 30%;
  top: 55%;
  width: 10%;
  z-index: 10;
}

.singleDiamondEight {
  right: 11%;
  top: 71%;
  width: 10%;
  z-index: 13;
}

.singleDiamondNine {
  right: 44.5%;
  top: 61%;
  width: 10%;
  z-index: 13;
}

.lineDiamond {
  right: 5%;
  top: 50%;
  width: 90%;
  z-index: 14;
}

.ring {
  right: 20%;
  top: 41%;
  width: 68%;
}

.ringTooltip {
  position: absolute;
  right: 29%;
  top: 38%;
  width: 50%;
  z-index: 14;

  img {
    position: relative;
    left: -5px;
    top: 5px;
    width: 20px;
    height: 30px;
    object-fit: contain;
  }
}

.chartTrades {
  right: 35%;
  top: 72%;
  width: 60%;
  z-index: 13;
}

.chartDay {
  right: 0%;
  top: 78%;
  width: 45%;
  z-index: 14;
}

.s_agents {
  right: 3%;
  top: 89%;
  margin-bottom: 8px;
}

.s_market {
  right: 3%;
  top: 94%;
}

.subtitle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: $color-ashy;
  border-radius: 5px;
  padding: 7px;
  max-width: 183px;
  width: 100%;

  span {
    margin-left: 8px;
    font-family: $ff-sans-landingPage;
    color: $color-snow-in-april;
    font-weight: $fw-medium;
    font-size: 1.3rem;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.mobileDocContainer {
  position: absolute;
  top: $header-height + 20px;
  left: 50%;
  transform: translate(-10px, 0);
  z-index: 99;

  .mobileDoc {
    background: #34363f;
    border-radius: 21px;
    width: 60px;
    height: 8px;
  }
}
