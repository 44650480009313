@import 'src/assets/styles/utils/index.module.scss';

.container {
}

.gridItem {
  :global(.avatar) {
    margin-bottom: 8px;
  }
  :global(.numbers) {
    border-top: thin solid $color-metal-grey;
    & > div {
      background-color: $color-ashy;
      &:nth-of-type(2) {
        background-color: $color-metal-grey;
      }
    }
  }
}
