@import 'src/assets/styles/utils/index.module.scss';

.container {
  position: relative;
  &.dropdownIsOpen {
    & > button {
      svg {
        transform: scaleY(-1);
      }
    }
  }
  &.size-s {
    height: 20px;
    width: 100%;
  }
  &.theme-white {
    & > button {
      background-color: $color-snow-in-april;
    }
  }
  & > button {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 6px;
    color: $color-ashy;
    svg {
    }
  }
  .dropdown {
    top: 100%;
    width: 100%;
    button {
      width: 100%;
      height: 20px;
      display: flex;
      align-items: center;
      padding: 6px;
      background-color: $color-athens-gray;
      &.checked {
        div {
          background-color: $color-oh-so-green;
        }
      }
      div {
        width: 12px;
        height: 12px;
        border: thin solid $color-metal-grey;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 4px;
      }
    }
  }
}
