@import 'src/assets/styles/utils/index.module.scss';

.container {
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  max-height: 250px;
  color: $color-black;
}

.list {
  background-color: $color-white;

  li:nth-child(odd) {
    background-color: $color-vape;
  }
}

.item {
  button {
    width: 100%;
    padding: 10px;
    text-align: left;
  }
}

.top {
  top: auto;
  bottom: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.bottom {
  top: 100%;
  bottom: auto;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
