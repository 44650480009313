@import 'src/assets/styles/utils/index.module.scss';

.container {
  font-size: 1.3rem;
  line-height: 1.4rem;
}

.sliderWrapper {
  display: flex;
  align-items: center;
}

.button {
  flex-shrink: 0;
  width: 15px;
  height: 15px;
}

.buttonLeft {
  margin-right: 4px;
  margin-left: 7px;
}

.label {
  display: block;
  margin-bottom: 10px;
}

.disabled {
  &,
  * {
    cursor: not-allowed !important;
  }
}

.slider {
  position: relative;
  width: 100%;
  height: 3px;
  pointer-events: none;
}

.input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;

  &::-webkit-slider-thumb {
    cursor: grab;
    pointer-events: auto;
  }
}

.themeBlack {
  .thumb {
    border: 2px solid  $color-black;
    background-color: $color-white;
  }
}

.themeGreen {
  .thumb {
    background-color: $color-oh-so-green;
  }
}

.track {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  background-color: $color-metal-grey;
  pointer-events: none;
}

.range {
  position: absolute;
  top: 0;
  right: var(--right-position);
  left: var(--left-position);
  height: 100%;
  transition: 0.1s $ease-in-out-quad;
}

.thumb {
  position: absolute;
  top: 50%;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  // transition: 0.1s $ease-in-out-quad;
  transform: translate(-50%, -50%);
}

.thumbLeft {
  left: var(--left-position);
  transform: translate(-50%, -50%);
}

.thumbRight {
  right: var(--right-position);
  transform: translate(50%, -50%);
}
