@import 'src/assets/styles/utils/index.module.scss';
.BigTable {
  .topHeader {
    display: grid;
    background-color: $color-pretty-dark;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    span {
      border-right: 0.001em solid $color-ashy;
      color: $color-snow-in-april;
      margin: 2px 0;
      height: 26px;
    }
    &.withFee {
      grid-template-columns: 2fr 3fr 2fr 2fr;
    }
    &.withoutFee {
      grid-template-columns: repeat(4, 2fr);
    }
  }
  .mainHeader {
    display: grid;
    &.withFee {
      grid-template-columns: 2fr 1fr 2fr repeat(4, 1fr);
    }
    &.withoutFee {
      grid-template-columns: 2fr repeat(6, 1fr);
    }
    .title {
      font-family: $ff-sans;
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
    }
  }
  .subHeader {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr 4fr;
    span:not(:last-of-type) {
      border-right: 0.001em solid $color-vape;
    }
  }
  .body {
    display: grid;
    &.withFee {
      grid-template-columns: 2fr repeat(7, 1fr);
    }
    &.withoutFee {
      grid-template-columns: 2fr repeat(6, 1fr);
    }
    .title {
      font-family: $ff-sans;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      height: 15px;
      margin-top: 5px;
      margin-bottom: 5px;
      padding-top: 2px;
      padding-left: 20px;
      border-left: solid $color-ashy 2px;
    }

    span {
      height: 40px;
    }
  }
  .firstColumn {
    text-align: start;
  }
  span {
    padding: 8px 4px;
    text-align: center;
    height: 30px;
    font-family: $ff-sans-landingPage;
    font-weight: 400;
    font-size: 11px;
    line-height: 12px;
    color: $color-ashy;
  }
  .colored {
    font-family: $ff-sans;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
  }
  .bold {
    font-size: 12px;
    line-height: 20px;
    color: inherit;
  }
  .energy {
    background-color: $color-vape;
  }
  .positive {
    color: #66ee66;
  }
  .negetive {
    color: #fc1355;
  }
  .neutral {
    color: #1641f7;
  }
}
.scroll {
  max-height: 180px;
  margin-top: 5px;
}
.miniTable {
  position: relative;
  margin-top: 5px;
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid $color-vape;
}
.miniItem {
  display: flex;
  background-color: $color-snow-in-april;
  margin-bottom: 3px;
  height: 40px;
  border-radius: 4px;
  padding: 8px 3px 8px 2px;
  align-items: center;
  span {
    border-left: solid $color-vape 1px;
    padding-left: 3px;
    flex: 0 0 20%;
  }
  .energy {
    flex: 0 0 30%;
  }
  .title {
    font-weight: 700;
    flex: 0 0 50%;
    color: $color-ashy;
    padding-left: 10px;
    border-left: solid $color-ashy 2px;
  }
}
.buttonExpand {
  position: absolute;

  width: 100%;
  height: 20px;

  -webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 3s; /* Firefox < 16 */
  -ms-animation: fadein 3s; /* Internet Explorer */
  -o-animation: fadein 3s; /* Opera < 12.1 */
  animation: fadein 3s;

  transition: filter 0.2s;
  &:hover {
    filter: brightness(0.9);
  }
  &.bottomButton {
    bottom: 5px;
    .arrow {
      transform: rotate(-90deg);
    }
  }
  &.topButton {
    top: 5;
    z-index: 1;
    .arrow {
      transform: rotate(90deg);
    }
  }
  &.notDisplay {
    animation-name: disapearAnimation;
    animation-duration: 700ms;
    animation-fill-mode: forwards;
  }
  @keyframes disapearAnimation {
    100% {
      display: none;
      opacity: 0;
    }
  }
}

.positive {
  color: #66ee66;
}

.negetive {
  color: #fc1355;
}
.neutral {
  color: #1641f7;
}
.gray {
  color: $color-metal-grey;
}
