@import 'src/assets/styles/utils/index.module.scss';

.container {
  .main {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 32px;
    header {
      display: grid;
      grid-template-columns: 50% 50%;
      grid-template-rows: auto auto;
      grid-row-gap: 40px;
      *:nth-child(2n + 2) {
        justify-self: end;
      }
      color: $color-ashy;
      h2 {
        font-size: 2.2rem;
        line-height: 26px;
        font-family: $ff-sans;
        font-weight: 500;
      }
      nav {
        width: 100%;
        display: flex;
        column-gap: 40px;
        button {
          font-size: 1.3rem;
          line-height: 100%;
          font-family: $ff-sans;
          font-weight: 400;
          padding-bottom: 4px;
          &.active {
            font-family: $ff-sans-landingPage;
            font-weight: 700;
            border-bottom: 2px solid $color-oh-so-green;
          }
        }
      }
      .actions {
        display: flex;
        & > * {
          margin-left: 12px;
        }
        .tags {
          display: flex;
          & > * {
            margin-left: 4px;
          }
        }
      }
    }
    .dataGrid {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      row-gap: 30px;
      column-gap: 30px;
      margin-top: 30px;
    }
    footer {
      display: flex;
      justify-content: flex-end;
      margin-top: 24px;
      & > * {
        background-color: $color-vape;
        border-radius: 12px;
      }
      span,
      button {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: $color-kinda-dirty;
        margin-left: 6px;
        font-weight: 500;
      }
      span {
        width: auto;
        padding: 0 8px;
      }
      button:nth-of-type(1) {
        transform: rotate(90deg);
      }
      button:nth-of-type(2) {
        transform: rotate(-90deg);
      }
    }
  }
}

.table {
  width: 100%;

  .sortIcon {
    display: inline-block;
    color: $color-metal-grey;
    margin-left: 8px;
    cursor: pointer;

    &.desc {
      transform: rotate(180deg);
    }
  }
  &.table {
    border-collapse: separate;
    border-spacing: 0 20px;
    .roleSelector {
      max-width: 180px;
    }
    thead {
      th {
        text-align: left;
        font-size: 1.3rem;
        font-weight: 500;
      }
    }
    tbody {
      td {
        background-color: $color-vape;
        height: 62px;
        vertical-align: middle;
        padding-right: 16px;
        &:not(:last-of-type) {
          min-width: 120px;
        }
        &:first-of-type {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          padding-left: 16px;
        }
        &:last-of-type {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
      .tableDataRow {
        :global(.details) {
          display: flex;
          align-items: center;
          :global(.avatar) {
            margin-right: 16px;
            width: 30px;
            height: 30px;
            border-radius: 100%;
            background-size: cover;
            &:global(.isLetter) {
              font-size: 16px;
              display: flex;
              align-items: center;
              justify-content: center;
              text-transform: uppercase;
              border: 1px solid $color-metal-grey;
            }
          }
          h6 {
            font-size: 13px;
            font-weight: 700;
            font-family: $ff-sans-landingPage;
          }
          p {
            margin-top: 4px;
            color: $color-metal-grey;
            font-size: 10px;
          }
        }
        :global(.assets) {
          display: flex;
          & > div {
            background-color: $color-white;
            padding: 3px 10px 3px 3px;
            display: flex;
            align-items: center;
            border-radius: 4px;
            margin-right: 12px;
            & > div {
              display: flex;
              align-items: center;
              height: 19px;
              border-radius: 4px;
              background-color: $color-ashy;
              color: $color-snow-in-april;
              margin-right: 4px;
              padding: 0 5px;
              span {
                font-size: 11px;
              }
              svg {
                margin-right: 6px;
              }
            }
          }
        }
      }
    }
  }
}

.gridItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  background-color: $color-vape;
  width: 186px;
  position: relative;
  padding: 20px 0 12px 0;
  :global(.moreMenu) {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 10px;
    height: 10px;
    display: flex;
    align-items: center;
  }
  :global(.content) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 25px;
    :global(.avatar) {
      width: 50px;
      height: 50px;
      border-radius: 100%;
      background-size: cover;
      &:global(.isLetter) {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        text-transform: uppercase;
        border: 1px solid $color-metal-grey;
      }
    }
    h6 {
      font-size: 13px;
      font-weight: 700;
      font-family: $ff-sans-landingPage;
      margin-bottom: 8px;
      text-align: center;
    }
    span {
      margin-bottom: 10px;
      color: $color-metal-grey;
      font-size: 9px;
    }
    :global(.roleSelector) {
      margin-bottom: 10px;
    }
    :global(.buildCanary) {
      width: 100%;
      height: 20px;
      border-radius: 4px;
      background-color: $color-burn-my-eyes;
      font-size: 11px;
      color: $color-pretty-dark;
      width: 140px;
      margin-bottom: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
    }
  }
  :global(.numbers) {
    width: 100%;
    display: flex;
    justify-content: center;
    column-gap: 8px;
    padding-top: 12px;
    & > div {
      display: flex;
      align-items: center;
      padding: 4px 5px;
      border-radius: 4px;
      background-color: $color-metal-grey;
      color: $color-snow-in-april;
      svg {
        margin-right: 6px;
      }
    }
  }
}
