@import 'src/assets/styles/utils/index.module.scss';

.ecosystemWrapper {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 60vh;
  z-index: 22;
  display: flex;
  align-items: center;
  padding: 45px;
}

.content {
  display: flex;
  flex: 1;
  position: relative;

  .cards {
    display: flex;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }

    .imgContainer {
      position: relative;
      min-width: 60px;
      display: flex;
      justify-content: center;
      align-items: center;

      @media only screen and (max-width: 768px) {
        min-width: 100%;
        min-height: 75px;
      }

      .icon {
        position: absolute;
        max-width: unset;
        width: 110px;
        left: -20px;
        z-index: 2;

        @media only screen and (max-width: 768px) {
          left: 50%;
          top: 50%;
          transform: translate(-50%, -70%);
        }
      }
    }
  }
}
