@import 'src/assets/styles/utils/index.module.scss';

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: absolute;
  margin: auto;
  padding: 80px 30px 35px;
  border-radius: 4px;
  height: 100vh;
  width: 100%;
  .cardContainer {
    background-color: $color-ashy;
    height: auto;
    min-height: 500px;
    width: 100%;
    padding: 30px;
    position: relative;
    @media only screen and (max-width: 768px) {
      min-height: unset;
    }
  }
  .closeBtn {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 20px;
    top: 20px;
    background-color: $color-oh-so-green;
    svg {
      color: $color-ashy;
    }
  }

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    flex-wrap: wrap;

    .title {
      font-family: GT Cinetype;
      font-style: normal;
      font-weight: bold;
      font-size: 25px;
      line-height: 27px;
      letter-spacing: -0.02em;
      color: $color-snow-in-april;
      text-decoration-line: underline;
      text-underline-position: under;
      text-decoration-color: $color-burn-my-eyes;

      @media only screen and (max-width: 768px) {
        margin-bottom: 1.5em;
      }
    }

    .logoContainer {
      display: flex;

      @media only screen and (max-width: 768px) {
        flex-wrap: wrap;
        width: 100%;
      }

      .partnerLogo {
        width: 100%;
        height: 100%;
        max-width: 80px;
        max-height: 40px;
        object-fit: contain;
        margin-right: 2.4rem;
      }
    }
  }

  .aggregatorContainer {
    justify-content: space-between;

    .box {
      margin-right: unset;
      width: 30%;
      min-width: unset;
      align-items: flex-start;
      padding: 20px;

      h3 {
        text-align: center;
        font-weight: 500;
        font-size: 18px;
        line-height: 19px;
        color: $color-pretty-dark;
      }
    }
  }

  .bodySection {
    display: flex;
    flex: 1;
    flex-direction: row;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }

    .image {
      background-color: $color-snow-in-april;
      border-radius: 4px;
      box-sizing: content-box;

      @media only screen and (max-width: 768px) {
        width: auto;
        margin: 0 0 3.2rem 0;
      }

      img {
        width: 100%;
        height: 320px;
        object-fit: contain;
      }

      margin: 0 15px;

      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }

    .details {
      color: $color-snow-in-april;
      font-size: 14px;
      line-height: 25px;
      // display: flex;
      // flex-direction: column;
      // justify-content: space-between;

      .heading {
        font-family: NB Akademie Std;
        font-weight: normal;
        font-size: 20px;
        line-height: 22px;
        color: $color-snow-in-april;
        margin-bottom: 22px;
      }

      .smHeading {
        font-weight: bold;
        font-size: 15px;
        line-height: 18px;
        text-transform: uppercase;
        color: $color-snow-in-april;
        margin-bottom: 15px;
      }

      .text {
        font-family: $ff-sans-landingPage;
        font-weight: $fw-regular;
        font-size: 1.4rem;
        line-height: 2rem;
        color: $color-snow-in-april;
        margin-bottom: 15px;
      }
    }
  }
}

.noBorderRadius {
  border-radius: 0px !important;

  .closeBtn {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 25px;
    top: 30px;
    background-color: $color-oh-so-green;
    svg {
      color: $color-ashy;
    }
  }
}

.gridContainer {
  display: grid;
  grid-template-columns: auto auto auto;

  .gridItem {
    padding: 10px;
    font-size: 30px;
    text-align: center;

    img {
      width: 100%;
      height: 100%;
      max-width: 80px;
      max-height: 40px;
      object-fit: contain;
    }
  }
}
