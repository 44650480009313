@import 'src/assets/styles/utils/index.module.scss';

.container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;

  :global(.mapbox-map) {
    -webkit-tap-highlight-color: transparent;
  }

  :global(.mapboxgl-canvas-container) {
    position: relative;
    width: 100%;
    height: 100%;
    cursor: grab;
    -webkit-user-select: none;
    user-select: none;
  }

  :global(.mapboxgl-canvas) {
    position: absolute;
    top: 0;
    left: 0;
    outline: none;
  }
}

.mapNavigation {
  position: absolute;
}

.theMap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: $z-the-map;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.selectedLocationMarker {
  position: relative;
  pointer-events: none;
}

.pinIcon {
  position: absolute;
  top: 0;
  left: 50%;
  color: $color-ace-blue;
  transform: translate(-50%, -100%);
}

.globalLocationMarker {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cursorDefault {
  cursor: default !important;
}
