@import 'src/assets/styles/utils/index.module.scss';

.container {
  position: relative;
  &.opened {
    .icon {
      svg {
        transform: rotate(180deg);
      }
    }
    .dropdown {
      visibility: visible;
    }
  }
  .valueButton {
    width: 100%;
    height: 35px;
    padding-left: 8px;
    display: flex;
    align-items: center;
    border: thin solid $color-black;
    border-radius: 5px;
    p {
      padding-left: 12px;
      flex: 1;
      text-align: left;
    }
    .icon {
      padding: 0 10px;
      height: 25px;
      border-left: thin solid $color-vape;
      display: flex;
      align-items: center;
    }
  }
  .dropdown {
    width: 100%;
    border: thin solid $color-black;
    border-radius: 5px;
    position: absolute;
    top: calc(100% + 10px);
    background-color: $color-white;
    z-index: 100;
    overflow: hidden;
    visibility: hidden;
    button {
      width: 100%;
      height: 30px;
      display: flex;
      align-items: center;
      padding: 0 15px;
      &:hover {
        background-color: $color-snow-in-april;
      }
      &:not(:last-of-type) {
        border-bottom: thin solid $color-metal-grey;
      }
      span {
        width: 6px;
        height: 6px;
        background-color: $color-ashy;
        transform: rotate(45deg);
        margin-right: 12px;
      }
    }
  }
}
