@import 'src/assets/styles/utils/index.module.scss';

.mapboxWrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: $z-popup;
  pointer-events: none;
}

.container {
  transform: translate3d(0, 0, 0); // Fixes blurry content on Chrome
}

.higherZIndex {
  z-index: $z-popup + 1;
}
