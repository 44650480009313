@import 'src/assets/styles/utils/index.module.scss';

.container {
  position: relative;
  height: 40px;
  border-radius: 4px;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    width: 2px;
    height: 18px;
    transform: translateY(-50%);
  }
}

.mainButton {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: left;
}

.badge-color-primary::after {
  background-color: $color-ace-blue;
}

.badge-color-secondary::after {
  background-color: $color-singularity-red;
}

.themeLight {
  background-color: $color-vape;

  .title {
    color: $color-ashy;
  }
}

.themeDark {
  background-color: $color-ashy;

  .title {
    color: $color-snow-in-april;
  }
}

.dragButton {
  position: absolute;
  top: 3px;
  right: 3px;
  color: $color-metal-grey;
  cursor: move;
}

.icon {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 36px;
  color: $color-metal-grey;
}

.textWrapper {
  flex: 1;
  width: calc(100% - 65px);
}

.title {
  color: $color-metal-grey;
  font-weight: $fw-bold;
  font-size: 1.2rem;
  line-height: 1.6rem;
}

.details {
  color: $color-metal-grey;
  font-size: 1.1rem;
}

.closeBtn {
  position: absolute;
  top: 50%;
  right: 3px;
  transform: translateY(-50%);
}

.exportIndicator {
  overflow: hidden;
  width: 20px;
  height: 100%;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.threeDots {
  position: absolute;
  top: 5px;
  right: 5px;
}
