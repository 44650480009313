@import 'src/assets/styles/utils/index.module.scss';

.container {
  position: relative;
}

.input {
  cursor: pointer;
  input {
    pointer-events: none !important;
  }

  svg {
    transition: transform 0.3s $ease-in-out-quad;
  }
}

.disabled {
  &,
  * {
    cursor: not-allowed !important;
  }
}

.opened {
  .input svg {
    transform: scaleY(-1);
  }
}
