@import 'src/assets/styles/utils/index.module.scss';

.container {
}

.switchWrapper {
  display: flex;
  align-items: center;
  margin-top: -1px;
  padding: 15px 0;
  border-top: thin solid rgba($color-metal-grey, 0.1);

  & + & {
    border-bottom: thin solid rgba($color-metal-grey, 0.1);
  }
}

.switch {
  width: 100%;
}

.inputField,
.checkboxField,
.sliderField,
.locationField,
.selectField,
.fileUploadField {
  margin-top: 30px;
}

.counter {
  margin-top: 20px;
}

.marginTopSmall {
  margin-top: 15px;
}

.sevenDaysTag {
  min-width: 72px;
}

.datePickerContainer {
  margin-bottom: 50px;
}

.datePickerNoMargin {
  margin-top: 0;
}

.lengthWrapper {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 8px;
}

.lengthText {
}

.lengthTooltip {
  margin-left: 8px;
}

.locationSearchDropdown {
  max-height: 85px;
}

.locationLabelPositioner {
  label {
    margin-left: -18px;
  }
}
