@import 'src/assets/styles/utils/index.module.scss';

.worldMap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.formLogin {
  position: absolute;
  top: 100px;
  left: 100px;
}

.content {
  overflow-y: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 5% 70px 15% 70px;
  -webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 3s; /* Firefox < 16 */
  -ms-animation: fadein 3s; /* Internet Explorer */
  -o-animation: fadein 3s; /* Opera < 12.1 */
  animation: fadein 3s;

  p {
    margin-top: 4%;
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Firefox < 16 */
  @-moz-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Internet Explorer */
  @-ms-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Opera < 12.1 */
  @-o-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  transition: filter 0.2s;
  &:hover {
    color: $color-london-fog;
  }

  @media only screen and (min-width: 300px) and (max-width: 900px) {
    padding: 5% 30px 25% 30px;
  }
}

.content::after {
  position: fixed;
  content: ' ';
  bottom: 0;
  right: 0;
  width: 50%;
  z-index: 1;
  background-image: linear-gradient(transparent, #211e21);
  height: 90px;

  @media only screen and (min-width: 300px) and (max-width: 900px) {
    width: 100%;
  }
}

.boxText {
  color: $color-snow-in-april;
  font-family: $ff-sans-landingPage;
  font-weight: $fw-regular;
  font-size: 2.4rem;
  line-height: 3.6rem;

  a {
    color: $color-oh-so-green;
    cursor: pointer;
    border-bottom: 2px solid $color-oh-so-green;

    transition: filter 0.2s;
    &:hover {
      filter: brightness(0.8);
    }
  }

  @media only screen and (min-width: 300px) and (max-width: 900px) {
    font-size: 2.2rem;
    line-height: 3.2rem;
  }
}

.boxImage {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5%;
  width: 100%;

  img {
    max-width: 100%;
    height: auto;
    margin-right: 60px;
    -webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 3s; /* Firefox < 16 */
    -ms-animation: fadein 3s; /* Internet Explorer */
    -o-animation: fadein 3s; /* Opera < 12.1 */
    animation: fadein 3s;
  }
  .imgEconomic {
    height: 62px;
    width: 62px;
  }

  .imgBeworld {
    height: 63px;
    width: 55px;
  }

  .imgCleantech {
    height: 26px;
    width: 96px;
  }
}
