@import 'src/assets/styles/utils/index.module.scss';

.hoverCard {
  cursor: pointer;
  -webkit-transition: background-color 0.5s; /* For Safari 3.0 to 6.0 */
  transition: background-color 0.5s; /* For modern browsers */

  &:hover {
    background-color: $color-ashy;

    .title {
      color: $color-snow-in-april;
      text-decoration-line: underline;
      text-underline-position: under;
      text-decoration-color: $color-burn-my-eyes;
    }

    .learnMoreLink {
      visibility: visible;
    }

    .description {
      color: $color-snow-in-april;
    }

    // .image {
    //   &::after {
    //     position: absolute;
    //     content: " ";
    //     bottom: 0;
    //     right: 0;
    //     width: 10%;
    //     z-index: 1;
    //     background-image: linear-gradient(transparent, #6e6);
    //     height: 200px;
    //     opacity: 0.5;
    //   }
    // }
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 25px;

  // min-width: 330px;
  min-width: calc(25vw - 70px);
  min-height: 485px;
  height: 100%;
  background: rgba(209, 207, 207, 0.2);
  backdrop-filter: blur(10px);
  border-radius: 4px;

  @media only screen and (min-width: 992px) and (max-width: 1600px) {
    max-height: 400px;
    min-height: 150px;
  }
}

.title {
  font-family: GT Cinetype;
  font-size: 25px;
  line-height: 27px;

  text-align: center;
  letter-spacing: -0.02em;
  color: $color-kinda-dirty;
  position: relative;
}

.descContainer {
  position: relative;
  min-height: 330px;
}

.image {
  width: 100%;
  height: 275px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media only screen and (min-width: 992px) and (max-width: 1600px) {
    height: 190px;

    img {
      width: 60%;
    }
  }
}

.description {
  font-family: $ff-sans-landingPage;
  font-weight: $fw-regular;
  font-size: 1.6rem;
  line-height: 2.2rem;
  text-align: center;
  color: $color-kinda-dirty;

  @media only screen and (min-width: 992px) and (max-width: 1600px) {
    font-size: 1.4rem;
    line-height: 2rem;
    margin-top: -16%;
  }
}

.learnMoreLink {
  visibility: hidden;
  font-family: GT Cinetype;
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  line-height: 2.2rem;

  text-align: center;
  letter-spacing: -0.02em;
  text-decoration-line: underline;
  color: $color-burn-my-eyes;
  text-underline-position: under;

  @media only screen and (min-width: 992px) and (max-width: 1600px) {
    font-size: 1.6rem;
    line-height: 2.2rem;
    margin-top: -35%;
  }
}
