@import 'src/assets/styles/utils/index.module.scss';

.menuItem {
  display: flex;
  align-items: center;
  padding: 12px;
  color: $color-white;
  font-weight: $fw-medium;
  font-size: 1.2rem;
  font-family: $ff-sans;

  & + & {
    border-top: 1px solid $color-kinda-dirty;
  }

  &:disabled {
    opacity: 0.6;
  }
}

.icon {
  margin-right: 10px;
}
