@import 'src/assets/styles/utils/index.module.scss';

.container {
  position: absolute;
  left: 0;
  top: $header-height;
  bottom: 0;
  width: 56px;
  background-color: $color-white;
  z-index: $z-modal-sticky-header;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  backdrop-filter: blur(18px);
  filter: drop-shadow(3px 0px 7px rgba(0, 0, 0, 0.05));
  nav {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    button {
      width: 32px;
      height: 32px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-metal-grey;
      position: relative;
      &.active {
        background-color: $color-ashy;
        color: $color-vape;
        &:after {
          content: '';
          position: absolute;
          top: -1px;
          right: 0;
          transform: translateX(12px);
          background-color: $color-ashy;
          width: 2px;
          height: 34px;
        }
      }
      &:not(:last-of-type) {
        margin-bottom: 40px;
      }
    }
  }
  footer {
    border-top: thin solid $color-vape;
    padding-top: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    ul {
      margin-top: 10px;
      margin-bottom: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      li {
        margin-top: 20px;
        a {
          padding: 0px;
          svg {
            color: $color-metal-grey;
          }
        }
      }
    }
    & > span {
      background-color: $color-vape;
      height: 24px;
      width: 100%;
      text-align: center;
      line-height: 24px;
    }
  }
}
