@import 'src/assets/styles/utils/index.module.scss';

.container {
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 35px;
  position: relative;
  flex-wrap: wrap;
  padding-bottom: 20%;
  -webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 3s; /* Firefox < 16 */
  -ms-animation: fadein 3s; /* Internet Explorer */
  -o-animation: fadein 3s; /* Opera < 12.1 */
  animation: fadein 3s;

  @media only screen and (min-width: 300px) and (max-width: 900px) {
    padding: 0 30px 15% 30px;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    margin-bottom: 80px; // last item in the list clickable
  }
}

.containerHalf {
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  -webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 3s; /* Firefox < 16 */
  -ms-animation: fadein 3s; /* Internet Explorer */
  -o-animation: fadein 3s; /* Opera < 12.1 */
  animation: fadein 3s;
}

.container::after {
  position: fixed;
  content: ' ';
  bottom: 0;
  right: 0;
  width: 50%;
  z-index: 1;
  background-image: linear-gradient(transparent, $color-ace-blue);
  height: 120px;
  pointer-events: none;

  @media only screen and (min-width: 300px) and (max-width: 900px) {
    width: 100%;
  }
}

.container,
.containerHalf {
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Firefox < 16 */
  @-moz-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Internet Explorer */
  @-ms-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Opera < 12.1 */
  @-o-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  transition: filter 0.2s;
  &:hover {
    color: $color-london-fog;
  }
}

.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  width: 100%;

  &:first-child {
    margin-top: 50px;
  }

  img {
    max-width: 100%;
    width: 100%;
  }
}

.border {
  border: 1px solid $color-metal-grey;
  margin-left: -1px;
  margin-top: -1px;
}

.trendingRow {
  display: flex;
  width: 100%;
}

.boxHalf {
  align-items: center;
  max-width: calc(50% - 2px);
  width: calc(50% - 2px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  overflow-wrap: anywhere;

  img {
    max-width: 100%;
    width: 100%;
  }

  @media only screen and (min-width: 300px) and (max-width: 900px) {
    max-width: 100%;
    width: 100%;
  }
}

.dateLocal {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  margin-top: 20px;

  .eventDetail {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
}

.boxText {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 23px 23px 0 23px;
  width: 100%;

  h2 {
    color: $color-snow-in-april;
    font-size: 3rem;
    line-height: 3.3rem;
    font-family: $ff-sans-landingPage;
    font-weight: $fw-regular;
  }

  span {
    color: $color-london-fog;
    font-family: $ff-sans-landingPage;
    font-size: 1.4rem;
    line-height: 2.6rem;
  }

  @media only screen and (min-width: 300px) and (max-width: 900px) {
    h2 {
      font-size: 2.6rem;
      line-height: 3.4rem;
    }
    span {
      font-size: 2.2rem;
      line-height: 3.2rem;
    }
  }
}

.textSmall {
  color: $color-london-fog;
  font-family: $ff-sans-landingPage;
  letter-spacing: 2px;
  font-size: 1.6rem;
  line-height: 2.4rem;
  text-transform: uppercase;
}

.boxTextHalf {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  padding: 23px 23px 0 23px;

  h2 {
    color: $color-snow-in-april;
    font-size: 3rem;
    line-height: 3.3rem;
    font-family: $ff-sans-landingPage;
    font-weight: $fw-regular;
  }

  span {
    color: $color-london-fog;
    font-size: 1.4rem;
    line-height: 2.6rem;

    &:last-child {
      text-transform: uppercase;
      letter-spacing: 2px;
    }
  }

  @media only screen and (min-width: 300px) and (max-width: 900px) {
    h2 {
      font-size: 2.6rem;
      line-height: 3.4rem;
    }
    span {
      font-size: 2.2rem;
      line-height: 3.2rem;
    }
  }
}

.buttonPosition {
  display: flex;
  align-self: flex-end;
}

.buttonApply {
  width: 134px;
  height: 38px;
  background: $color-oh-so-green;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 1px;

  span {
    color: $color-ace-blue;
    font-size: 1.8rem;
    font-family: $ff-sans-landingPage;
    font-weight: $fw-medium;
    text-transform: uppercase;
    letter-spacing: 1px;
    transform: translateY(12%);
  }

  transition: filter 0.2s;
  &:hover {
    filter: brightness(0.9);
  }
}
