@import 'src/assets/styles/utils/index.module.scss';

.counterWrapper {
  width: 110px;
  color: $color-snow-in-april;
}

.disabled {
  &,
  * {
    cursor: not-allowed !important;
  }
}

.title {
  display: block;
  margin-bottom: 11px;
  font-weight: $fw-medium;
  font-size: 1.4rem;
  line-height: 1.5rem;
  white-space: nowrap;
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 4px;
  background-color: $color-metal-grey;
  color: $color-pretty-dark;
  cursor: pointer;
}

.value {
  color: inherit;
  font-weight: $fw-bold;
  font-size: 1.3rem;
  line-height: 1.4rem;
}
