@import 'src/assets/styles/utils/index.module.scss';

.inputsWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
}

.input {
  width: calc(50% - 15px);
}

.rightDirection {
  flex-direction: row-reverse;
}

.buttonRegister {
  width: 100%;
  margin-bottom: 30px;
}

.checkboxWrapper {
  display: flex;
}

.checkbox {
  margin-right: 14px;
}

.termsBtn {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.recaptchaContainer {
  margin-bottom: 8px;
}

.startText {
  margin: 30px 0;
  font-family: $ff-sans;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: $color-metal-grey;
}
