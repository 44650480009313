@import 'src/assets/styles/utils/index.module.scss';

.content {
  display: flex;
  flex: 1;
  height: 100vh;
  background-color: $color-ashy;
  flex-direction: column;
  position: relative;
  padding: $header-height 40px 0 40px;

  &::after {
    position: fixed;
    content: ' ';
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 1;
    background-image: linear-gradient(transparent, #34363f);
    height: 200px;
  }

  @media only screen and (min-width: 300px) and (max-width: 900px) {
    max-width: 100%;
    padding: $header-height 30px 0 30px;
    height: unset;
  }

  .buttonBack {
    z-index: 1;
    margin: 4% 0;
    padding: 0;
    background: none;
    font-weight: $fw-regular;
    font-size: 16px;
    font-family: 'NB Akademie Std';
    line-height: 17px;
    letter-spacing: 1px;
    display: flex;
    justify-content: flex-start;

    @media only screen and (max-width: 600px) {
      margin: 12% 0;
    }
  }

  .container {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;

    .heading {
      font-family: $ff-sans-landingPage;
      font-weight: 500;
      font-size: 2.5em;
      line-height: 32px;
      color: $color-oh-so-green;
      margin-bottom: 30px;
    }

    .text {
      font-family: $ff-sans-landingPage;
      font-weight: 500;
      font-size: 2em;
      line-height: 155%;
      color: $color-snow-in-april;
      margin: 20px 0;
      white-space: pre-wrap;
      ol,
      ul {
        list-style: decimal;
        padding-left: 40px;
        ol,
        ul {
          list-style: disc;
        }
      }
    }
  }
}
