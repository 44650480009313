@import 'src/assets/styles/utils/index.module.scss';

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.content {
  height: 82px;
  bottom: 0px;

  .chart {
    padding: 0 0 0 0;
  }

  .tooltip {
    background: linear-gradient(17.86deg, #211e21 -60.33%, rgba(33, 30, 33, 0.94) 98.42%);
    border-radius: 8px;
    padding: 8px 10px;
    color: $color-snow-in-april;
    position: absolute !important;
  }
}

.cursorPointer {
  cursor: pointer;
}
