@import 'src/assets/styles/utils/index.module.scss';

html,
body,
#app-root {
  overflow: hidden;
  height: 100vh;
}

.parallaxLayer {
  max-height: 100vh;
  overflow: hidden;
  width: 100%;
  display: flex;
  z-index: 3;
}

.parallaxText {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-white;
  z-index: 11 !important;
}

.layersContainer {
  width: 100wh;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.mainLayer {
  // border: 1pt solid red;
  position: absolute;
  height: 600px;
  width: 600px;
  display: flex;
  margin-right: 9%;
  justify-content: center;
  align-items: center;
  margin-bottom: 10vh;

  @media screen and (max-width: 1200px) {
    zoom: 0.75;
  }

  @media screen and (min-width: 1201px) and (max-width: 1600px) {
    margin-right: 5%;
    height: 450px;
    width: 450px;
  }

  @media only screen and (min-width: 1920px) and (min-height: 1080px) {
    height: 700px;
    width: 700px;
  }

  @media only screen and (min-width: 2560px) and (min-height: 1080px) {
    height: 900px;
    width: 900px;
  }

  @media only screen and (min-width: 2880px) and (min-height: 1800px) {
    height: 1000px;
    width: 1000px;
  }

  @media only screen and (min-width: 3072px) and (min-height: 1920px) {
    height: 1200px;
    width: 1200px;
  }
}

.mainLayerExchange {
  position: absolute;
  height: 600px;
  width: 600px;
  display: flex;
  margin-right: 9%;
  justify-content: center;
  align-items: center;
  margin-bottom: 10vh;

  @media screen and (max-width: 1200px) {
    zoom: 0.75;
  }

  @media screen and (min-width: 1201px) and (max-width: 1600px) {
    margin-right: 5%;
    height: 460px;
    width: 460px;
    zoom: 1.15;
  }

  @media only screen and (min-width: 1920px) and (min-height: 1080px) {
    height: 700px;
    width: 700px;
  }

  @media only screen and (min-width: 2560px) and (min-height: 1080px) {
    height: 900px;
    width: 900px;
  }

  @media only screen and (min-width: 2880px) and (min-height: 1800px) {
    height: 1000px;
    width: 1000px;
  }

  @media only screen and (min-width: 3072px) and (min-height: 1920px) {
    height: 1200px;
    width: 1200px;
  }
}

.wrapper div {
  // z-index: 10;
}

.pWrapper > div {
  z-index: 11;
}

.backgroundLayer {
  background-color: $color-pretty-dark;
  height: 100vh;
  width: 100%;
  max-width: 100%;
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 0;
  top: 0px;
}

.waveBackground {
  height: 100vh;
  width: 100%;
  max-width: 100%;
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right -50vh bottom 60%;
  z-index: 1;
  top: 0px;

  @media only screen and (min-width: 1280px) {
    background-position: right -47vh bottom 65%;
  }

  @media only screen and (min-width: 1366px) {
    background-position: right -50vh bottom 65%;
  }

  @media only screen and (min-width: 1440px) {
    background-position: right -50vh bottom 65%;
  }

  @media only screen and (min-width: 1600px) {
    background-position: right -45vh bottom 65%;
  }

  @media only screen and (min-width: 1920px) {
    background-position: right -44vh bottom 65%;
  }

  @media only screen and (min-width: 2547px) {
    background-position: right -40vh bottom 65%;
  }

  @media only screen and (min-width: 2560px) {
    background-position: right -40vh bottom 65%;
  }

  @media only screen and (min-width: 2880px) {
    background-position: right -40vh bottom 65%;
  }

  @media only screen and (min-width: 3072px) {
    background-position: right -40vh bottom 65%;
  }

  @media only screen and (min-width: 3840px) {
    background-position: right -48vh bottom 65%;
  }
}

.layerTitle {
  z-index: -1 !important;
  max-width: 35%;
  max-height: 50%;
}

.bottomToTopFade {
  position: absolute;
  left: 50%;
  z-index: 21 !important;
  bottom: 6px;
  -webkit-animation: slideInFade 6s forwards;
  -moz-animation: slideInFade 6s forwards;
  animation: slideInFade 6s forwards;
}

.scrollBtn {
  background: none;
  transform: translateX(-50%) translateY(-50%);

  span {
    font-family: 'NB Akademie Std', sans-serif;
    font-weight: $fw-medium;
    letter-spacing: 1px;
    font-size: 2.7rem;
    margin-right: 16px;
    transition: filter 0.2s;
  }

  @media screen and (max-width: 1200px) {
    zoom: 0.8;
  }
}

.scrollBtnDisabled {
  cursor: default;

  span,
  svg {
    opacity: 0.2;
  }
}

.buttonScroll {
  color: $color-snow-in-april;
}

.boxActions {
  height: 160px;
  justify-content: space-between;
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 30%;
  top: 28%;
  z-index: 21;

  @media screen and (max-width: 1200px) {
    zoom: 0.6;
  }

  @media only screen and (min-width: 1280px) {
    left: 34%;
    top: 29%;
    zoom: 0.6;
  }

  @media only screen and (min-width: 1366px) {
    left: 32%;
    top: 31%;
    zoom: 0.8;
  }

  @media only screen and (min-width: 1440px) {
    top: 31%;
  }

  @media only screen and (min-width: 1600px) {
    left: 33%;
    top: 28%;
  }

  @media only screen and (min-width: 1920px) {
    left: 33%;
    top: 26%;
  }

  @media only screen and (min-width: 2547px) {
    left: 36%;
    top: 23%;
  }

  @media only screen and (min-width: 2560px) {
    left: 36%;
    top: 22%;
  }

  @media only screen and (min-width: 2880px) {
    left: 35%;
    top: 22%;
  }

  @media only screen and (min-width: 3072px) {
    left: 35%;
    top: 22%;
  }

  @media only screen and (min-width: 3840px) {
    left: 28%;
    top: 22%;
  }

  .action {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: $color-snow-in-april;
    margin-bottom: 10px;
    cursor: pointer;

    span {
      font-family: $ff-mono;
      color: $color-metal-grey;
      opacity: 0.9;
      font-size: 2.9rem;
      margin-left: 20px;
    }
  }

  .activeAction {
    span {
      color: $color-snow-in-april;
      opacity: 1;
    }
  }

  .disabledAction {
    span {
      cursor: default;
      color: $color-metal-grey;
      opacity: 0.2 !important;
    }

    button {
      cursor: default;
    }
  }
}

.colorOposite {
  color: $color-snow-in-april !important;
}

.boxCustomSize {
  height: 106px;
}
