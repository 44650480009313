.scmSingleSimulationNotification {
  position: absolute;
  top: 70px;
  width: 300px;
  padding: 20px 14px;
  background: black;
  border-radius: 10px;
  color: #ccc;
  right: 90px;
}
