@import 'src/assets/styles/utils/index.module.scss';

.container {
  display: flex;
  height: 100%;
}

.dataGrid {
  flex: 1;
}

.gridItem {
  cursor: pointer;
  :global(.content) {
    p {
      font-size: 1.1rem;
      color: $color-kinda-dirty;
    }
  }
}

.tableItem {
  :global(.userDetails) {
    margin-left: 18px;
  }
  :global(.user) {
    width: fit-content;
    height: 26px;
    padding: 0 12px;
    border-radius: 13px;
    font-size: 1.2rem;
    color: $color-ace-blue;
    background-color: $color-white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  :global(.actions) {
    display: flex;
    align-items: center;
    height: 60%;
    border-left: thin solid $color-metal-grey;
    button {
      padding: 2px;
      margin-left: 18px;
    }
  }
}
