@import 'src/assets/styles/utils/index.module.scss';

.root {
  --label-color: currentColor;
  --line-color: #{$color-metal-grey};
  --icon-color: #{$color-metal-grey};
  --border-filled-color: transparent;
}

.hasPlaceholder.elevateLabel.themeLineLight:not(.hasError) {
  --label-color: #{$color-metal-grey};
}

.container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 1.3rem;
  line-height: 1.4rem;

  .themeLine & {
    padding-right: 8px;
    border-bottom-width: thin;
    border-bottom-style: solid;
    border-bottom-color: var(--line-color);
  }

  .themeFilled & {
    padding-right: 10px;
    padding-left: 10px;
    border: thin solid;
    border-color: var(--border-filled-color);
    border-radius: 4px;
    color: $color-ashy;
  }

  .themeOutlineGray & {
    padding-right: 10px;
    padding-left: 10px;
    border: thin solid;
    border-color: var(--line-color);
  }

  .themeLineLight & {
    color: $color-snow-in-april;
  }

  .themeLineDark & {
    color: $color-ashy;
  }

  .themeWhite & {
    background-color: $color-snow-in-april;
  }

  .themeGray & {
    background-color: $color-vape;
  }

  .themeDark & {
    background-color: $color-metal-grey;
  }

  .themeDarkGray & {
    background-color: rgba($color-kinda-dirty, 0.6);
    color: rgba($color-snow-in-april, 0.5);
  }

  .themeOutlineGray & {
    color: $color-snow-in-april;
  }
}

input,
textarea {
  &::placeholder {
    color: currentColor;
    opacity: 1;
  }
}

.input-height-1 {
  input,
  textarea,
  .label {
    height: 30px;
  }

  textarea {
    min-height: 30px;
    height: 100% !important;
    padding-top: 8px;
  }
}

.textareaWrapper {
  max-height: 100px;
  overflow-y: auto;
}

.textareaWrapperBig {
  max-height: 230px;
  overflow-y: auto;
}

.input-height-2 {
  input,
  textarea,
  .label {
    height: 22px;
  }

  textarea {
    min-height: 22px;
    padding-top: 3px;
  }
}

.input-height-3 {
  input,
  textarea,
  .label {
    height: 36px;
  }

  textarea {
    min-height: 36px;
    padding-top: 3px;
  }
}

.input-height-10 {
  input,
  textarea,
  .label {
    height: 36px;
  }

  textarea {
    min-height: 220px;
    padding-top: 3px;
  }
}

.innerContainer {
  position: relative;
  flex-grow: 1;
  height: 100%;
}

.input {
  width: 100%;
}

.textarea {
  width: 100% !important;
}

.iconWrapper {
  flex-shrink: 0;
}

.iconWrapperLeft {
  padding-right: 8px;
}

.iconWrapperRight {
  padding-left: 8px;
}

.icon {
  color: var(--icon-color);
}

.iconError {
  color: var(--icon-color);
}

.PEN {
  pointer-events: none;
}

.label {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  color: var(--label-color);
  white-space: nowrap;
  transition: transform 0.15s $ease-out-quad, opacity 0.15s $ease-out-quad;
  transform-origin: left;
  letter-spacing: 0.4px;
  composes: PEN;

  .lengthTooltip {
    margin-left: 8px;
    pointer-events: all;

    svg {
      max-width: 14px;
      max-height: 14px;
    }
  }
}

.rightSlot {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  margin-left: 10px;
}

.unit {
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.15s $ease-out-quad;
}

.unitAlwaysVisible {
  opacity: 1 !important;
}

.ghostLabel {
  &.focused,
  &.filled {
    .label {
      opacity: 0;
    }

    .unit {
      opacity: 1;
    }
  }
}

.elevateLabel {
  &.focused,
  &.filled,
  &.hasPlaceholder {
    .label {
      transform: translateY(-20px) scale(0.75);
    }

    input::placeholder {
      opacity: 1;
    }

    .unit {
      opacity: 1;
    }
  }
}

.hasError {
  --label-color: #{$color-error};
  --line-color: #{$color-soft-singularity-red};
  --icon-color: #{$color-soft-singularity-red};
  --border-filled-color: #{$color-error};
  // --border-color: #{$color-error};
}

.focused {
  --line-color: #{$color-oh-so-green};
  --border-filled-color: #{$color-oh-so-green};
}

.fakeInput {
  .input {
    opacity: 0;
  }
}

.filename {
  position: absolute;
  top: 50%;
  left: 0;
  width: 75%;
  pointer-events: none;
  transform: translateY(-50%);
}

.chooseFile {
  position: absolute;
  top: 50%;
  width: 100px;
  pointer-events: none;
  transform: translateY(-50%) translateX(-95%);
  background: $color-oh-so-green;
  padding: 6px;
  text-align: center;
  svg {
    display: inline-block;
    margin-right: 5px;
  }
}

.disabled {
  cursor: not-allowed !important;
  .input {
    color: $color-metal-grey;
  }
  * {
    pointer-events: none;
  }
}

.chooseFileWithUnit {
  left: 95px !important;
}
