@import 'src/assets/styles/utils/index.module.scss';

.container {
  .emailInvitation {
    padding-bottom: 20px;
    & > div {
      display: flex;
      justify-content: space-between;
      &:first-of-type {
        margin-bottom: 15px;
        align-items: center;
        h3 {
          font-size: 18px;
          font-weight: 500;
          color: $color-ashy;
        }
        p {
          display: flex;
          align-items: center;
          font-size: 11px;
          line-height: 12px;
          color: $color-metal-grey;
          svg {
            margin-right: 5px;
            transform: translateY(-1px);
          }
        }
      }
      &:last-of-type {
        align-items: flex-start;
      }
    }
  }
  .input {
    flex: 1;
    margin-right: 5px;
  }
  .line {
    width: 100%;
    height: 1px;
    border-bottom: thin solid $color-vape;
    position: relative;
    span {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, -50%);
      background-color: $color-snow-in-april;
      padding: 0 6px;
      font-size: 11px;
      line-height: 12px;
      color: $color-metal-grey;
    }
  }
}
