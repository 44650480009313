@import 'src/assets/styles/utils/index.module.scss';

.chartsWrapper {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 22px;
    height: 100%;
  }
}

.chartsInnerWrapper {
  position: relative;
}

.cursor {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border: thin solid $color-ashy;
  opacity: 0;
  pointer-events: none;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 5px;
    height: 4px;
    background: $color-ashy;
  }

  &::before {
    top: -1px;
    right: 0;
  }

  &::after {
    bottom: -1px;
    left: 0;
  }
}

.axisX {
  margin-left: 22px;
}
