@import 'src/assets/styles/utils/index.module.scss';

.modal {
  top: 10%;
  left: 50%;
  padding: 50px 25px 25px;
  border-radius: 8px;
  background-color: $color-pretty-dark;

  &.centeredModal {
    top: 50%;
    transform: translate(-50%, -50%);

    p {
      font-size: 14px;
    }
  }

  .buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
  }

  p {
    color: $color-snow-in-april;
    line-height: 1.36;
    text-align: center;
  }

  .closeBtn {
    position: absolute;
    top: 7px;
    right: 7px;
  }
}
