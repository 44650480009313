@import 'src/assets/styles/utils/index.module.scss';

.container {
  overflow-y: scroll;

  &::after {
    position: fixed;
    content: ' ';
    bottom: 0;
    right: 0;
    width: 50%;
    z-index: 1;
    background-image: linear-gradient(transparent, #211e21);
    height: 120px;
  }

  .equality {
    padding: 5% 70px 15%;
    font-family: 'NB Akademie Std', sans-serif;
    font-weight: 400;

    h1 {
      color: $color-oh-so-green;
      font-size: 3rem;
      line-height: 3.5rem;
      margin: 25px 0;
    }
    p {
      color: $color-snow-in-april;
      font-size: 2.4rem;
      line-height: 3.6rem;
    }
    ul {
      padding-left: 15px;
      li {
        list-style-type: circle;
      }
    }
  }
}
