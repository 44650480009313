@import 'src/components/ModalCollaborationDeploy/ModalCollaborationDeploy.module.scss';

.background {
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px);
}

.modalDeployProcess {
  border-radius: $modalBorderRadius;
  padding-top: 4rem;
  padding-bottom: 3rem;

  .canaryBox {
    color: $color-snow-in-april;
    margin-bottom: 2rem;
  }

  h2.modalTitle {
    margin-bottom: 2rem;
  }

  .deployProcessProgressBar {
    width: 80%;
  }

  .errorIconContainer {
    padding: 10px;
    margin-bottom: 2rem;
    color: $color-snow-in-april;
    background-color: $color-singularity-red;
    border-radius: 5px;
  }
}
