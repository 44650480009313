@import 'src/assets/styles/utils/index.module.scss';

.container {
  --background: linear-gradient(20.24deg, #211e21 -58.28%, rgba(33, 30, 33, 0.94) 95.07%);

  display: flex;
  justify-content: space-between;
  min-width: 120px;
  padding: 10px 20px 10px 10px;
  border-radius: 4px;
  background: var(--background);
  color: $color-vape;
  font-size: 9px;

  > div:first-child {
    margin-right: 20px;
  }
}

.title {
  margin-bottom: 5px;
}

.cashDisplay {
  display: flex;
  align-items: center;
}

.savingsRow {
  display: flex;
  align-items: center;
}

.savingLine {
  width: 2px;
  height: 10px;
  margin-right: 5px;
}

.noProfit {
  width: 17px;
  margin-top: 10px;
  border-top: 1px solid $color-metal-grey;
}

.percentageDisplay {
  display: flex;
  margin-left: 20px;
}

.triangle {
  margin-right: 3px;
}

.triangleLoss {
  margin-top: 2.5px;
  transform: scale(-1);
}

.tick {
  position: absolute;
  top: 100%;
  left: 15px;
  width: 7px;
  height: 7px;
  border-radius: 2px;
  background: var(--background);
  transform: translate(-50%, -4px) rotate(-45deg);
}
