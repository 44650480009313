@import 'src/assets/styles/utils/index.module.scss';

.button {
  width: 17px;
}

.line {
  height: 2.8px;
  background: $color-ashy;
  transition: transform 0.25s $ease-out-quad, opacity 0.25s $ease-out-quad;

  +.line {
    margin-top: 2.8px;
  }
}

.opened {
  .line {
    transition: transform 0.3s $ease-in-quad;
  }

  .line:first-child {
    transform: translateY(6px) rotate(45deg);
  }

  .line:nth-child(2) {
    opacity: 0;
  }

  .line:last-child {
    transform: translateY(-5px) rotate(-45deg);
  }
}
