@import 'src/assets/styles/utils/index.module.scss';

.icon {
  color: $color-metal-grey;
  cursor: help;
}

.helperTooltip {
  max-width: 200px;
  font-size: 1.2rem;
}

.displayBlock {
  display: block;

  p {
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
