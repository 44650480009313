@import 'src/assets/styles/utils/index.module.scss';

.container {
  ul {
    display: flex;
    flex-direction: column;
    width: 100%;
    li {
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 15px;
      border-bottom: thin solid $color-ashy;
      .avatar {
        width: 32px;
        height: 32px;
        border-radius: 100%;
        background-size: cover;
      }
      .content {
        margin-left: 15px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        flex: 1;
        p:nth-child(1) {
          font-weight: 500;
          font-size: 11px;
          line-height: 13px;
          color: $color-oh-so-green;
        }
        p:nth-child(2) {
          font-weight: 400;
          font-size: 11px;
          line-height: 12px;
          margin-top: 3px;
          color: $color-snow-in-april;
          button {
            color: $color-oh-so-green;
          }
        }
      }
      & > button {
        width: 18px;
        height: 18px;
        border-radius: 100%;
        color: $color-black;
        margin-left: 9px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:nth-of-type(1) {
          background-color: $color-oh-so-green;
        }
        &:nth-of-type(2) {
          background-color: $color-singularity-red;
        }
      }
    }
  }
  .commonActions {
    width: 100%;
    display: flex;
    gap: 7px;
    padding: 7px;
    button {
      flex: 1;
    }
  }
  & > p {
    padding: 20px 20px 10px 20px;
    color: $color-metal-grey;
    text-align: center;
  }
  .loader {
    position: relative;
    width: 32px;
    height: 32px;
  }

  .spinner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    animation: rotate 2s linear infinite;

    & .path {
      stroke: $color-oh-so-green;
      animation: dash 1.5s ease-in-out infinite;
      stroke-linecap: round;
    }
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
