@import 'src/assets/styles/utils/index.module.scss';

.imageFloating {
  max-height: 100vh;
  position: absolute;
  z-index: 13;
}

.boxContent {
  position: absolute;
  background-color: $color-ace-blue;
  max-width: 95vw;
  max-height: 297px;
  left: 2.5%;
  right: 2.5%;
  bottom: 5%;
  border-radius: 2px;
  z-index: 16;
}

.simulationWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.levelSimulation > div {
  z-index: 15;
}

.levelSimulation > div:first-child {
  z-index: 16;
}

.boxText {
  margin: 6%;
  font-family: $ff-sans-landingPage;
  font-weight: $fw-regular;
  color: $color-snow-in-april;

  h3 {
    font-size: 2.2rem;
    line-height: 2.4rem;
    font-weight: $fw-medium;
    letter-spacing: 1.5px;
    margin-bottom: 4%;
  }

  p {
    font-size: 1.6rem;
    line-height: 2.8rem;
    font-weight: $fw-regular;
  }
}

.graycCommunity {
  right: 0%;
  top: 50%;
  width: 100%;
  z-index: 14;
}

.lightBarBlue {
  right: 25%;
  top: 15%;
  width: 50%;
  height: 55%;
  z-index: 15;
  filter: brightness(0.8);
}

.shadownBlue {
  right: 0%;
  top: 48%;
  width: 100%;
  filter: brightness(0.4);
}

.house {
  right: 25%;
  top: 53%;
  width: 50%;
  z-index: 14;
}

.boxTitleCommSimulation {
  right: 10%;
  top: 80%;
  width: 76%;
  z-index: 15;
}

.boxCreateCommSimulation {
  right: 12.5%;
  top: 64%;
  width: 24%;
  z-index: 15;
  display: none; //hidinf this for mobile as per designs
}

.tooltipSimulation {
  right: 30%;
  top: 43%;
  width: 35%;
  z-index: 15;
}

.mobileScreen {
  right: 8%;
  top: 37%;
  width: 80%;
  z-index: 15;
}

.addHouse {
  right: 36%;
  top: 40%;
  width: 53%;
  z-index: 15;
}

.singleLink {
  right: 20%;
  top: 55.5%;
  width: 3.2%;
  z-index: 15;
}

.lightBarBlueTwo {
  right: 30%;
  top: 20%;
  width: 25%;
  height: 70%;
  z-index: 14;
  filter: brightness(0.8);
}

.links {
  right: 0%;
  top: 54%;
  height: 26%;
  z-index: 15;
}

.chartNetEnergy {
  right: 48%;
  top: 36%;
  width: 40%;
  z-index: 16;
}

.netEnergy {
  right: 48%;
  top: 36%;
  width: 50%;
  z-index: 15;
}

.assets {
  right: 33%;
  top: 57%;
  width: 65%;
  z-index: 15;
}

.chartEnergyTrade {
  right: 44%;
  top: 73%;
  width: 52%;
  z-index: 15;
}

.grayBoxForDoc {
  top: 22% !important;
}

.grayBox {
  position: absolute;
  z-index: 1;
  background-color: $color-ashy;
  max-height: 60px;
  width: calc(100% - 20px);
  height: 100%;
  border-radius: 2px;
  top: 35%;
  left: 10px;
  right: 10px;

  .grayBoxText {
    margin: 3%;
    font-family: $ff-sans-landingPage;
    font-weight: $fw-regular;
    color: $color-snow-in-april;

    h3 {
      font-size: 1.6rem;
      line-height: 1.6rem;
      letter-spacing: 1px;
      margin-bottom: 4%;
    }

    p {
      font-size: 1.4rem;
      line-height: 1.6rem;
    }
  }
}

.simulationBoxContent {
  position: absolute;
  left: 5%;
  top: 83%;
  z-index: 20;
  display: flex;
  flex-direction: row;

  @media only screen and (min-width: 992px) and (max-width: 1600px) {
    top: 84%;
  }

  @media only screen and (min-width: 1921px) and (max-width: 2560px) {
    top: 73%;
  }
}

.triangle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  max-height: 100%;
  width: 0px;
  max-width: 100;
  margin-right: 10px;
  background-color: $color-ashy;
  border-radius: 5px;
}

.triangle_2 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  max-height: 100%;
  width: 0px;
  max-width: 100;
  margin-right: 10px;
  background-color: $color-pretty-dark;
  border-radius: 5px;
}

.boxSimulation {
  background-color: $color-ashy;
  width: 340px;
  max-width: 100%;
  border-radius: 6px;
  padding: 12px;

  .boxTitle {
    color: $color-snow-in-april;
    font-size: 2rem;
    letter-spacing: 1px;
    font-weight: $fw-medium;
  }

  .simulation {
    display: flex;
    justify-content: space-between;
    z-index: 4;

    button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      background-color: $color-pretty-dark;
      border-radius: 5px;
      height: 42px;
      max-height: 100%;
      padding: 12px;
      color: $color-metal-grey;
      opacity: 0.8;
      font-weight: $fw-medium;
      letter-spacing: 1px;
      margin-top: 12px;

      span {
        color: $color-snow-in-april;
        opacity: 0.4;
        font-size: 1.2rem;
        letter-spacing: 1px;
        font-weight: $fw-medium;
        margin-top: 3px;
      }
    }

    .button {
      display: flex;
      flex-direction: column;
    }

    .actived {
      background-color: $color-pretty-dark;
      opacity: 1;
      border: 1pt solid $color-snow-in-april;
      color: $color-snow-in-april;
    }

    .activedBtnSimulation_1 {
      background-color: $color-pretty-dark;
      opacity: 1;
      border: 1pt solid $color-singularity-red;
      color: $color-snow-in-april;
    }

    .activedBtnSimulation_2 {
      background-color: $color-pretty-dark;
      opacity: 1;
      border: 1pt solid $color-oh-so-green;
      color: $color-snow-in-april;
    }

    .activedBtnSimulation_3 {
      background-color: $color-pretty-dark;
      opacity: 1;
      border: 1pt solid $color-ace-blue;
      color: $color-snow-in-april;
    }
  }
}
