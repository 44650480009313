@import 'src/assets/styles/utils/index.module.scss';

.netEnergy {
  background-color: $color-vape;
  display: flex;
  align-items: center;
  padding: 10px;
  height: 4.4rem;
  border-radius: 3px;
  font-size: 1.4rem;
  margin-top: -15px;

  .text {
    margin-left: 5px;
  }
  .tag {
    padding: 5px;
    border-radius: 3px;
    background-color: $color-oh-so-green;
  }
  .red {
    background-color: $color-singularity-red;
    color: $color-white;
  }
}

.lastItem {
  margin-left: auto;
  text-align: right;
}

.dataSet {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 5px 0;
  .marginTop {
    margin-top: 2px;
  }
  .icon {
    background-color: #d8d8dd;
    border-radius: 4px;
    padding: 3px;
  }
}

.dataSet div {
  width: 49%;
  font-size: 1.2rem;
}
