@import 'src/assets/styles/utils/index.module.scss';

.content {
  display: flex;
  height: 100vh;
  width: 100vw;
  background: $color-pretty-dark;
  z-index: 1;
  // vertically rotate video on smaller screens
  position: relative;
  overflow: hidden;

  img {
    z-index: 12;
  }

  .bgFractuals {
    display: flex;
    flex: 1;
    height: 100vh;

    img {
      position: fixed;
      top: 50%;
      left: 50%;
      min-width: 100%;
      min-height: 100vh;
      width: auto;
      height: auto;
      z-index: -100;
      transform: translateX(-50%) translateY(-50%);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url('../../../../assets/gifs/iphone.gif');
    }
  }

  video {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100vh;
    width: auto;
    height: auto;
    z-index: -100;
    transform: translateX(-50%) translateY(-50%);

    @media only screen and (max-width: 600px) {
      position: absolute;
      -moz-transform: translateX(-50%) translateY(-50%) rotate(90deg);
      -webkit-transform: translateX(-50%) translateY(-50%) rotate(90deg);
      -o-transform: translateX(-50%) translateY(-50%) rotate(90deg);
      -ms-transform: translateX(-50%) translateY(-50%) rotate(90deg);
      transform: translateX(-50%) translateY(-50%) rotate(90deg);
      width: 100vh;
      height: 100vw;
    }
  }

  img {
    width: 200px;
    height: 200px;
    position: absolute;
    right: 30px;
    top: 120px;

    @media only screen and (max-width: 600px) {
      position: absolute;
      width: 120px;
      height: 120px;
      right: 18px;
      top: 110px;
    }
  }
}

.scrollButtonContainer {
  position: absolute;
  bottom: 80px;
  z-index: 100;
  width: calc(100% - 60px);
  color: $color-white;
  margin: 0 30px;

  @media only screen and (max-width: 600px) {
    bottom: 20%;
  }

  .quoteText {
    position: absolute;
    right: 0;
    text-align: right;
    font-family: $ff-sans;

    @media only screen and (max-width: 600px) {
      left: 0;
      text-align: left;
    }

    .text {
      font-size: 15px;
      line-height: 21px;
      z-index: 8;
    }

    .bold {
      font-weight: bold;
      font-size: 17px;
      line-height: 26px;
    }
  }
}

.bottomToTopFade {
  position: relative;
  -webkit-animation: slideInFade 6s forwards;
  -moz-animation: slideInFade 6s forwards;
  animation: slideInFade 6s forwards;
}

.logoBadge {
  position: fixed;
  height: 100px;
  width: 200px;
  right: 30px;
  top: 15%;

  img {
    position: absolute;
    top: 0;
    left: 0;
  }
  img:last-child {
    -webkit-animation: spin 6s linear infinite;
    -moz-animation: spin 6s linear infinite;
    animation: spin 6s linear infinite;
  }

  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}

.headingText {
  color: $color-white;
  font-family: $ff-sans-secondary;
  font-style: normal;
  font-weight: bold;
  font-size: 8.75vh;
  line-height: 108%;
  letter-spacing: -0.02em;
  margin-bottom: 2rem;
  max-width: 100%;
  cursor: pointer;

  @media screen and (max-width: 1200px) {
    zoom: 0.8;
  }

  @media only screen and (min-width: 300px) and (max-width: 900px) {
    font-size: 3.5rem;
    line-height: 4rem;
  }
}

.customColorTextOne {
  color: $color-metal-grey;
}

.customColorTextTwo {
  color: $color-ashy;
}

.blackText {
  color: $color-pretty-dark;
  font-family: $ff-sans-secondary;
  font-style: normal;
  font-weight: bold;
  font-size: 8.75vh;
  line-height: 108%;
  letter-spacing: -0.02em;
  margin-bottom: 2rem;
  max-width: 100%;
  cursor: pointer;

  @media only screen and (min-width: 300px) and (max-width: 900px) {
    font-size: 3.5rem;
    line-height: 4rem;
  }
}

.imageFloating {
  max-height: 100vh;
  position: absolute;
}

.textContainer {
  top: $header-height + 45px;
  position: absolute;
  left: 45px;
  z-index: 11;
  -webkit-animation: slideInFade 6s forwards;
  -moz-animation: slideInFade 6s forwards;
  animation: slideInFade 6s forwards;

  @media only screen and (min-width: 300px) and (max-width: 900px) {
    max-width: 60vw;
    left: 30px;
  }
}

// transition - slideInFade
@keyframes slideInFade {
  from {
    transform: translateY(100%);
    opacity: 0;
  }

  to {
    opacity: 1;
    transform: translateY(0%);
  }
}
