@import 'src/assets/styles/utils/index.module.scss';

.container {
  position: absolute;
  top: 118px;
  right: 65px;
  left: unset;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.containerEmbed {
  top: 30px;
}
