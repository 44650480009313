.container {
  height: 72px;
  width: 6px;
  border-radius: 20px;
  background-color: #4b464b;
  position: absolute;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;
  right: 30px;
  z-index: 4;
}

.activeBar {
  position: absolute;
  width: 6px;
  background-color: #1641f7;
}
