@keyframes placeHolderShimmer {
  0% {
    transform: translateX(-150%);
  }

  100% {
    transform: translateX(150%);
  }
}

:global {
  /*
    Helpers
  */
  .click-area {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 45px;
      min-width: 100%;
      height: 45px;
      min-height: 100%;
      transform: translate(-50%, -50%);
    }
  }

  .loading-shimmer {
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 300%;
      height: 100%;
      background: linear-gradient(to right, transparent 8%, #fff 38%, transparent 54%);
      opacity: 0.5;
      pointer-events: none;
      animation-duration: 2s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      animation-fill-mode: forwards;

      & :local {
        animation-name: placeHolderShimmer;
      }
    }
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }

  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  /*
    Gradients
  */
  .gradient-node-card {
    background: linear-gradient(110.47deg, #eaeaea 7.89%, #d3d3db 59.23%, #b6b6c4 92.81%);
  }

  .gradient-linear-stroke {
    background: linear-gradient(155.23deg, #eaeaea 42.55%, #d3d3db 97.69%, #b6b6c4 133.75%);
  }

  .gradient-dark-modal {
    background: linear-gradient(323.52deg, #000 5.75%, #808080 112.39%),
      linear-gradient(131.78deg, #fff 1.55%, rgba(255, 255, 255, 0) 47.58%);
  }

  .gradient-dark-linear {
    background: linear-gradient(212.58deg, #666 0%, #000 84.78%);
  }

  .gradient-neumorphs-soft-grey {
    background: linear-gradient(110.25deg, #eee 3.13%, #e2e2eb 105.3%);
  }

  .gradient-dark {
    // Gradient direct from designs:
    // background: linear-gradient(17.86deg, #211e21 -60.33%, rgba(33, 30, 33, 0.94) 98.42%);
    // Almost the same gradient but without opacity
    background: linear-gradient(17.86deg, #211e21 -60.33%, #2d2b2c 98.42%);
  }

  /*
  Custom Scrollbar
  */
  .ps__thumb-x,
  .ps__thumb-y {
    width: 6px !important;
    background-color: $color-metal-grey !important;
  }

  .ps__rail-x,
  .ps__rail-y {
    width: unset !important;
  }
}
