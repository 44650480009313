@import 'src/assets/styles/utils/index.module.scss';

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 16px;
}

.locationSearch {
  max-height: 170px;
  margin-bottom: 14px;
}

.list {
  height: 100%;
}
