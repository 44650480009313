@import 'src/assets/styles/utils/index.module.scss';

.container {
  overflow-y: scroll;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  position: relative;
  padding: 0 70px;

  -webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 3s; /* Firefox < 16 */
    -ms-animation: fadein 3s; /* Internet Explorer */
    -o-animation: fadein 3s; /* Opera < 12.1 */
    animation: fadein 3s;

    @keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
    }
  
    /* Firefox < 16 */
    @-moz-keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }
  
    /* Safari, Chrome and Opera > 12.1 */
    @-webkit-keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }
  
    /* Internet Explorer */
    @-ms-keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }
  
    /* Opera < 12.1 */
    @-o-keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }

    transition: filter 0.2s;
    &:hover {
      color: $color-london-fog;
    }

  @media only screen and (min-width: 300px) and (max-width: 900px) {
    padding: 30px;
    margin-top: 0;
  }
}

.container::after {
  position: fixed;
  content: " ";
  bottom: 0;
  right: 0;
  width: 50%;
  z-index: 1;
  background-image: linear-gradient(transparent, #211e21);
  height:120px;

  @media only screen and (min-width: 300px) and (max-width: 900px) {
    width: 100%;
  }
}

.box {
  margin-top: 16px;
  border: 1px solid $color-kinda-dirty;
  align-items: center;
  max-width: 100%;
  width: 100%;

  &:first-child {
    margin-top: 5%;

    @media only screen and (min-width: 300px) and (max-width: 900px) {
      margin-top: 10px;
    }
  }
  &:last-child {
    margin-bottom: 15%;
  }
}

.boxText {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 30px 30px 16px 30px;

  h2 {
    color: $color-snow-in-april;
    font-size: 3.2rem;
    line-height: 3.8rem;
    font-family: $ff-sans-landingPage;
    font-weight: $fw-regular;

    @media only screen and (min-width: 300px) and (max-width: 900px) {
      font-size: 2.2rem;
      line-height: 2.8rem;
    } 
  }

  .text {
    color: $color-london-fog;
    font-size: 2.4rem;
    font-family: $ff-sans-landingPage;
    font-weight: $fw-regular;
    margin-top: 22px;

    @media only screen and (min-width: 300px) and (max-width: 900px) {
      font-size: 1.4rem;
      margin-top: 16px;
    } 
  }

  .text_uppercase {
    color: $color-london-fog;
    font-size: 1.6rem;
    font-family: $ff-sans-landingPage;
    font-weight: $fw-regular;
    margin-top: 22px;
    text-transform: uppercase;
    letter-spacing: 2px;
    
    @media only screen and (min-width: 300px) and (max-width: 900px) {
      font-size: 1.4rem;
      margin-top: 16px;
    } 
  }

  @media only screen and (min-width: 300px) and (max-width: 900px) {
    font-size: 2.2rem;
    padding: 16px 16px 2px 16px;
  } 
}

.buttonPosition {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
}

.buttonApply {
  padding: 0 16px;
  height: 38px;
  background: $color-oh-so-green;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;

  span {
    color: $color-pretty-dark;
    font-size: 1.8rem;
    font-family: $ff-sans-landingPage;
    font-weight: $fw-medium;
    text-transform: uppercase;
    letter-spacing: 1px;
    transform: translateY(12%);
  }

  transition: filter 0.2s;
    &:hover {
    filter: brightness(0.8);
  }
}

.boxNoOpportunitie {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  align-items: flex-start;
  justify-content: center;

  p {
    color: $color-oh-so-green;
    font-size: 2.4rem;
    line-height: 3.6rem;
    font-family: $ff-sans-landingPage;
    font-weight: $fw-regular;
    margin-bottom: 24px;
  }

  @media only screen and (min-width: 300px) and (max-width: 900px) {
    justify-content: flex-start
  } 
}