.singleNotificationContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  .singleCharacterContainer {
    font-size: 28px;
    padding-top: 10px;
  }
  span {
    display: flex;
    font-size: 12px;
    font-weight: 600;
  }
}

.triangleWrapper {
  // create a triangle with css in the ::before element
  &::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid black;
  }
}
