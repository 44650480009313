@import 'src/assets/styles/utils/index.module.scss';

.container {
  width: 100%;
  height: auto;
  padding-bottom: 15px;
  border-radius: 5px;
  background-color: $color-white;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 45px;
    padding: 0 15px;

    .headerContent {
      display: flex;
      align-items: center;
      width: 100%;
    }

    h3 {
      margin-right: 10px;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.7rem;
    }
  }
}

.mla {
  margin-left: auto;
}

.content,
.footer {
  padding-right: 15px;
  padding-left: 15px;
}

.footer {
  margin-top: 15px;
}
