@import 'src/assets/styles/utils/index.module.scss';

.progressWrapper {
  width: 100%;
  background-color: $color-kinda-dirty;
  border-radius: 20px;
}

.progressWrapperBigger {
  height: 40px;
}

.bar {
  height: 8px;
  background-color: $color-oh-so-green;
  transition: width 1s ease;
  display: flex;
  overflow: visible;
  justify-content: flex-end;
  margin-left: 2px;
  margin-right: 2px;
}

.progressPointWrapper {
  position: relative;
  display: flex;
  margin-top: -8px;

  .progressPoint {
    width: 4px;
    height: 16px;
    background-color: $color-black;
  }

  .progressCountWrapper {
    height: 16px;
    position: absolute;
    margin-top: -20px;
    font-size: 14px;
    margin-left: -10px;
  }
}
