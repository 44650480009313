@import 'src/assets/styles/utils/index.module.scss';

.content {
  display: flex;
  flex: 1;
  position: relative;
  flex-direction: column;
  padding: 0 35px 80px 35px;

  @media only screen and (min-width: 300px) and (max-width: 900px) {
    padding: 0;
  }

  .content::after {
    position: fixed;
    content: ' ';
    bottom: 0;
    right: 0;
    width: 50%;
    z-index: 1;
    background-image: linear-gradient(transparent, $color-ace-blue);
    height: 120px;

    @media only screen and (min-width: 300px) and (max-width: 900px) {
      width: 100%;
    }
  }
}

.buttonBack {
  z-index: 1;
  margin-bottom: 10%;
  padding: 0;
  background: none;
  font-weight: $fw-regular;
  font-size: 16px;
  font-family: 'NB Akademie Std';
  line-height: 17px;
  letter-spacing: 1px;
  display: flex;
  justify-content: flex-start;
  margin-top: 5vh;

  @media only screen and (min-width: 300px) and (max-width: 900px) {
    // margin-left: 30px;
  }

  svg {
    width: 27px !important;
    height: 27px !important;
  }

  transition: filter 0.2s;
  &:hover {
    filter: brightness(0.9);
  }
}

.headerNews {
  h1 {
    color: $color-oh-so-green;
    font-size: 3rem;
    line-height: 3.6rem;
    font-family: 'NB Akademie Std';
    font-weight: $fw-medium;
  }
}

.textNews {
  color: $color-snow-in-april;
  font-size: 1.6rem;
  line-height: 2.6rem;
  overflow: scroll;
  margin-top: 3%;
  max-height: 65vh;
  height: 100%;

  @media only screen and (min-width: 300px) and (max-width: 900px) {
    max-height: 30vh;
  }

  a,
  b {
    color: $color-oh-so-green;
    cursor: pointer;
    border-bottom: 2px solid $color-oh-so-green;
    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
  }

  img {
    height: auto !important;
    margin-top: 15px;
  }
}
