@import 'src/assets/styles/utils/index.module.scss';

.button {
  position: relative;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.15);
  font-weight: $fw-bold;
  font-size: 1.6rem;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.icon {
  width: 45%;
  height: 45%;
}

.badge {
  position: absolute;
  bottom: 0;
  left: -2px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33.33%;
  height: 33.33%;
  border-radius: 50%;
  background: currentColor;

  &::before,
  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 5px;
    height: 1px;
    background: $color-white;
    transform: translate(-50%, -50%);
  }

  &::after {
    width: 1px;
    height: 5px;
  }
}

.minus {
  &::before {
    content: '';
  }
}

.plus {
  &::before,
  &::after {
    content: '';
  }
}

.themeBorder {
  border: thin solid currentColor;
}

.themeGradientLight {
  background: linear-gradient(156.01deg, #fefdfd 21.34%, #ececec 134.27%);
}

.color-primary {
  color: $color-pretty-dark;
}

.color-secondary {
  color: $color-ace-blue;
}

.color-tertiary {
  color: $color-oh-so-green;
}

.color-quaternary {
  color: $color-singularity-red;
}

.button:disabled {
  border-color: transparent;
  color: $color-metal-grey;
}

.mirrorContent {
  .content {
    transform: scaleX(-1);
  }
}
