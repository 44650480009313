@import 'src/assets/styles/utils/index.module.scss';

.mapHero {
  position: fixed;
  top: 50%;
  left: 140px;
  z-index: 1;
  width: 100%;
  max-width: 420px;
  height: 270px;
  transform: translateY(-50%);
}

.sideArrow {
  position: absolute;
}

.paginationCon {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 14px 15px;
  background-color: $color-modal-bg;
}

.pagination {
  @keyframes pulse {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  button {
    color: $color-snow-in-april;
  }

  .stepsContainer {
    display: flex;
    align-items: center;
  }

  .stepButton {
    width: 30px;
    height: 29px;
    margin-right: 5px;
    border: 1px solid $color-ashy;
    background: $color-modal-bg;
    font-size: 13px;

    &:last-child {
      margin-right: 0;
    }
  }

  .stepButton:hover {
    background: #1c1a1c;
  }

  .activeStep {
    background: $color-ashy;
    color: $color-snow-in-april;
  }

  .nextStepButton {
    height: 29px;
    background: $color-ashy;
    color: $color-snow-in-april;
    font-size: 12px;

    svg {
      position: relative;
      // top: 1px;
      right: -8px;
    }
  }

  .nextStepButton:hover {
    background: #1c1a1c;
  }

  .nextIcon {
    transform: rotate(180deg);
  }

  .prevStepButton {
    height: 29px;
    border: 1.5px solid $color-kinda-dirty;
    background: $color-modal-bg;
    color: $color-snow-in-april;
    font-size: 14px;

    svg {
      position: relative;
      // top: 1px;
      right: -2;
    }
  }

  .prevStepButton:hover {
    background: #1c1a1c !important;
  }
}

.WalkMeThroughModel {
  position: absolute;
  z-index: 100000000;
  justify-content: center;
  width: 387px;
  height: 144px;
  padding: 0;
  border-radius: 4px;
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 6px;
  // background: linear-gradient(10.13deg, #211e21 0.29%, rgba(33, 30, 33, 0.94) 99.02%);

  background: linear-gradient(10.13deg, #242124 0.29%, rgba(33, 30, 33, 0.94) 99.02%);
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  backdrop-filter: blur(20.3526px);
  // backdrop-filter: blur(20.3526px);
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $z-modal-auth - 1;
  width: 100%;
  height: 100%;
  background-color: rgba(61, 61, 61, 0.4);
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px);
  backdrop-filter: grayscale(0.5) opacity(0.8) /* ...and on and on... */;
}

.welcomeModal {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100000000;
  display: flex;
  justify-content: center;
  padding: 30px;
  border-radius: 4px;
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 6px;
  background: linear-gradient(10.13deg, #211e21 0.29%, rgba(33, 30, 33, 0.94) 99.02%);
  transform: translate(-50%, -50%);
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  backdrop-filter: blur(20.3526px);

  .wmLeft {
    display: block;
    width: 387px;
    height: 387px;
    background: $color-kinda-dirty;
  }

  .wmGreenCon {
    display: block;
    width: 100%;
    padding: 20px;
    background: $color-ashy;
  }

  .wmRight {
    display: flex;
    flex-direction: column;
    width: 387px;
    margin-top: 15px;
    padding: 0 25px;
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}

.descriptionHighlight {
  color: $color-soft-not-so-green;
}

.WalkMeThroughModelText {
  width: 80%;
  margin: 15px;
  line-height: 16px;
}

.WalkMeThroughModelTitle {
  color: $color-snow-in-april;
  font-weight: bold;
  font-size: 16px;
}

.WalkMeThroughModelDescription {
  margin-top: 11px;
  color: #efeff1;
  color: $color-vape;
  font-weight: normal;
  font-size: 13px;
}

.wmTitle {
  margin: 40px 0;
  color: $color-snow-in-april;
  text-align: left;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
}

.wmDescription {
  color: $color-snow-in-april;
  font-size: 16px;
  line-height: 19px;
}

.goNext {
  position: absolute;
  bottom: 30px;
  width: 44%;
  height: 31px;
  font-size: 13px;
}

.wmControl {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: space-between;
  width: 100%;
  color: $color-metal-grey;
}

.wmNextOrPrev {
  display: flex;
  flex-direction: row;
  margin-right: 12px;
}

.wmNumOf {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 21px;
  padding: 0 11px;
  border-radius: 12px;
  background-color: $color-ashy;
  color: $color-snow-in-april;
}

.wmNextPrevBtn {
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: center;
  width: 22px;
  height: 22px;
  margin-left: 3px;
  padding-right: 2px;
  border-radius: 100%;
  background-color: $color-ashy;
  color: #fbf9f9;
}

.closeBtn {
  position: absolute;
  top: 12px;
  right: 12px;
}

.rotated180 {
  transform: rotate(180deg); /* Equal to rotateZ(45deg) */
}

.mapHeroTitle {
  margin-bottom: 36px;
  font-weight: $fw-black;
  font-size: 5.5rem;
  font-family: $ff-sans-secondary;
  line-height: 6.6rem;
}

.mapHeroTitleNumber {
  color: $color-oh-so-green;
}

.mapHeroButtonsWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
}

.mapHeroButton {
  width: 190px;
}
