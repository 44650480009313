@import 'src/assets/styles/utils/index.module.scss';

.content {
  display: flex;
  flex: 1;
  background-size: 75%;
  background-repeat: no-repeat;
  background-position: bottom right;
  position: relative;
  flex-direction: column;
  overflow-y: scroll;
}

.content::after {
  position: fixed;
  content: ' ';
  bottom: 0;
  right: 0;
  width: 50%;
  z-index: 1;
  background-image: linear-gradient(transparent, #211e21);
  height: 120px;

  @media only screen and (min-width: 300px) and (max-width: 900px) {
    width: 100%;
  }
}

.nonPadding {
  display: flex;
  padding: 0 20px;
}

.backSec {
  z-index: 4;
}
.backSection {
  position: fixed;
  bottom: 1%;
  z-index: 4;
  margin: 0;
  align-self: flex-start;
  font-family: 'NB Akademie Std', sans-serif;
  font-weight: 500;
  letter-spacing: 2px;
  font-size: 1.5rem;
  text-transform: uppercase;
  background: none;
  margin-left: 12px;

  @media only screen and (min-width: 300px) and (max-width: 900px) {
    margin-left: 0;
  }

  svg {
    width: 27px !important;
    height: 27px !important;
  }

  transition: filter 0.2s;
  &:hover {
    filter: brightness(0.7);
  }
}

.bgColor {
  background-color: #1d191b !important;
}

.bg {
  display: flex;
  width: 100%;
  height: 100%;
  cursor: pointer;
  flex: 1;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('../../assets/images/LandingPage/cofunders.png');
  -webkit-animation: fadein 5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 5s; /* Firefox < 16 */
  -ms-animation: fadein 5s; /* Internet Explorer */
  -o-animation: fadein 5s; /* Opera < 12.1 */
  animation: fadein 5s;

  @media only screen and (max-width: 600px) {
    display: none;
  }

  @media only screen and (min-width: 700px) and (max-width: 1300px) {
    height: 55%;
    flex: none;
  }
}

.mobileBg {
  display: none;
  position: absolute;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 80%;
  cursor: pointer;
  flex: 1;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('../../assets/images/LandingPage/cofunders.png');
  -webkit-animation: fadein 5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 5s; /* Firefox < 16 */
  -ms-animation: fadein 5s; /* Internet Explorer */
  -o-animation: fadein 5s; /* Opera < 12.1 */
  animation: fadein 5s;

  @media only screen and (max-width: 600px) {
    display: block;
  }
}

.boxInfo {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 20px 70px 10% 70px;

  @media only screen and (max-width: 600px) {
    padding: 25px;
    justify-content: flex-start;
  }
}

.wrapperPeople {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 0;

  @media only screen and (max-width: 600px) {
    position: fixed;
    bottom: 5%;
    width: 100%;
    justify-content: space-between;
  }
}

.buttonIntro {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  span {
    color: $color-snow-in-april;
    font-family: $ff-sans-landingPage;
    font-weight: $fw-medium;
    letter-spacing: 2px;
    font-size: 1.5rem;
    text-transform: uppercase;
    margin-top: -10px;
    margin-right: 38px;
    transform: translateY(12%);
  }

  &:hover {
    filter: brightness(0.7);
  }
}

.iconIntro {
  color: $color-snow-in-april;
  align-self: flex-start;
  cursor: ponter;
  margin-right: 8px;
}

.info {
  display: flex;
  flex-direction: column;
  z-index: 2;

  p {
    color: $color-oh-so-green;
    font-size: 2.4rem;
    line-height: 3.6rem;
    font-family: $ff-sans-landingPage;
    font-weight: $fw-regular;
    -webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 3s; /* Firefox < 16 */
    -ms-animation: fadein 3s; /* Internet Explorer */
    -o-animation: fadein 3s; /* Opera < 12.1 */
    animation: fadein 3s;
    margin-top: 12px;

    @media only screen and (max-width: 600px) {
      margin-top: 0;
      z-index: 2;
    }
  }
}

.boxPeople {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-bottom: 30px;
}

.photo {
  position: relative;
  display: flex;
  width: 33.3%;
  height: 100vh;
  max-height: 262px;
  border: solid 4px transparent;
  clip-path: polygon(
    calc(0% + 4px) calc(0% + 4px),
    /* top left */ calc(100% - 4px) calc(0% + 4px),
    /* top right */ calc(100% - 4px) calc(100% - 4px),
    /* bottom right */ calc(0% + 4px) calc(100% - 4px) /* bottom left */
  );
  transition: clip-path 0.6s linear;
  -webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 3s; /* Firefox < 16 */
  -ms-animation: fadein 3s; /* Internet Explorer */
  -o-animation: fadein 3s; /* Opera < 12.1 */
  animation: fadein 3s;

  @media only screen and (max-width: 600px) {
    width: 50%;
  }

  span {
    position: absolute;
    align-self: flex-start;
    left: 20px;
    top: 20px;
    font-size: 2.4rem;
    line-height: 2.8rem;
    max-width: 100px;
    font-weight: $fw-medium;
    font-family: $ff-sans-landingPage;
    color: $color-london-fog;
    cursor: pointer;

    @media only screen and (max-width: 600px) {
      left: 8px;
      top: 8px;
      word-wrap: break-word;
      max-width: unset;
    }
  }

  &:hover {
    border: solid 4px $color-oh-so-green;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);

    span {
      color: $color-oh-so-green;
    }
  }

  @media only screen and (min-width: 1400px) {
    max-height: 362px;
  }
}

.boxPhoto {
  display: flex;
  width: 100%;
  cursor: pointer;
  background-position: center;
  background-size: cover;
  background-image: url('../../assets/images/LandingPage/jutta-member.png');
  opacity: 0.3 !important;
  filter: grayscale(1) brightness(0.4);
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &:hover {
    opacity: 1 !important;
    filter: grayscale(1) brightness(1);
  }
}

.teamGlobe {
  position: absolute;
  left: 20px;
  top: -60px;
  width: 100%;
  max-width: calc(100vh - 83px);
  height: calc(100vh - 83px);
  transform: translateX(-25%);
  z-index: 4;

  @media only screen and (min-width: 300px) and (max-width: 900px) {
    left: 0;
    width: 100%;
    transform: translateX(0);
  }
}

.teamGlobeInfo {
  position: relative;
  width: 100%;
  height: 87vh;
  padding: 32px 53px 0;
  pointer-events: none;
}

.teamGlobeTitle {
  position: relative;
  width: 350px;
  margin-bottom: 49px;
  margin-left: calc(100% - 350px);
  font-weight: 400;
  font-size: 6rem;
  font-family: 'GT Cinetype';
  color: $color-snow-in-april;
  line-height: 71.7px;
  text-align: right;
  z-index: 5;
}

.teamGlobeInfoDetail {
  position: relative;
}

.teamGlobeInfoDetailItem {
  margin-bottom: 45px;
}

.teamGlobeNumber {
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 3rem;
  font-family: 'Roboto';
  color: $color-snow-in-april;
  line-height: 35.16px;
  text-align: right;
}

.teamGlobeNumberLabel {
  font-weight: 400;
  font-size: 1.2rem;
  font-family: 'Roboto';
  color: $color-snow-in-april;
  line-height: 14.06px;
  text-align: right;
}

.teamGlobeContainer {
  position: absolute;
  min-height: 112px;
  margin-top: 0px;
  bottom: 0px;
  z-index: 4;
}

.teamGlobeHeadquarters {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.teamGlobeHeadquarterItem {
  position: relative;
  max-width: 150px;
  margin-right: 10px;
  margin-bottom: 32px;
  color: $color-metal-grey;
  font-weight: 400;
  font-size: 12px;
  font-family: 'Roboto';
  line-height: 20px;
  word-wrap: break-word;
}

.teamGlobeHeadquarterItemTitle {
  font-weight: 900;
}

.header {
  position: sticky;
  top: 0;
  background-color: #1d191b;
  z-index: 4;
  padding: 5% 0 0.5% 0;
}

.bioBackBtn {
  position: relative;
  z-index: 1;
  margin-bottom: 45px;
  padding: 0;
  background: none;
  font-weight: 500;
  font-size: 1.5;
  font-family: 'NB Akademie Std';
  text-transform: uppercase;
  line-height: 17px;
  letter-spacing: 1px;
  margin-left: 70px;

  @media only screen and (min-width: 300px) and (max-width: 900px) {
    margin-left: 30px;
  }

  svg {
    width: 27px !important;
    height: 27px !important;
  }

  transition: filter 0.2s;
  &:hover {
    filter: brightness(0.7);
  }
}

.bioAvatarContainer {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  margin-bottom: 36px;
  padding: 0 70px;

  @media only screen and (min-width: 300px) and (max-width: 900px) {
    padding: 0 30px;
  }
}

.bioAvatar {
  width: 85px;
  height: 85px;
  margin-right: 32px;

  img {
    width: 85px;
    height: 85px;
    filter: grayscale(1) brightness(1.1);
  }
}

.bioName {
  margin-bottom: 4px;
  font-weight: $fw-regular;
  font-size: 1.3rem;
  font-family: $ff-sans-landingPage;
  line-height: 14px;
  color: $color-snow-in-april;
}

.bioArea {
  margin-left: 5px;
  color: $color-london-fog;
  font-weight: 500;
  font-size: 11px;
  font-family: $ff-sans-landingPage;
  line-height: 12px;
}

.bioDescriptionContainer {
  position: relative;
  z-index: 1;
  height: auto;
  font-weight: $fw-regular;
  font-family: $ff-sans-landingPage;
  padding: 0 70px;

  @media only screen and (min-width: 300px) and (max-width: 900px) {
    padding: 0 30px;
  }
}

.bioDescriptionContainer::after {
  position: fixed;
  content: ' ';
  bottom: 0;
  right: 0;
  width: 50%;
  z-index: 1;
  background-image: linear-gradient(transparent, #211e21);

  height: 120px;

  @media only screen and (min-width: 300px) and (max-width: 900px) {
    width: 100%;
  }
}

.bioDescriptionName {
  margin-bottom: 31px;
  color: $color-oh-so-green;
  font-size: 30px;
  line-height: 32.25px;
}

.bioDescription {
  color: $color-snow-in-april;
  font-size: 2.4rem;
  line-height: 3.6rem;
  margin-bottom: 150px;
  overflow: scroll;
}

.info {
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Firefox < 16 */
  @-moz-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Internet Explorer */
  @-ms-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Opera < 12.1 */
  @-o-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

.wrapperPeopleMobile {
  padding: 20px;

  .heading {
    font-family: $ff-sans-secondary;
    font-size: 22px;
    line-height: 30px;
    letter-spacing: -0.02em;
    color: $color-snow-in-april;
  }

  .descriptionContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 75%;
    margin: 20px 0;

    .count {
      font-family: $ff-sans;
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
      color: $color-snow-in-april;
      margin-right: 10px;
    }

    .label {
      font-family: $ff-sans;
      font-size: 14px;
      line-height: 14px;
      color: $color-snow-in-april;
    }
  }

  .boxPeople {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 0 0 30px 20px;

    .tile {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 20px;
    }

    .photoContainerBorder {
      border: 1px solid $color-oh-so-green;
      width: 42px;
      height: 42px;
      border-radius: 50%;
      margin-right: 15px;
    }

    .photoContainer {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      cursor: pointer;
      background-position: center;
      background-size: cover;
      filter: grayscale(1) brightness(0.7);
    }

    .textContainer {
      color: $color-snow-in-april;

      .name {
        font-family: $ff-sans-landingPage;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        margin-bottom: 0.5em;
      }

      .bioLink {
        color: $color-oh-so-green;
        font-family: $ff-sans-landingPage;
        font-weight: 500;
        font-size: 14;
        line-height: 14px;
      }
    }
  }

  .teamHeadquarters {
    border-top: 1px solid $color-ashy;
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
  }
}

.quality {
  padding: 5% 70px 15%;
  font-family: 'NB Akademie Std', sans-serif;
  font-weight: 400;

  h1 {
    color: $color-oh-so-green;
    font-size: 3rem;
    line-height: 3.5rem;
    margin: 25px 0;
  }
  p {
    color: $color-snow-in-april;
    font-size: 2.4rem;
    line-height: 3.6rem;
  }
  ul {
    padding-left: 15px;
    li {
      list-style-type: circle;
    }
  }
}
