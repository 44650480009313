@import 'src/assets/styles/utils/index.module.scss';

.container {
  margin-bottom: 15px;
}

.loadingWrapper {
  min-height: 40px;
}

.differenceInfoTag {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 16px 14px;
  border-radius: 4px;
  color: $color-snow-in-april;
  background-color: $color-ashy;
  font-size: 1.1rem;
  font-weight: bold;

  p {
    margin: 0 4px;
  }

  span {
    font-weight: $fw-bold;
  }

  .icon {
    margin-right: 10px;
    font-size: 18px;
  }

  .earning {
    // color: $color-ace-blue;
    color: $color-oh-so-green;
  }

  .neutral {
    // color: $color-london-fog;
    color: $color-soft-singularity-red;
  }

  .saving {
    color: $color-oh-so-green;
  }

  .losses {
    color: $color-soft-singularity-red;
  }

  .mb {
    margin-bottom: 10px;
  }
}
