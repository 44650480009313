@import 'src/assets/styles/utils/index.module.scss';

.input {
  width: 100%;
  margin-bottom: 3rem;
}

.rightDirection {
  flex-direction: row-reverse;
}

.buttonRegister {
  width: 100%;
}
