@import 'src/assets/styles/utils/index.module.scss';

$padding: 10px;

.modal {
  position: relative;
  top: unset;
  left: unset;
  border-radius: 8px;
}

.closeBtn {
  position: absolute;
  top: 8px;
  right: 8px;
}

.container {
  margin-top: 1rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.paddingLR {
  padding: 0 $padding 0 $padding;
}

header.communityAssetsHeader {
  display: flex;
  align-items: center;
  padding: $padding;

  h3 {
    flex: 1;
    font-weight: bold;
    font-size: 1.5rem;
  }
}

.boxInfo {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 0 0 16px;

  h2 {
    color: $color-snow-in-april;
    font-size: 1.7rem;
    line-height: 2.1rem;
    font-weight: $fw-bold;
    padding-left: 10px;
  }

  p {
    margin-top: 8px;
    color: $color-metal-grey;
    padding-left: 10px;
    font-size: 1.3rem;
    line-height: 1.6rem;
    font-weight: $fw-regular ;
  }
}

.image {
  margin: 8px 0 8px 0 ;
}

.iconCanary {
  min-width: 25px;
  min-height: 25px;
  margin-right: 10px;
  box-shadow: 3px 0px 17px rgba(22, 65, 247, 0.8);
  border-radius: 4px;
}

.takeMeToTheFuture {
  margin: 12px 16px 16px 16px;
}

.askMeLater {
  display: flex;
  align-self: center;
  color: $color-snow-in-april;
  font-size: 1.3rem;
  cursor: pointer;
}