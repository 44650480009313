@import 'src/assets/styles/utils/index.module.scss';

.imageFloating {
  max-height: 100vh;
  position: absolute;
  z-index: 13;
}

.ecosystemWrapper {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  z-index: 22;
  display: flex;
  align-items: center;
}

.ecosystemContainer {
  position: absolute;
  z-index: 25;
  bottom: 0;
  width: 100%;
  overflow: scroll;
  padding: 40px 20px;

  @media only screen and (min-width: 300px) and (max-width: 900px) {
    top: 23%;
    height: calc(100vh - 25%);
  }
}

.ecosystemContainerMobile {
  position: absolute;
  z-index: 25;
  bottom: 0;
  width: 100%;
  overflow: scroll;
  padding: 0;
  top: 60px;
  @media only screen and (min-width: 300px) and (max-width: 900px) {
    top: 60px;
    height: auto;
  }
}
