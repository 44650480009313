@import 'src/assets/styles/utils/index.module.scss';

.content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 30px 0;
  width: 100%;

  @media only screen and (min-width: 300px) and (max-width: 900px) {
    flex-wrap: wrap;
  } 

  .filterBtn {
    margin-right: 12px;
    border: 1px solid $color-oh-so-green;
    border-radius: 0;
    background: unset;
    color: $color-oh-so-green;
    padding: 0 12px;

    @media only screen and (min-width: 300px) and (max-width: 900px) {
      margin-bottom: 15px;
    } 
  }

  .iconBtn {
    padding: 0;
    width: 35px;
    margin-right: 12px;
    border: 1px solid $color-oh-so-green;
    border-radius: 0;
    background: unset;
    color: $color-oh-so-green;

    @media only screen and (min-width: 300px) and (max-width: 900px) {
      margin-bottom: 12px;
    }
  }

  .filterSeleted {
    background: $color-oh-so-green;
    color: $color-ace-blue;
  }

  .filterIcon {
    color: $color-oh-so-green;
  }

  .closeIcon {
    margin-left: 15px;
  }

  .optionsContainer {
    position: absolute;
    top: 50px;
    z-index: 2;
    overflow: scroll;
    min-width: 250px;
    max-height: 300px;
    padding: 8px;
    border: 1px solid $color-oh-so-green;
    background: $color-ace-blue;

    .item {
      display: flex;
      align-items: center;
      width: 100%;
      margin: 10px;
      color: $color-white;
      font-size: 1.6rem;
    }

    input {
      width: 16px;
      height: 16px;
      margin-right: 20px;
      -webkit-appearance: auto;
      appearance: auto;
    }

    input[type='radio']::after {
      content: '';
      position: relative;
      top: 0;
      left: 0;
      display: inline-block;
      width: 16px;
      height: 16px;
      border: 1px solid $color-oh-so-green;
      border-radius: 15px;
      background-color: $color-ace-blue;
      visibility: visible;
    }

    input[type='radio']:checked::after {
      content: '';
      position: relative;
      top: 0;
      left: 0;
      display: inline-block;
      width: 16px;
      height: 16px;
      border: 3px solid $color-white;
      border-radius: 15px;
      background-color: $color-oh-so-green;
      visibility: visible;
    }
  }
}
