@import 'src/assets/styles/utils/index.module.scss';

.container {
  padding: 10px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.9);
  color: $color-vape;
}

.title {
  margin-bottom: 5px;
  font-weight: $fw-bold;
}

.item {
  display: flex;
  align-items: center;
  white-space: nowrap;

  & + & {
    margin-top: 3px;
  }
}

.badge {
  width: 10px;
  margin-right: 10px;
}
