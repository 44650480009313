@import 'src/assets/styles/utils/index.module.scss';

.background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $z-modal-auth - 1;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-image: url('../../assets/images/bg-canary-alert.gif');
  background-color: $color-pretty-dark;
}

.modal {
  display: flex;
  flex: 1;
  width: 100%;
  z-index: 4001;
  align-self: center;
  height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
}

.header {
  height: 62px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px;
  background-color: rgba(33, 30, 33, 0.8);
  backdrop-filter: blur(10px);
}

.left {
  display: flex;
  align-items: center;
  flex-direction: row;
  
  span {
    color: $color-snow-in-april;
    margin-left: 16px;
    cursor: pointer;
    font-size: 1.4rem;
    font-weight: $fw-medium;
  }
}

.closeTop {
  display: flex;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  margin-left: 50px;
}

.arrowIcon {
  color: $color-snow-in-april;
}

.container {
  display: flex;
  align-items: center;
  flex-direction: column;

  h1 {
    color: $color-snow-in-april;
    max-width: 585px;
    align-self: center;
    text-align: center;
    font-size: 5rem;
    line-height: 6rem;
    font-weight: $fw-bold;
    margin: -15px 0 60px 0px;
  }
}

.content {
  width: 80%;
  display: flex;

  @media (max-width: 500px) {
    flex-direction: column;
  }
}

.box {
  min-height: 130px;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 30%;
  margin-right: 10px;
  padding: 16px;
  border-radius: 8px;
  background-color: rgba(52, 54, 63, 0.8);
  backdrop-filter: blur(10px);

  &:last-child {
    margin-right: 0px;
  }

  p {
    color: $color-snow-in-april;
    font-size: 1.33rem;
    line-height: 2rem;
    margin-bottom: 16px;

    span {
      color: $color-oh-so-green;
    }
  }


  @media (max-width: 500px) {
    width: 100%;
    margin-bottom: 16px;
  }
}

.box_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  span {
    color: $color-snow-in-april;
    font-size: 1.5rem;
    line-height: 1.8rem;
    font-weight: $fw-regular;
    letter-spacing: 1.3px;
  }
}

.box_active {
  background-color: $color-ace-blue;
  min-width: 40%;
}

.info {
  padding: 16px;
  margin-top: 64px;
  border-radius: 8px;
  background-color: rgba(52, 54, 63, 0.8);
  backdrop-filter: blur(10px);
  max-width: 60%;

  color: $color-snow-in-april;
  font-size: 1.33rem;
  line-height: 2rem;
  margin-bottom: 16px;

  a {
    color: $color-oh-so-green;
  }
}

.iconCheck {
  color: $color-oh-so-green;
}
