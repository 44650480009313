@import 'src/assets/styles/utils/index.module.scss';

.worldMap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.formLogin {
  position: absolute;
  top: 100px;
  left: 100px;
}

.content {
  display: flex;
  flex: 1;
  background-size: 75%;
  background-repeat: no-repeat;
  background-position: bottom right;
  align-items: center;
  padding: 0 70px;
  position: relative;
  margin-top: 10px;
}

.content::after {
  position: fixed;
  content: " ";
  bottom: 0;
  right: 0;
  width: 50%;
  z-index: 1;
  background-image: linear-gradient(transparent, #211e21);
  height: 90px;

  @media only screen and (min-width: 300px) and (max-width: 900px) {
    width: 100%;
  }
}

.paralax {
  div {
    &:first-child {
      z-index: 9;
    }
  }
}

.bg {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
  transition: background-image 0.5s ease-in-out;
  -webkit-transition: background-image 0.5s ease-in-out;
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: contain;
}

.bg::after {
  position: fixed;
  content: " ";
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 1;
  background-image: linear-gradient(transparent, #211e21);
  height:120px;

  @media only screen and (min-width: 300px) and (max-width: 900px) {
    width: 100%;
  }
}

.bg_default {
  background-image: url('../../assets/images/LandingPage/he1.png');
}
.bg_she1 {
  background-image: url('../../assets/images/LandingPage/she1.png');
}
.bg_she2 {
  background-image: url('../../assets/images/LandingPage/she2.png');
}
.bg_he2 {
  background-image: url('../../assets/images/LandingPage/he2.png');
}

.contentLinks {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.links {
  display: flex;
  width: 100%;
  max-height: 291px;
  height: 100%;
  flex-direction: column;
  display: flex;
  flex: 1;
  justify-content: space-between;
  -webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 3s; /* Firefox < 16 */
  -ms-animation: fadein 3s; /* Internet Explorer */
  -o-animation: fadein 3s; /* Opera < 12.1 */
  animation: fadein 3s;

  span {
    color: $color-oh-so-green;
    font-size: 3rem;
    line-height: 3.8rem;
    font-family: $ff-sans-secondary;
    font-weight: $fw-regular;
    cursor: pointer;

    transition: color 0.5s;
    &:hover {
      color: $color-london-fog;
    }
    
    @media only screen and (min-width: 300px) and (max-width: 900px) {
      font-size: 2.6rem;
      padding-right: 30px;
    } 
  }

  @media only screen and (min-width: 300px) and (max-width: 900px) {
    max-height: 270px;
    height: 100%;
  } 
}

.arrowDown {
  transform: rotate(-90deg);
  color: $color-snow-in-april;
  align-self: flex-start;
  margin-top: 38px;
  cursor: ponter;
  -webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 3s; /* Firefox < 16 */
  -ms-animation: fadein 3s; /* Internet Explorer */
  -o-animation: fadein 3s; /* Opera < 12.1 */
  animation: fadein 3s;

  transition: filter 0.2s;
  &:hover {
    filter: brightness(0.7);
  }
}

.arrowUp {
  transform: rotate(90deg);
  color: $color-snow-in-april;
  align-self: flex-start;
  margin-bottom: 38px;
  cursor: ponter;
  -webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 3s; /* Firefox < 16 */
  -ms-animation: fadein 3s; /* Internet Explorer */
  -o-animation: fadein 3s; /* Opera < 12.1 */
  animation: fadein 3s;

  transition: filter 0.2s;
  &:hover {
    filter: brightness(0.7);
  }
}

.boxInfo {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 0 70px;

  @media only screen and (min-width: 300px) and (max-width: 900px) {
    padding: 0 30px;
  } 
}

.info {
  display: flex;
  flex-direction: column;
  -webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 3s; /* Firefox < 16 */
  -ms-animation: fadein 3s; /* Internet Explorer */
  -o-animation: fadein 3s; /* Opera < 12.1 */
  animation: fadein 3s;

  h3 {
    color: $color-oh-so-green;
    font-size: 3rem;
    line-height: 3.5rem;
    font-family: $ff-sans-landingPage;
    font-weight: $fw-regular;
    margin-bottom: 25px;

    @media only screen and (min-width: 300px) and (max-width: 900px) {
      font-size: 2.6rem;
      padding-right: 30px;
    } 
  }

  p {
    color: $color-snow-in-april;
    font-size: 2.4rem;
    line-height: 3.6rem;
    font-family: $ff-sans-landingPage;
    font-weight: $fw-regular;

    @media only screen and (min-width: 300px) and (max-width: 900px) {
      font-size: 2.2rem;
      line-height: 3.2rem;
      padding-right: 30px;
    } 
  }

  .arrowDown, .arrowUp, .links, .info {
    @keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
    }
  
    /* Firefox < 16 */
    @-moz-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
    }
  
    /* Safari, Chrome and Opera > 12.1 */
    @-webkit-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
    }
  
    /* Internet Explorer */
    @-ms-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
    }
  
    /* Opera < 12.1 */
    @-o-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
    }
  }
}
