@import 'src/assets/styles/utils/index.module.scss';

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  footer {
    width: 100%;
    display: flex;
    padding-top: 15px;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: -12px;
      right: -12px;
      height: 1px;
      background-color: $color-london-fog;
    }
    button {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      &:not(:last-of-type) {
        border-right: thin solid $color-london-fog;
      }
      span {
        margin-left: 7px;
        font-weight: 500;
      }
    }
  }
}

.hero {
  width: 100%;
  height: 300px;
  background-color: $color-vape;
  border-radius: 5px;
  padding: 20px 18px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .usage {
      display: flex;
      flex-direction: column;
    }
  }
  .icon {
    flex: 1;
    svg {
      height: 100%;
    }
  }
  p {
    font-size: 1.6rem;
    line-height: 20px;
  }
}

.navigation {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
  margin-bottom: 20px;
  button {
    font-size: 1.4rem;
    line-height: 1.6rem;
    font-weight: 400;
    &.active {
      font-weight: 700;
      border-bottom: 2px solid $color-oh-so-green;
    }
  }
}

.assetsList {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  .content {
    flex: 1;
  }
}

.settings {
  flex: 1;
}

.search {
  margin-bottom: 10px;
}

.assetListItem {
  height: 40px;
  display: flex;
  padding: 0 8px 0 12px;
  position: relative;
  background-color: $color-vape;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 7px;
  border-radius: 4px;
  &.isSelected {
    box-shadow: 0 0 0 1px $color-oh-so-green;
  }
  &.isSelectable {
    cursor: pointer;
    user-select: none;
  }
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 2px;
    height: 18px;
    background-color: $color-pretty-dark;
  }
  & > svg {
    color: $color-metal-grey;
  }
  p {
    flex: 1;
    padding-left: 12px;
    font-weight: 700;
  }
}

.addAssetButton {
  width: 100%;
  height: 32px;
  background-color: $color-ashy;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-snow-in-april;
  border-radius: 4px;
  font-weight: 500;
  svg {
    margin-right: 10px;
  }
  span {
    opacity: 0.5;
    margin-left: 5px;
    font-weight: 400;
    font-size: 1rem;
  }
}

.saveSettings {
  width: 100%;
  height: 45px;
  margin-top: 15px;
  background-color: $color-oh-so-green;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}
