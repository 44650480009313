@import 'src/assets/styles/utils/index.module.scss';

.scmlandingpage {
  padding: 0 0 5px 0;
  scroll-behavior: smooth;
  width: 100%;
  background: $color-pretty-dark;
}
.content {
  display: flex;
  flex: 1;
  height: 100vh;
  overflow-y: scroll;
  background-color: $color-pretty-dark;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  padding: $header-height 0 0 0;

  .scmvideo {
    width: 100%;
    display: flex;
    position: relative;
    margin: 150px 0;
    video {
      width: 100%;
    }
    .play {
      background-image: url('../../assets/images/Scm/icon-play.svg');
      background-repeat: no-repeat;
      width: 90px;
      height: 90px;
      position: absolute;
      left: 0%;
      right: 0%;
      top: 0%;
      bottom: 0%;
      margin: auto;
      background-size: contain;
      background-position: center;
    }
    .pause {
      background-image: url('../../assets/images/Scm/icon-player-pause-circle.svg');
      background-repeat: no-repeat;
      width: 90px;
      height: 90px;
      position: absolute;
      left: 0%;
      right: 0%;
      top: 0%;
      bottom: 0%;
      margin: auto;
      background-size: contain;
      background-position: center;
      fill: $color-vape;
    }
  }
  .bannerwrapper {
    background-image: url('../../assets/images/Scm/bg-scm.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .banner {
    margin: 60px 0 60px 50px;
    display: flex;
    flex-direction: column;
    gap: 4rem;

    /* Mobile */
    @media only screen and (min-width: 300px) and (max-width: 900px) {
      margin: 60px 0 60px 0px;
    }
  }
  .center {
    text-align: center;
  }
  .features {
    text-align: center;
    min-height: 250px;
    color: $color-white;
    padding: 10px 50px;
    img {
      width: 50px;
    }

    h4 {
      font-size: 22px;
      line-height: 26px;
      margin: 10px 0;
      text-wrap: nowrap;
    }
    p {
      font-size: 16px;
      line-height: 19px;
      padding-bottom: 10px;
      padding-inline: 20px;
    }
    a {
      color: $color-singularity-red;
      font-size: 16px;
    }
  }
  .featureItem {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    img {
      max-width: 58px;
    }
    h2 {
      font-size: 51px;
      color: $color-vape;
    }
    .customList {
      display: flex;
      flex-direction: row;
      align-items: normal;
      padding-bottom: 14px;

      p {
        color: $color-vape;
        font-size: 18px;
        line-height: 24px;
      }
    }
    .customCheck {
      color: $color-singularity-red;
      font-size: 16px;
      margin-right: 10px;
      margin-top: 0.5rem;

      svg {
        background: $color-tundora;
      }
    }
  }
  .featureImage {
    display: block;
    width: 100%;
    text-align: center;
    img {
      padding: 0 20px;
      max-height: 690px;
    }
    h3 {
      text-align: left;
      font-size: 30px;
      color: $color-vape;
      padding: 20px 0 20px 0;
    }
  }
  .tradingSection {
    min-height: 450px;

    .tradingSection_emails {
      position: relative;
      padding: 0 0 30px 0;
    }

    .tradingSection_home {
      position: absolute;
      top: 136px;
      right: 20px;
      width: 65%;
      z-index: 1;
    }
  }
  .listOfUsers {
    .listOfUsers_content {
      background: $color-vape;
      padding: 10px;
      width: 55%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 4px;
      position: relative;
      text-align: left;
      margin-bottom: 15px;
    }
    .listOfUsers_Icon {
      padding: 0;
      width: 15%;
      img {
        padding: 0;
      }
    }
    .listOfUsers_Info {
      width: 60%;
      h4 {
        color: $color-ashy;
      }
      p {
        color: $color-metal-grey;
      }
    }
    button {
      margin-left: 12px;
      border: 1px solid $color-oh-so-green;
      border-radius: 0;
      background: $color-oh-so-green;
      color: $color-oh-so-green;
      border-radius: 50%;
      padding: 3px;
      svg {
        display: inline-block;
        color: $color-tundora;
      }
    }
  }

  .subtitle {
    font-size: 38px;
    color: $color-white;
    text-wrap: wrap;
    text-align: left;
  }
  .title {
    font-size: 64px;
    color: $color-white;
    line-height: 135%;
    span {
      border-bottom: 8px solid $color-singularity-red;
    }
  }
  .primary {
    color: $color-vape;
    white-space: normal;
    height: 60px;
    padding: 0 40px;
  }
  .container {
    max-width: 1620px;
    display: block;
    margin: auto;
    .section {
      margin: 0 30px 70px 30px;
      display: flex;
      flex-flow: column wrap;
      flex-direction: row;

      .col {
        width: 100%;
      }
      .col1 {
        width: 50%;
        // height: 50%;
        min-height: 400px;
        text-wrap: nowrap;
        align-content: center;
      }
      .col2 {
        width: 50%;
        // height: 50%;
        min-height: 400px;
        align-content: center;
      }
      .col4 {
        width: 25%;
      }
      .col3 {
        width: 40%;
      }
      .register {
        width: 100%;
        button {
          margin: 0 auto;
          width: 100%;
        }
      }
    }
    .rowReverse {
      flex-direction: row-reverse;
    }
    .footer {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      button {
        width: 100%;
      }
      .title {
        font-size: 18px;
        text-align: center;
        a {
          text-decoration: underline;
        }
      }
    }
  }
  .scmIcons {
    color: $color-singularity-red;
  }
  .bottomToTopFade {
    position: fixed;
    left: 50%;
    z-index: 21 !important;
    bottom: 20px;
    -webkit-animation: slideInFade 6s forwards;
    -moz-animation: slideInFade 6s forwards;
    animation: slideInFade 6s forwards;
  }

  .scrollBtn {
    background: none;
    transform: translateX(-50%) translateY(-50%);
    -webkit-transition: all 6s ease-in-out;
    -moz-transition: all 6s ease-in-out;
    -ms-transition: all 6s ease-in-out;
    -o-transition: all 6s ease-in-out;
    transition: all 6s ease-in-out;

    span {
      font-family: 'NB Akademie Std', sans-serif;
      font-weight: $fw-medium;
      letter-spacing: 1px;
      font-size: 2.7rem;
      margin-right: 16px;
      transition: filter 0.2s;
    }
  }
  .scrollBtnDisabled {
    cursor: default;
    -webkit-transition: all 6s ease-in-out;
    -moz-transition: all 6s ease-in-out;
    -ms-transition: all 6s ease-in-out;
    -o-transition: all 6s ease-in-out;
    transition: all 6s ease-in-out;
    span,
    svg {
      opacity: 0;
    }
  }

  .buttonScroll {
    color: $color-snow-in-april;
  }
  /* Mobile */
  @media only screen and (min-width: 300px) and (max-width: 900px) {
    max-width: 100%;
    padding: $header-height 0 15px 0;
    overflow-x: hidden;

    .scrollBtn {
      display: none;
    }

    .title {
      font-size: 5em;
      text-wrap: wrap;
    }

    .container {
      .section {
        flex-direction: column;
        margin: 0 20px 30px 30px;
        .col1 {
          width: 100%;
        }
        .col2 {
          width: 100%;
          min-height: 100px;
          align-content: center;
        }
        .col4 {
          width: 100%;
        }
        .colHideXS {
          display: none;
        }
      }
      .primary {
        height: 48px;
        width: 100%;
      }
      .sectionReverse {
        flex-flow: column-reverse;
      }
    }
  }
  .features {
    padding: 0;
  }
}
footer {
  margin: 20px;
}
// .footerMenu {
//   // padding: 0 40px 50px 40px;
// }
.pageNavLink {
  display: inline-block;
  position: relative;
  margin-right: 3rem;
  transition: color 0.2s;
  font-family: $ff-sans;
  font-size: 1.6rem;
  font-weight: $fw-regular;
  line-height: 1.9rem;

  &:hover {
    color: $color-snow-in-april;
  }
}
.ssss {
  overflow-y: scroll;
}
.boxIcons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 200px;
  margin-right: 20px;

  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    align-items: center;
    justify-content: flex-start;
  }

  a {
    margin: 0 !important;
  }

  .icons {
    color: $color-snow-in-april;
  }

  .mailIcon {
    position: relative;
    top: 2px;
  }
}
.footernav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footerRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 83px;
}

/* slider */
.slideContainer {
  background-image: url('../../assets/images/Scm/sliderbg.png');
  background-repeat: no-repeat;
  background-position: left;
  height: 550px;
  width: 100%;
  background-size: contain;
  .featureswiper {
    top: 120px;
    min-height: 300px;

    div {
      width: 100% !important;
    }
  }
  .sliderItem {
    .slideContent {
      background: #31333d;
      padding: 10px;
      width: 280px !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 35px;
      border-radius: 4px;
      position: relative;
      margin-left: 10px;
      margin-bottom: 15px;
    }
    .slideIcon {
      width: 150px !important;
      text-align: left;
      img {
        width: 40px;
      }
    }
    .slideInfo {
      text-align: left;
      color: $color-metal-grey;
      line-height: 1.2em;
    }
    .slideUtility {
      text-align: right;
      svg {
        display: inline-block;
        color: $color-snow-in-april;
      }
      .homeInfo {
        display: flex;
        justify-content: flex-end;
        .assetName {
          background-color: $color-vape;
          display: inline;
          width: auto !important;
          border-radius: 4px;
          padding: 5px;
        }
        button {
          margin-left: 12px;
          border: 1px solid $color-oh-so-green;
          border-radius: 0;
          background: $color-oh-so-green;
          color: $color-oh-so-green;
          border-radius: 4px;
          padding: 3px;
          svg {
            display: inline-block;
            color: $color-tundora;
          }
        }
      }
    }
  }
}
.slideItem {
  text-align: center;
}
.bulletClickable {
  bottom: -5px !important;
}
.timelineicon {
  background-color: $color-metal-grey;
  height: 7px;
  width: 7px;
  display: inline-block;
  margin: 3px;
  border-radius: 50%;
}
.timelineiconactive {
  background-color: $color-snow-in-april;
}
@media only screen and (min-width: 300px) and (max-width: 900px) {
  .bulletClickable {
    left: -20px !important;
    text-align: right;
    bottom: 20px !important;
  }
  .footernav {
    flex-direction: column;
  }
  .boxIcons {
    justify-content: space-around;
  }
}
@media only screen and (min-width: 300px) and (max-width: 769px) {
  /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  .featureswiper {
    width: 300px;
  }
  .bulletClickable {
    left: 0 !important;
    text-align: center;
    bottom: 0 !important;
  }
  .slideContent {
    margin-left: 0 !important;
  }
  .tradingSection {
    min-height: auto !important;
  }
  .featureImage {
    img {
      padding: 0 !important;
      max-height: 690px !important;
    }
  }
  .listOfUsers_content {
    width: 75% !important;
  }
  .tradingSection_home {
    right: 0;
    width: 65%;
  }
}

@media (min-width: 801px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .featureswiper {
    width: 400px;
  }
  .bulletClickable {
    left: 0 !important;
    text-align: center;
    bottom: 0 !important;
  }
}
