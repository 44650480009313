@import 'src/assets/styles/utils/index.module.scss';

.menu_btn {
  position: relative;
  display: none;
  align-items: center;
  justify-content: center;
  width: 83px;
  height: 60px;
  background-color: $color-pretty-dark;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    display: flex;
  }
}

.menu_btn__burger {
  width: 24px;
  height: 3px;
  background: $color-snow-in-april;
  transition: all 0.2s ease-in-out;
}

.menu_btn__burger::before,
.menu_btn__burger::after {
  content: '';
  position: absolute;
  width: 24px;
  height: 3px;
  background: $color-snow-in-april;
  transition: all 0.2s ease-in-out;
}

.menu_btn__burger::before {
  transform: translateY(-8px);
}

.menu_btn__burger::after {
  transform: translateY(8px);
}

.active_menu_btn__burger {
  background: transparent;
  transform: translateX(-50px);
}

.active_menu_btn__burger::before {
  background: $color-snow-in-april;
  transform: rotate(45deg) translate(35px, -35px);
}

.active_menu_btn__burger::after {
  background: $color-snow-in-april;
  transform: rotate(-45deg) translate(35px, 35px);
}
