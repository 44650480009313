@import 'src/assets/styles/utils/index.module.scss';

.container {
  display: flex;
  align-items: center;
  height: 20px;
  color: $color-ashy;
  font-size: 11px;
  font-weight: bold;
  justify-content: space-between;
}
