@import 'src/assets/styles/utils/index.module.scss';

.boxContent {
  position: absolute;
  background-color: $color-ace-blue;
  max-width: 350px;
  width: 100%;
  top: 48%;
  left: 45px;
  border-radius: 2px;
  z-index: 22 !important;

  h3 {
    font-size: 2.6rem;
    line-height: 2.6rem;
    font-weight: $fw-medium;
    letter-spacing: 1.5px;
    margin-bottom: 4%;
    text-transform: capitalize;
  }

  p,
  a {
    font-size: 1.7rem;
    line-height: 2.8rem;
    font-weight: $fw-regular;

    span {
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 1200px) {
    zoom: 0.75;
  }

  @media only screen and (min-width: 1280px) {
    top: 52%;
    max-width: 310px;
  }

  @media only screen and (min-width: 1366px) {
    top: 52%;
    max-width: 310px;
  }

  @media only screen and (min-width: 1440px) {
    top: 55%;
    max-width: 310px;
  }

  @media only screen and (min-width: 1600px) {
    top: 50%;
  }

  @media only screen and (min-width: 1920px) {
    top: 48%;

    h3 {
      font-size: 2.5rem;
      line-height: 3.3rem;
    }

    p,
    a {
      font-size: 1.8rem;
      line-height: 3.3rem;
    }
  }

  @media only screen and (min-width: 2547px) {
    top: 40%;
    max-width: 600px;

    h3 {
      font-size: 3rem;
      line-height: 3.3rem;
    }

    p,
    a {
      font-size: 2.4rem;
      line-height: 4rem;
    }
  }

  @media only screen and (min-width: 2560px) {
    top: 40%;
    max-width: 600px;

    h3 {
      font-size: 3rem;
      line-height: 3.3rem;
    }

    p {
      font-size: 2.4rem;
      line-height: 4rem;
    }
  }

  @media only screen and (min-width: 2880px) {
    top: 38%;
    max-width: 600px;

    h3 {
      font-size: 3rem;
      line-height: 3.3rem;
    }

    p,
    a {
      font-size: 2.4rem;
      line-height: 4rem;
    }
  }

  @media only screen and (min-width: 3072px) {
    top: 38%;
    max-width: 600px;

    h3 {
      font-size: 3.1rem;
      line-height: 3.4rem;
    }

    p,
    a {
      font-size: 2.5rem;
      line-height: 4.1rem;
    }
  }

  @media only screen and (min-width: 3840px) {
    top: 38%;
    max-width: 600px;

    h3 {
      font-size: 3rem;
      line-height: 3.3rem;
    }

    p,
    a {
      font-size: 2.6rem;
      line-height: 4.2rem;
    }
  }
}

.boxText {
  margin: 6%;
  font-family: $ff-sans-landingPage;
  color: $color-snow-in-april;

  a {
    cursor: pointer;
    color: $color-oh-so-green;
    border-bottom: 2px solid $color-oh-so-green;
  }
}
