@import 'src/assets/styles/utils/index.module.scss';

.container {
}

.marker {
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
}

.rootMarker {
  composes: marker;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    border: thin solid green;
    border-radius: 50%;
    pointer-events: none;
    transform: translate(-50%, -50%);
  }
}

.markerText {
  position: absolute;
}

.markerCommunityText {
  top: -50px;
  left: 50px;
  composes: markerText;
}

.communityRing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 153px;
  height: 33px;
  border: 7px solid #6f35b5;
  border-radius: 50%;
  pointer-events: auto;
  transform: translate(-50%, -50%);
}

.gridMarket {
}

.gridMarketBullet {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background: linear-gradient(155.56deg, #34363f 8.46%, #27292f 84.51%);
  color: $color-snow-in-april;
  pointer-events: auto;
}

.createCommunityMarket {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background: linear-gradient(134.36deg, #1641f7 19.08%, #1134c7 93.26%);
  color: $color-snow-in-april;
  pointer-events: auto;
}

.gridMarketTooltip {
  position: absolute;
  top: -4px;
  left: 50%;
  pointer-events: auto;
  transform: translate(-50%, -100%);
}

.housePinBtn {
  color: #fc1355;
  pointer-events: auto;
}

.houseOwnerPopup {
  padding: 6px 24px 6px 6px;
  display: flex;
  align-items: center;
  background-color: transparentize($color-modal-bg, 0.4);
  backdrop-filter: blur(2px);
  border-radius: 5px;
  position: relative;
  transform: translateY(-10px);
  .avatar {
    width: 22px;
    height: 22px;
    border-radius: 100%;
    background-size: cover;
    background-position: center;
    border: 1px dashed $color-metal-grey;
    &.hasAvatar {
      border: none;
    }
  }
  span {
    margin-left: 8px;
    color: $color-snow-in-april;
    font-weight: 400;
  }
  svg {
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
  }
}
