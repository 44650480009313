@import 'src/assets/styles/utils/index.module.scss';

.imageFloating {
  max-height: 100vh;
  position: absolute;
  z-index: 13;
}

.grayBox {
  position: absolute;
  background-color: $color-ashy;
  max-width: 255px;
  width: 100%;
  border-radius: 2px;
  top: 62%;
  left: -60%;
  z-index: 21;

  p {
    font-size: 1.4rem;
    line-height: 2.4rem;
  }

  a {
    cursor: pointer;
    color: $color-oh-so-green;
  }

  @media only screen and (min-width: 1280px) {
  }

  @media only screen and (min-width: 1366px) {
  }

  @media only screen and (min-width: 1440px) {
  }

  @media only screen and (min-width: 2547px) {
    left: -25%;
    top: 97%;
    p {
      font-size: 1.9rem;
      line-height: 3rem;
    }
  }
}

.grayBoxText {
  margin: 6%;
  font-family: $ff-sans-landingPage;
  font-weight: $fw-regular;
  color: $color-snow-in-april;

  @media only screen and (min-width: 1921px) and (max-width: 2560px) {
    p {
      font-size: 2rem;
      line-height: 3rem;
    }
  }
}

.deploymentWrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.city {
  width: 100%;
  position: relative;
}

.ringOne {
  right: 67.5%;
  bottom: 74%;
  width: 25%;
}

.ringTwo {
  right: 61.5%;
  bottom: 28%;
  width: 25%;
}

.ringThree {
  right: 39.5%;
  bottom: 46%;
  width: 25%;
}

.ringFour {
  right: 20%;
  bottom: 72%;
  width: 25%;
}

.ringFive {
  right: 4.5%;
  bottom: 33%;
  width: 25%;
}
