@import 'src/assets/styles/utils/index.module.scss';

.stepText {
  display: inline-block;
  align-items: center;
  justify-content: center;
  padding: 6px 12px 6px 12px;
  border-radius: 15px;
  background-color: $color-black;
  color: $color-snow-in-april;
  margin-bottom: 0px;
}
