@import 'src/assets/styles/utils/index.module.scss';

.tgContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.tgTooltip {
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  height: auto;
  padding: 15px;
  border-radius: 4px;
  background: rgba($color: $color-pretty-dark, $alpha: 0.7);
  transform: translate(calc(-100% - 20px), -50%);
  backdrop-filter: blur(20.3526px);

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    clear: both;
    width: 0;
    height: 0;
    border-top: 10px solid rgba($color: $color-pretty-dark, $alpha: 0.7);
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    transform: translate(75%, -50%) rotate(-90deg);
  }

  .tgTooltipCountry {
    margin-bottom: 11.5px;
    color: $color-oh-so-green;
    font-size: 11px;
    font-family: 'NB Akademie Std';
    line-height: 12px;
  }

  .tgTooltipList {
    position: relative;
    overflow-y: scroll;
    width: auto;
    min-width: 150px;
    height: auto;
    min-height: 35px;
    max-height: 285px;

    &.tgTooltipListMultiRows {
      display: flex;
      flex-wrap: wrap;
      width: 400px;
      min-width: none;

      /* width */
      &::-webkit-scrollbar {
        display: block;
        width: 5px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: $color-metal-grey;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $color-kinda-dirty;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: $color-aubergine;
      }
    }
  }

  .tgTooltipItem {
    display: flex;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 15px 0 0;

    &.tgTooltipItemMultiRows {
      width: 50%;

      &:nth-child(2) {
        padding: 0;
      }
    }

    &:nth-child(1) {
      padding: 0;
    }

    .avatar {
      z-index: 1;
      width: 35px;
      height: 35px;
      margin-right: 5px;

      img {
        width: 30px;
        height: 30px;
        filter: grayscale(100%);
      }
    }

    .tgLabelContainer {
      z-index: 1;
      display: flex;
      flex-direction: column;
    }

    .tgTooltipTitle {
      // margin-bottom: 4px;
      color: white;
      font-weight: 500;
      font-size: 13px;
      line-height: 14px;
    }

    .tgTooltipLabel {
      color: $color-oh-so-green;
      font-weight: 500;
      font-size: 11px;
      line-height: 12px;
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
