
@import 'src/assets/styles/utils/index.module.scss';

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100vh;
  background-color: $color-ashy;
  flex-direction: column;
  position: relative;
  padding: $header-height 40px 0 40px;

  .form {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    max-width: 400px;

    input {
      width: 100%;
      background: $color-white;
      margin: 10px 0;
      min-height: 40px;
      border-radius: 4px;
      padding: 5px;
      font-size: 14px;
      
      &::placeholder {
        padding: -5px;
      }
    }

    button {
      margin: 10px 0;
      min-width: 100px;
      min-height: 40px;
      background: $color-singularity-red;
      border-radius: 4px;
      color: $color-white;
      font-weight: 500;
      font-size: 14px;
    }

    p {
      margin: 10px 0;
      font-size: 16px;
    }
  }

  &::after {
    position: fixed;
    content: " ";
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 1;
    background-image: linear-gradient(transparent, #34363f);
    height: 200px;
  }

  @media only screen and (min-width: 300px) and (max-width: 900px) {
    max-width: 100%;
    padding: $header-height 30px 0 30px;
    height: unset;
  }
  
  .buttonBack {
    z-index: 1;
    margin: 4% 0;
    padding: 0;
    background: none;
    font-weight: $fw-regular;
    font-size: 16px;
    font-family: 'NB Akademie Std';
    line-height: 17px;
    letter-spacing: 1px;
    display: flex;
    justify-content: flex-start;

    @media only screen and (max-width: 600px) {
      margin: 12% 0;
    }
  }

  .container {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;

  .heading {
    font-family: $ff-sans-landingPage;
    font-weight: 500;
    font-size: 2.5em;
    line-height: 32px;
    color: $color-oh-so-green;
    margin-bottom: 30px;
  }
}
}