@import 'src/assets/styles/utils/index.module.scss';

.assetCounterWrapper {
  width: 100%;
}

.assets {
  display: flex;
  justify-content: space-between;

  &.expanded {
    justify-content: flex-start;

    .assetWrapper {
      margin-right: 5px;
    }
  }
}

.assetWrapper {
  width: 105px;
  height: 140px;
  margin-bottom: 14px;
}

.singleAsset {
  margin-bottom: 14px;
}

.buttonExpand {
  width: 100%;
}
