.container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  overflow: hidden;
  // right: 15px;
  // top: 50%;
  z-index: 20;
}

.section {
  background-color: #4b464b;
  width: 6px;
  height: 24px;
  border-radius: 20px;
  margin-bottom: 5px;
  overflow: hidden;
  position: relative;
}

.activeBar {
  position: absolute;
  width: 6px;
  height: 12px;
  border-radius: 20px;
  background-color: #1641f7;
}

.statusBarContent {
  position: absolute;
  top: 45%;
  right: 15px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: 400px;
  flex-direction: column;
}

