@import 'src/assets/styles/utils/index.module.scss';

.imageFloating {
  max-height: 100vh;
  position: absolute;
  z-index: 13;
}

.basePlaqueOne {
  right: 4%;
  top: 53%;
  width: 100%;
}

.linesPlaque {
  right: 12%;
  top: 36%;
  width: 78%;
}

.middlePlaque {
  right: 4%;
  top: 27%;
  width: 100%;
}

.linksPlaque {
  right: 21%;
  top: 2%;
  width: 57%;
}

.topPlaque {
  right: 4%;
  top: -20%;
  width: 100%;
}

.boxVoltage {
  background-color: $color-ashy;
  width: 420px;
  max-width: 100%;
  border-radius: 5px;
  padding: 12px;
  position: absolute;
  left: 68%;
  bottom: 4%;
  z-index: 21;

  span {
    color: $color-snow-in-april;
    font-size: 2rem;
    letter-spacing: 1px;
    font-weight: $fw-medium;
  }

  @media screen and (max-width: 1200px) {
    zoom: 0.8;
  }

  @media only screen and (min-width: 1921px) and (max-width: 2560px) {
    left: 68%;
    bottom: 10%;
  }
}

.voltage {
  display: flex;
  justify-content: space-between;
  z-index: 4;

  button {
    background-color: $color-pretty-dark;
    opacity: 0.5;
    border-radius: 5px;
    height: 42px;
    max-height: 100%;
    padding: 12px;
    color: $color-metal-grey;
    font-weight: $fw-medium;
    letter-spacing: 1px;
    // margin-right: 12px;
    margin-top: 12px;
  }

  .actived {
    background-color: $color-pretty-dark;
    opacity: 1;
    border: 1pt solid $color-snow-in-april;
    color: $color-snow-in-april;
  }
}

.marketTwoTooltip {
  z-index: 14;
  position: absolute;
  top: 18%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

  img {
    position: relative;
    left: -8px;
    object-fit: contain;
  }

  @media only screen and (min-width: 1920px) and (min-height: 1080px) {
    top: 27%;
  }
}

.descriptionTooltip {
  font-size: 1.4rem;
  font-weight: $fw-regular;
  margin-top: 5px;
}

.plaqueMarketTwo {
  right: 4%;
  top: 25%;
  width: 100%;
}

.grayBox {
  position: absolute;
  background-color: $color-ashy;
  max-width: 255px;
  width: 100%;
  border-radius: 2px;
  bottom: 0%;
  left: -60%;
  z-index: 21;

  p {
    font-size: 1.4rem;
    line-height: 2.4rem;

    a {
      cursor: pointer;
      border-bottom: 2px solid $color-snow-in-april;
    }
  }

  @media only screen and (min-width: 2547px) {
    left: -40%;

    p {
      font-size: 1.9rem;
      line-height: 3rem;
    }
  }
}

.grayBoxText {
  margin: 6%;
  font-family: $ff-sans-landingPage;
  font-weight: $fw-regular;
  color: $color-snow-in-april;
}

.marketThreeTooltip {
  position: absolute;
  top: 2%;
  left: 0%;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 14;

  img {
    position: relative;
    left: -8px;
    object-fit: contain;
  }

  @media only screen and (min-width: 1920px) and (min-height: 1080px) {
    top: 10%;
  }
}

.basePlaqueMarket {
  right: 4%;
  top: 43%;
  width: 100%;
}

.linesMarketThree {
  right: 12%;
  top: 21%;
  width: 78%;
}

.topPlaqueMarketThree {
  right: 4%;
  top: 12%;
  width: 100%;
}

.marketNextTooltip {
  position: absolute;
  top: 2%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 15;

  img {
    position: relative;
    left: -8px;
    object-fit: contain;
  }

  @media screen and (max-width: 1200px) {
    zoom: 0.9;
    top: 0%;
  }
}

.basePlaqueNext {
  right: 4%;
  top: 53%;
  width: 100%;
  filter: blur(3px);
  z-index: 3;
}

.linesPlaqueNext {
  right: 12%;
  top: 36%;
  width: 78%;
  filter: blur(2px);
}

.middlePlaqueNext {
  right: 4%;
  top: 27%;
  width: 100%;
}

.linksPlaqueNext {
  right: 21%;
  top: 2%;
  width: 57%;
  z-index: 13;
}

.topPlaqueNext {
  right: 4%;
  top: -20%;
  width: 100%;
  z-index: 14;
}
