@import 'src/assets/styles/utils/index.module.scss';

.container {

}

.energyExportIndicatorWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  background: linear-gradient(180deg, #80b0f8, #4264f3);
  color: $color-snow-in-april;

  svg {
    &:nth-child(1) {
      opacity: 0.25;
    }

    &:nth-child(2) {
      opacity: 0.5;
    }

    &:nth-child(3) {
      opacity: 0.75;
    }
  }
}

.energyExporter {
  flex-direction: column-reverse;
  background: linear-gradient(180.03deg, #6e6, #4264f3);
}

.energyImporter {
  background: linear-gradient(180.03deg, #fc1355, #4264f3);

  svg {
    transform: rotate(180deg);
  }
}
