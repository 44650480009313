@import 'src/assets/styles/utils/index.module.scss';

.background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $z-modal-auth - 1;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(1px);
}

@-moz-document url-prefix() {
  .background {
    background: rgba(255, 255, 255, 0.7);
  }
}

.pictureCropperModal {
  top: 50%;
  left: 50%;
  z-index: $z-modal-auth;
  padding: 20px;
  border-radius: 6px;
  background: rgb(33, 30, 33);
  transform: translate3d(-50%, -50%, 0);
  backdrop-filter: brightness(60%);

  @media (max-width: 500px) {
    top: 0%;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
    transform: unset;
  }

  .header {
    position: absolute;
    top: 5px;
    left: 15px;
    color: $color-white;
    font-size: 1.6rem;
  }

  .closeBtn {
    position: absolute;
    top: 5px;
    right: 10px;
  }

  .body {
    margin: 15px 0;
    color: $color-metal-grey;
    font-size: 1.2rem;
  }

  .footerBtns {
    display: flex;
    flex-direction: row-reverse;
    column-gap: 5px;
  }
}
