@import 'src/assets/styles/utils/index.module.scss';

.imageFloating {
  max-height: 100vh;
  position: absolute;
  z-index: 13;
}

.boxContent {
  position: absolute;
  background-color: $color-ace-blue;
  max-width: 95vw;
  max-height: 297px;
  left: 2.5%;
  right: 2.5%;
  bottom: 5%;
  border-radius: 2px;
  z-index: 14;
}

.boxText {
  margin: 6%;
  font-family: $ff-sans-landingPage;
  color: $color-snow-in-april;

  h3 {
    font-size: 2.2rem;
    line-height: 2.4rem;
    font-weight: $fw-medium;
    letter-spacing: 1.5px;
    margin-bottom: 4%;
  }

  p {
    font-size: 1.6rem;
    line-height: 2.8rem;

    span {
      border-bottom: 2px solid $color-snow-in-april;
    }
  }
}

.waveBackground {
  height: 100%;
  max-width: 100%;
  background-image: url('../../../../assets/images/LandingPage/marketDesign/layerOne_exchanges/ripples.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left 50vh bottom 80%;
}

.basePlaqueApi {
  right: 0%;
  top: 75%;
  width: 90%;
}

.linesPlaqueApi {
  right: 0%;
  top: 63%;
  width: 82%;
}

.squareOnePlaqueApi {
  right: 0%;
  top: 68%;
  width: 90%;
}

.apiStyleOneApi {
  left: 0%;
  top: 80%;
  width: 30%;
}

.middlePlaqueApi {
  right: 0%;
  top: 55%;
  width: 100%;
}

.linksPlaqueApi {
  right: 0%;
  top: 37%;
  width: 75%;
}

.squareTwoPlaqueApi {
  right: 0%;
  top: 45%;
  width: 90%;
}

.apiStyleTwoApi {
  left: 0%;
  top: 55%;
  width: 30%;
}

.topPlaqueApi {
  right: 0%;
  top: 30%;
  width: 100%;
}

.squareThreePlaqueApi {
  right: 0%;
  top: 33%;
  width: 90%;
}

.apiStyleThreeApi {
  left: 0%;
  top: 43%;
  width: 30%;
}

.s_operador {
  bottom: 8%;
  margin-bottom: 3px;
}

.s_market {
  bottom: 3%;
  margin-top: 3px;
}

.s_aggregator {
  bottom: 8%;
  margin-bottom: 3px;
}

.subtitle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: $color-ashy;
  border-radius: 5px;
  padding: 7px;
  max-width: 183px;
  width: 100%;
  right: 3%;
  z-index: 14;

  span {
    margin-left: 8px;
    font-family: $ff-sans-landingPage;
    color: $color-snow-in-april;
    font-weight: $fw-medium;
    font-size: 1.3rem;
  }
}

.communityTooltip {
  position: absolute;
  right: 25%;
  top: 44%;
  z-index: 14;

  img {
    position: relative;
    left: -8px;
    // top: 5px;
    // width: 20px;
    // height: 30px;
    object-fit: contain;
  }
}

.singleCommunityApi {
  right: 0%;
  top: 60%;
  width: 100%;
}

.apiCommunityApi {
  right: 0%;
  top: 60%;
  width: 100%;
}

.ringApi {
  right: 0%;
  top: 53%;
  width: 93%;
}

.linkApi {
  right: 44%;
  top: 35%;
  width: 4%;
  height: 40%;
}

.houseTooltip {
  position: absolute;
  right: 58%;
  top: 50%;
  z-index: 15;

  img {
    position: relative;
    left: -8px;
    // top: 5px;
    // width: 20px;
    // height: 30px;
    object-fit: contain;
  }
}

.houseBlue {
  right: 20%;
  top: 62%;
  width: 56%;
  z-index: 14;
}

.solarPanel {
  right: 75%;
  top: 60%;
  width: 25%;
  z-index: 14;
}

.car {
  right: 70%;
  top: 80%;
  width: 25%;
  z-index: 14;
}

.battery {
  right: 10%;
  top: 85%;
  width: 25%;
  z-index: 14;
}

.consumption {
  right: 0%;
  top: 70%;
  width: 25%;
  z-index: 14;
}

.shadowBlue {
  right: 5%;
  top: 55%;
  width: 100%;
  filter: brightness(0.7);
}

.lineDiamondApi {
  right: 0%;
  top: 55%;
  width: 90%;
  z-index: 14;
}

// .lineHouse {
//   right: 11%;
//   top: 31%;
//   width: 25%;
// }

.singleDiamondOne {
  right: 45%;
  top: 65%;
  width: 10%;
  z-index: 14;
}

// .singleDiamondTwo {
//   right: 32%;
//   top: 61%;
//   width: 2.5%;
// }

// .singleDiamondThree {
//   right: 19%;
//   top: 64%;
//   width: 2.5%;
//   z-index: 14;
// }

// .singleDiamondFour {
//   right: 13%;
//   top: 46%;
//   width: 2.5%;
// }

.ringHouse {
  right: 22%;
  top: 53%;
  width: 56%;
  z-index: 14;
}
