@import 'src/assets/styles/utils/index.module.scss';

.box {
  display: flex;
  flex-direction: column;
  padding: 14px 10px;
  border-radius: 5px;
  margin-top: 20px;
  background: $color-ashy;
  border: 1px solid $color-oh-so-green;

  span {
    font-family: $ff-sans-landingPage;
    color: $color-snow-in-april;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 17px;
    margin-bottom: 8px;
  }

  div {
    font-family: $ff-sans;
    color: $color-snow-in-april;
    font-size: 1.4rem;
    line-height: 16px;
  }
}
