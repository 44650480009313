@import 'src/assets/styles/utils/index.module.scss';

.checkboxWrapper {
}

.disabled {
  &,
  * {
    cursor: not-allowed !important;
  }
}

.theBox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 19px;
  height: 20px;
  margin-right: 7px;
  border: 1px solid;
  border-radius: 5px;
  cursor: pointer;
}

.checkboxInput {
  position: absolute;
  width: 0;
  height: 0;
}

.label {
  display: flex;
  align-items: center;
  color: inherit;
  font-weight: $fw-medium;
  font-size: 1.2rem;
  line-height: 1.3rem;
}

.iconCheck {
  position: relative;
  width: 100%;
  height: 100%;
  color: $color-oh-so-green;
  opacity: 0;
  transition: opacity 0.3s;

  .checked & {
    opacity: 1;
  }
}

.themeDark {
  color: $color-ashy;

  .theBox {
    border-color: $color-ashy;
    background-color: $color-white;
  }
}

.themeLight {
  color: $color-white;

  .theBox {
    border-color: $color-snow-in-april;
    background-color: $color-pretty-dark;
  }
}

.themeGradientDark,
.themeGradientDarkExtended {
  color: $color-snow-in-april;

  .theBox {
    width: 23px;
    height: 25px;
    border: none;
    border-radius: 6px;
    background: linear-gradient(20deg, #262626 3.13%, #000 105.3%);
    box-shadow: inset 3px 4px 8px rgba(0, 0, 0, 0.0882);

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: inherit;
      background: linear-gradient(20deg, #000 3.13%, #323232 112.22%);
      box-shadow: 0 3px 9px rgba(0, 0, 0, 0.07);
      opacity: 0;
      transition: opacity 0.3s;
    }
  }

  .iconCheck {
    width: 14px;
    height: 14px;
  }

  &.checked {
    .theBox {
      &::before {
        opacity: 1;
      }
    }
  }
}

.themeGradientDarkExtended {
  color: $color-ashy;
}

.hasError {
  .label {
    color: $color-error;
  }

  .theBox {
    border-color: $color-error;
  }
}
