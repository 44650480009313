@import 'src/components/ModalCollaborationDeploy/ModalCollaborationDeploy.module.scss';

.background {
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px);
}

.modalAddUser {
  display: block;
  border-radius: $modalBorderRadius;
  padding-top: 4rem;
  padding-bottom: 3rem;

  h2.modalTitle {
    margin-bottom: 2rem;
  }
}
