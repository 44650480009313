@import 'src/assets/styles/utils/index.module.scss';

.imageFloating {
  width: 100%;
  height: auto;
  position: absolute;
  z-index: 13;
}

.icons {
  color: $color-snow-in-april;
}

.lightBarBlue {
  max-width: 22vh;
  height: 100vh;
  margin-bottom: 40%;
  position: relative;
}

.shadowBlue {
  width: 180%;
  height: 180%;
  top: -40%;
  left: -40%;
  filter: brightness(0.7);
  background-image: url(../../../../assets/images/LandingPage/marketDesign/layerOne_exchanges/shadown.png);
  background-size: 100%;
  background-position: center;
}

.effectShadown img:first-child {
  position: absolute;
  mix-blend-mode: color;
}

.houseBlue {
  position: relative;
  width: 100%;
  height: 100%;

  @media only screen and (max-width: 1920px) {
    padding: 30%;
  }

  @media only screen and (min-width: 1921px) {
    padding: 29%;
    width: 100vh;
  }
}

.houseBlueTooltip {
  position: absolute;
  top: 18%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 14;

  @media only screen and (min-width: 1920px) and (min-height: 1080px) {
    top: 27%;
  }
}

.solarPanel {
  left: 10%;
  top: 31%;
  max-width: 145px;
  max-height: 149px;
  width: 20%;
}

.itemsResize {
  @media only screen and (min-width: 1921px) {
    max-width: 100% !important;
    max-height: 100% !important;
    width: 10vh !important;
  }
}

.car {
  left: 5%;
  bottom: 20%;
  max-width: 146px;
  max-height: 140px;
  width: 20%;
}

.battery {
  left: 40%;
  bottom: 3%;
  max-width: 145px;
  max-height: 160px;
  width: 20%;
}

.consumption {
  right: 4%;
  bottom: 20%;
  max-width: 172px;
  max-height: 154px;
  width: 20%;
}

.diamondTooltip {
  position: absolute;
  top: 15px;
  left: 17%;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 14;

  img {
    position: relative;
    bottom: 3px;
    left: -8px;
    object-fit: contain;
  }

  @media only screen and (min-width: 1920px) and (min-height: 1080px) {
    left: 37%;
    top: 18%;
  }
}

.descriptionTooltip {
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: $fw-regular;
}

.diamondWrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 13;
}

.diamond {
  width: 35vh;
}

.singleCommunityWrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 13;
}

.barWrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 13;
}

.singleCommunity {
  position: relative;
  width: 100%;
  height: 100%;

  @media only screen and (min-width: 1921px) {
    width: 100vh;
  }
}

.waveBg {
  height: 110vh;
  width: 220vh;
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../../../../assets/images/LandingPage/marketDesign/layerOne_exchanges/ripples.png');
  background-position: right 0vh bottom -31vh;
  z-index: 1;
}

.linksCommunity {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 8%;
  margin-left: -2%;
  margin-top: -11%;

  @media only screen and (min-width: 1921px) {
    width: 100vh;
  }
}

.lineDiamondTooltip {
  position: absolute;
  top: 50px;
  left: -5%;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 14;

  @media only screen and (min-width: 1920px) and (min-height: 1080px) {
    top: 22%;
  }
}

.singleDiamondOne {
  right: 58%;
  top: 5%;
  width: 6%;
  z-index: 14;
}

.singleDiamondTwo {
  right: 76%;
  top: 19%;
  width: 6%;
  z-index: 14;
}

.singleDiamondThree {
  right: 88%;
  top: 25%;
  width: 6%;
  z-index: 14;
}

.singleDiamondFour {
  right: 68%;
  top: 42%;
  width: 6%;
  z-index: 14;
}

.singleDiamondFive {
  right: 38%;
  top: 58%;
  width: 6%;
  z-index: 14;
}

.singleDiamondSix {
  right: 6%;
  top: 48%;
  width: 6%;
  z-index: 14;
}

.singleDiamondSeven {
  right: 14%;
  top: 29%;
  width: 6%;
  z-index: 14;
}

.singleDiamondEight {
  right: 40%;
  top: 15%;
  width: 6%;
  z-index: 14;
}

.singleDiamondNine {
  right: 50%;
  top: 28%;
  width: 6%;
  z-index: 14;
}

.lineDiamond {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 9%;
  margin-left: -1%;
  margin-top: -29%;

  @media only screen and (min-width: 1921px) {
    width: 100vh;
  }
}

.ringTooltip {
  z-index: 14;
  position: absolute;
  top: 10%;
  left: 0;
  right: 0%;
  margin-left: auto;
  margin-right: auto;

  img {
    position: relative;
    left: -8px;
    top: 5px;
    width: 20px;
    height: 30px;
    object-fit: contain;
  }
}

.ring {
  left: 10%;
  top: -4%;
  width: 80%;
}

.chartDay {
  right: 0;
  top: 68%;
  width: 43%;
}

.chartTrades {
  left: 0;
  top: 72%;
  width: 55%;
}

.subtitleWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  right: 2%;
  bottom: 4%;
  width: 183px;
  z-index: 21;

  @media screen and (max-width: 1200px) {
    zoom: 0.8;
  }
}

.subtitle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: $color-ashy;
  border-radius: 5px;
  padding: 7px;
  width: 100%;
  margin-bottom: 8px;

  span {
    margin-left: 8px;
    font-family: $ff-sans-landingPage;
    color: $color-snow-in-april;
    font-weight: $fw-medium;
    font-size: 1.3rem;
    transform: translateY(15%);
  }
}
