@import 'src/assets/styles/utils/index.module.scss';

.theme-1 {
  --background: #{$color-kinda-dirty};
  --color-text: #{$color-white};
}

.theme-2 {
  --background: #{$color-oh-so-green};
  --color-text: #{$color-pretty-dark};
}

.theme-3 {
  --background: #{$color-ashy};
  --color-text: #{$color-white};
}

.theme-4 {
  --background: linear-gradient(17.86deg, #211e21 -60.33%, rgba(33, 30, 33, 0.94) 98.42%);
  --color-text: #{$color-white};
}

.tooltip {
  display: flex;
  align-items: center;
  min-height: 35px;
  padding: 5px 10px;
  border-radius: 6px;
  background: var(--background);
  color: var(--color-text);
  font-weight: $fw-medium;
  font-size: 1.3rem;
  white-space: nowrap;
  pointer-events: var(--pointer-events);

  &.wordWrap {
    white-space: normal;
  }

  strong {
    font-weight: $fw-bold;
  }
}

.tooltipFixedPos {
  position: fixed;
  z-index: $z-base-tooltip;
}

.tick {
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 2px;
  background: var(--background);
}

.trigger {
  display: inline-flex;
}

.tick-pos {
}
