@import 'src/assets/styles/utils/index.module.scss';

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: $color-metal-grey;
}

.icon {
  color: $color-snow-in-april;
  transform: rotate(-45deg);
}
