@import 'src/assets/styles/utils/index.module.scss';

.logincontainer {
  width: 100%;
  height: 100%;
  padding: 25px;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(10.13deg, #211e21 0.29%, rgba(33, 30, 33, 0.94) 99.02%);

  .loginContainer {
    background: transparent;
    max-width: 800px;
    width: 100%;
  }
}

.containerBg {
  background: linear-gradient(10.13deg, #211e21 0.29%, rgba(33, 30, 33, 0.94) 99.02%);
}

.logo {
  margin-top: 50px;
  margin-bottom: 40px;
}

.logo {
  margin-top: 50px;
  margin-bottom: 40px;
}
