@import 'src/assets/styles/utils/index.module.scss';

.background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $z-modal-auth - 1;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(57px);
}

@-moz-document url-prefix() {
  .background {
    background: rgba(255, 255, 255, 0.7);
  }
}

.headerSpacing {
  padding-top: 10rem !important;
}

.modal {
  top: 50%;
  left: 50%;
  z-index: $z-modal-auth;
  padding: 25px;
  border-radius: 6px;
  background: linear-gradient(10.13deg, #211e21 0.29%, rgba(33, 30, 33, 0.94) 99.02%);
  transform: translate3d(-50%, -50%, 0);
  overflow: hidden;

  @media (max-width: 500px) {
    top: 0%;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
    transform: unset;
  }
}

.closeBtn {
  position: absolute;
  top: 25px;
  right: 15px;
}
