@import 'src/assets/styles/utils/index.module.scss';

.carbonEmissionsTabs {
  margin-top: 20px !important;
}

footer {
  margin: 0 !important;
}

.assetInfo {
  min-height: 140px !important;
}
