@import 'src/assets/styles/utils/index.module.scss';

$modalBorderRadius: 6px;

.background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $z-modal-auth - 1;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(24px);
}

@-moz-document url-prefix() {
  .background {
    background: rgba(255, 255, 255, 0.7);
  }
}

.modal {
  top: 50%;
  left: 50%;
  z-index: $z-modal-auth;
  border-radius: 6px;
  background: $color-ashy;
  transform: translate3d(-50%, -50%, 0);
}

h2.modalTitle {
  margin-bottom: 5px;
  color: $color-snow-in-april;
  font-weight: $fw-bold;
  font-size: 3rem;
  line-height: 3rem;
  text-align: center;
}

.modalWrapper {
  padding-top: 3.4rem;
  padding-bottom: 3.4rem;

  .stepsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 2.5rem;
  }
}

.closeBtn {
  position: absolute;
  top: 16px;
  right: 16px;
}

.buttonsWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.modalContentWrapper {
  background: $color-pretty-dark;
  border-bottom-left-radius: $modalBorderRadius;
  border-bottom-right-radius: $modalBorderRadius;
  padding: 2rem 3rem 2rem 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin-bottom: 32px;
    color: $color-metal-grey;
    font-size: 1.6rem;
    text-align: center;
  }

  .inputArea {
    width: 50%;
    position: relative;
    .apiInput {
      label {
        color: $color-metal-grey;
      }
    }
    .checkmarkBox {
      border: 2px solid $color-oh-so-green;
      border-radius: 1rem;
      width: 2rem;
      height: 2rem;
      position: absolute;
      top: 6px;
      right: 0px;
      svg {
        color: $color-oh-so-green;
        width: 1.7rem;
        height: 1.7rem;
        stroke: $color-oh-so-green;
        stroke-width: 1px;
      }
    }
  }

  .plainLink {
    color: $color-oh-so-green;
    margin-top: 2rem;
    font-size: 1.2rem;
  }
  .buttonConnect {
    width: 100%;
    margin-top: 2rem;
  }
}
