@import 'src/assets/styles/utils/index.module.scss';

.container {
  z-index: $z-burger-navigation;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 240px;
  height: 100%;
  padding: 100px 26px 26px;
  background-color: rgba($color-white, 0.7);
  transition: transform 0.3s;
  backdrop-filter: blur(18px);
}

.closed {
  transform: translateX(-100%);
}

.burgerLinks {
  display: flex;
  flex-direction: column;
}

.burgerNavLink {
  font-size: 1.6rem;
  line-height: 1.7rem;

  &:not(:last-child) {
    margin-bottom: 40px;
  }
}

.titleSocialMedia {
  font-size: 1.8rem;
  color: #{$color-ashy};
  line-height: 1.7rem;
  margin-bottom: 27px;
  font-weight: $fw-bold;
}

.socialLink {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 40px;
  }
}

.socialIcon {
  margin-right: 20px;
}
