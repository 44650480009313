html {
  box-sizing: border-box;
}

* {
  border-radius: 0;

  &,
  &::after,
  &::before {
    box-sizing: inherit;
  }
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  vertical-align: baseline;
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
}

body {
  line-height: 1;
}

strong,
b {
  font-weight: 600;
}

em,
i {
  font-style: italic;
}

ol,
ul {
  list-style: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

a,
button {
  cursor: pointer;
}

button,
input,
textarea,
select,
option {
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  color: currentColor;
  box-shadow: none;
  font: inherit;
  appearance: none;

  &:focus,
  &:active {
    outline: none;
  }
}

a {
  color: inherit;
  font-family: inherit;
  text-decoration: none;

  &:focus,
  &:hover,
  &:active {
    text-decoration: none;
  }
}

img,
picture,
svg {
  vertical-align: middle;
  max-width: 100%;
}

svg {
  fill: currentColor;
}
