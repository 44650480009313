@import 'src/assets/styles/utils/index.module.scss';

.loading {
  width: 100%;
}

.notificationsBtn {
  position: absolute;
  top: 11px;
  right: 133px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 1px;
  border-radius: 4px;
}

.notificationsBtn:hover {
  background-color: $color-kinda-dirty;
}

.AskForHelp {
  position: absolute;
  top: 11px;
  right: 13px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  width: fit-content;
  // height: 22px;
  padding: 5px 0 5px 5px;
  border-radius: 4px;
  background-color: $color-ashy;
  color: $color-white;
  font-weight: bold;
  letter-spacing: 0.6px;
}

.AskForHelp:hover {
  background-color: $color-kinda-dirty;
}

.assetList ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  margin-right: -7px;
  margin-bottom: -7px;
}

.assetList li {
  /* position: absolute; */
  /* top: 0; */
  /* left: 0; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 110px;
  /* width: 100%; */
  /* height: 100%; */
  padding: 16px 5px 5px;
  padding: 37px;
  border-radius: 4px;
  background-color: $color-ashy;
  color: $color-snow-in-april;
  text-align: center;
}

.helpDescription {
  width: 220px;
  margin-top: -5px;
  color: $color-metal-grey;
  font-size: 11px;
  line-height: 16px;
}

.cancelButton {
  position: absolute;
  top: 11px;
  right: 13px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 62px;
  height: 22px;
  border-radius: 8%;
  background-color: #a0a0ac;
  color: #211e21;
  font-weight: bold;
}

.cancelButton:hover {
  position: absolute;
  top: 11px;
  right: 13px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 62px;
  height: 22px;
  border-radius: 8%;
  background-color: rgb(121, 118, 118);
  color: #211e21;
  font-weight: bold;
}

.modal {
  position: relative;
  min-width: 350px;
  // border-radius: 8px;
  // background: linear-gradient(10.13deg, #211e21 0.29%, rgba(33, 30, 33, 0.94) 99.02%);
  // backdrop-filter: blur(20.3526px);

  .section {
    margin-bottom: 10px;
    padding: 15px 12px;
    border-radius: 8px;
    background: linear-gradient(10.13deg, #211e21 0.29%, rgba(33, 30, 33, 0.94) 99.02%);
    backdrop-filter: blur(20.3526px);

    .lightContainer {
      padding: 15px;
      border-radius: 3px;
      background: $color-ashy;
    }
  }

  .closeButton {
    position: absolute;
    top: 9px;
    right: 10px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background-color: $color-snow-in-april;
    color: $color-metal-grey;
  }

  h4 {
    margin-bottom: 12px;
    color: $color-snow-in-april;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 19px;
  }

  h6 {
    margin-bottom: 12px;
    color: $color-snow-in-april;
    font-size: 1.3rem;
    line-height: 1.5rem;
  }

  p {
    margin-bottom: 12px;
    color: $color-snow-in-april;
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 1.4rem;
  }
}

.info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.randomContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 14px;
  cursor: pointer;

  svg {
    margin-right: 12px;
    color: $color-snow-in-april;
  }
}

.addingFriends {
  height: 35px;
  margin: 1px 0 7px;
  padding: 7px 9px 1px;
  border: #6e6 1.3px solid;
  border-radius: 6px;
  background-color: #211e21;
  color: white;
  font-size: 12px;
  text-align: left;
}

.addingFriends p {
  vertical-align: middle;
  line-height: 1.5;
}

.addingMore {
  float: left;
  width: 17px;
  height: 17px;
  margin-top: -1px;
  margin-right: 9px;
  color: yellow;
}

.searchInput {
  width: 100%;

  svg {
    color: $color-black;
  }
}

.stepStateContainer {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  h4 {
    margin-bottom: 0;
  }

  .stepState {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin-right: 16px;
    border-radius: 4px;
    background: $color-snow-in-april;
    color: $color-black;
    box-shadow: 0 0 5px 2px rgba(251, 249, 249, 0.5);
    font-weight: bold;
    font-size: 16px;
    text-align: center;
  }
}

.authButton {
  width: 100%;

  &:first-child {
    margin-right: 10px;
  }
}

.runSimulationButton {
  width: 100%;
}

.WZrunPauseBtn {
  width: 100%;
}

.pagination {
  @keyframes pulse {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  button {
    color: $color-snow-in-april;
  }

  .stepsContainer {
    display: flex;
    align-items: center;
  }

  .stepButton {
    width: 30px;
    height: 30px;
    margin-right: 5px;
    border: 1px solid $color-ashy;
    background: $color-modal-bg;

    &:last-child {
      margin-right: 0;
    }
  }

  .activeStep {
    background: $color-ashy;
    color: $color-snow-in-april;
  }

  .nextStepButton {
    height: 29px;
    background: $color-oh-so-green;
    color: $color-pretty-dark;
    font-size: 14px;

    svg {
      position: relative;
      // top: 1px;
      right: -8px;
    }
  }

  .nextStepButton:hover {
    background: #4cd94c;
  }

  .nextIcon {
    transform: rotate(180deg);
  }

  .prevStepButton {
    height: 29px;
    border: 1.5px solid $color-kinda-dirty;
    background: $color-modal-bg;
    color: $color-snow-in-april;
    font-size: 14px;

    svg {
      position: relative;
      // top: 1px;
      right: -2;
    }
  }

  .prevStepButton:hover {
    background: #1c1a1c;
  }
}
