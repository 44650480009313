@import 'src/assets/styles/utils/index.module.scss';

.container {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  h3 {
    font-size: 18px;
    font-weight: 500;
    color: $color-ashy;
  }
  .invitationsList {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    padding-bottom: 28px;
    border-bottom: thin solid $color-vape;
    .invitation {
      width: 100%;
      height: 50px;
      background-color: $color-vape;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid $color-vape;
      margin-bottom: 7px;
      padding: 0 8px;
      .avatar {
        width: 32px;
        height: 32px;
        border-radius: 100%;
        background-size: cover;
        border: 1px dashed $color-metal-grey;
        margin-right: 8px;
        flex-shrink: 0;
      }
      p {
        flex: 1;
      }
      .roleSelector {
        width: 120px;
        margin-right: 16px;
      }
      .tag {
        margin-right: 16px;
        padding: 3px 10px;
        background-color: $color-ace-blue;
        border-radius: 9px;
        font-size: 11px;
        line-height: 12px;
        font-weight: 300;
        letter-spacing: 0.04rem;
        color: $color-snow-in-april;
        font-family: $ff-sans-landingPage;
      }
    }
  }
  .addMore {
    padding-top: 28px;
    & > div {
      width: 100%;
      display: flex;
      margin-top: 14px;
      .input {
        flex: 1;
        margin-right: 10px;
      }
    }
  }
  footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
    svg {
      color: $color-oh-so-green;
    }
    button {
      width: 100%;
    }
    .cancelBtn {
      padding: 15px;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
    }
  }
}
