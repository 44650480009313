@import 'src/assets/styles/utils/index.module.scss';

.main {
  z-index: $z-modal-auth;
  border-radius: 6px;
  background: linear-gradient(10.13deg, #211E21 0.29%, rgba(33, 30, 33, 0.94) 99.02%);
}

.closeBtn {
  position: absolute;
  top: 16px;
  right: 16px;
}

.wrapper {
  padding: 16px;

  .header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 16px;
  }
  
  .status {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  
  h3 {
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: $color-snow-in-april;
    font-weight: $fw-bold;
    text-align: left;
  }
  
  .yourSimulation {
    font-size: 1.2rem;
    line-height: 1.4rem;
    color: $color-snow-in-april;
    font-weight: $fw-regular;
    text-align: left;
  }

  .textStatusGreen {
    font-size: 1.2rem;
    line-height: 1.4rem;
    color: $color-oh-so-green;
    font-weight: $fw-regular;
    text-align: left;
  }

  .textStatusPink {
    font-size: 1.2rem;
    line-height: 1.4rem;
    color: $color-soft-singularity-red;
    font-weight: $fw-regular;
    text-align: left;
  }

  .description {
    font-size: 1rem;
    line-height: 1.4rem;
    color: $color-white;
    font-weight: $fw-regular;
    text-align: left;
    margin-top: 2px;
  }

  .greenText {
    color: $color-oh-so-green;
  }

  .textLink {
    font-size: 1.25rem;
    line-height: 1.4rem;
    color: $color-metal-grey;
    font-weight: $fw-regular;
    text-align: left;
    margin-top: 16px;
  }

  .buttonGetLink {
    display: flex;
    flex-direction:row;
    margin-top: 16px;
  }

  .buttonCopyLink {
    color: $color-oh-so-green;
    margin-right: 12px;
    font-weight: $fw-regular;

    &:disabled {
      background-color: $color-ashy ;
      opacity: 0.5;
      color: $color-oh-so-green;
    }
  }

  .buttonEmbed {
    color: $color-oh-so-green;
    font-weight: $fw-regular;

    &:disabled {
      background-color: $color-ashy ;
      opacity: 0.5;
      color: $color-oh-so-green;
    }
  }

  .buttonModal {
    display: flex;
    flex-direction: row;
  }

  .linkCopiedBox{
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
    border: 1pt solid $color-oh-so-green;
    border-radius: 3px;
    padding: 0 8px 0 8px;
    height: 24px;
    
    span {
      font-size: 1rem;
      line-height: 1.4rem;
      color: $color-oh-so-green;
      font-weight: $fw-regular;
      text-align: left;
    }
    
    .icon {
      color: $color-oh-so-green;
    }
  }

  .iframeBox {
    font-size: 1rem;
    line-height: 1.4rem;
    color: $color-metal-grey;
    font-weight: $fw-regular;
    text-align: left;
    margin-top: 16px;
  }
}

.footer {
  background: linear-gradient(0deg, #211E21 50%, rgba(39, 39, 39, 0.94) 100%);
  max-width: 320px;
  margin-left: 0;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  padding: 16px;

  .footerTitle{
    font-size: 1.25rem;
    line-height: 1.4rem;
    color: $color-metal-grey;
    font-weight: $fw-regular;
    text-align: left;
    margin-top: 16px;
  }
}

.circleIcons {
  border-radius: 50%;
  width: 35px;
  height: 35px;
  background-color: $color-ashy;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 14px;
  margin-top: 16px;
  color: var(--color-text);
  --color-text: #a0a0ac;
  --color-text-active: #a0a0ac;

  &:disabled {
    opacity: 0.5;
  }
}

.textDisabled {
  opacity: 0.5;
}

