@import 'src/assets/styles/utils/index.module.scss';

.list {
  display: flex;
  flex-direction: column;
  width: var(--width);
}

.item {
  width: 100%;
}
