@import 'src/assets/styles/utils/index.module.scss';

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: $color-ashy;
  width: 100%;
}

.containerWrapper {
  display: flex;
  flex: 1;
  height: 100vh;

  @media only screen and (min-width: 300px) and (max-width: 900px) {
    flex-direction: column;
  }
}

.left {
  display: flex;
  flex: 1;
  height: 100vh;
  max-width: 50%;
  flex-direction: column;
  position: relative;


  @media only screen and (min-width: 300px) and (max-width: 900px) {
    max-width: 100%;
    align-items: flex-start;
    flex: 0;
  }
}

.right {
  display: flex;
  flex: 1;
  height: 100vh;
  max-width: 50%;
  background-color: $color-pretty-dark;
  flex-direction: column;
  position: relative;

  @media only screen and (min-width: 300px) and (max-width: 900px) {
    max-width: 100%;
    padding-top: 0px;
    height: unset;
  }
}

.bgRightHeader {
  background-color: #34363f;
  min-height: 60px;
  width: 100%;

  @media only screen and (max-width: 600px) {
    display: none;
  }
}

.bgLeftHeader {
  background-color: $color-ashy;
  min-height: 60px;
  width: 100%;
  z-index: 2;
}

.bgDark {
  background-color: $color-ashy;
}

.backAction {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
  right: 30px;
  z-index: 999;
  bottom: 2%;
  cursor: pointer;
  color: $color-snow-in-april;
  transition: filter 0.2s;
  &:hover {
    filter: brightness(0.7);
  }

  span {
    font-family: $ff-sans-landingPage;
    font-weight: $fw-medium; 
    letter-spacing: 2px;
    font-size: 1.5rem;
    text-transform: uppercase;
    margin-right: 38px;
  }
}
