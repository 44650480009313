@import 'src/assets/styles/utils/index.module.scss';

.svg {
  display: block;
  max-width: none;
}

.clickable {
  cursor: pointer;
}
