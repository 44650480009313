@import 'src/assets/styles/utils/index.module.scss';

.container {
}

.supremeContainer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  display: block;
  overflow: auto;
  width: 100%;
  height: 100%;
  padding-top: 100px;
  background-color: rgba(61, 61, 61, 0.4);
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px);
  -webkit-transition: 0.5s;
  transition: all 0.3s linear;
  backdrop-filter: grayscale(0.5) opacity(0.8) /* ...and on and on... */;
}
