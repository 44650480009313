@import 'src/assets/styles/utils/index.module.scss';

.imageFloating {
  max-height: 100vh;
  position: absolute;
  z-index: 13;
}

.waveBackground {
  height: 100%;
  max-width: 100%;
  background-image: url('../../../../assets/images/LandingPage/marketDesign/layerOne_exchanges/ripples.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left 50vh bottom 80%;
}

.basePlaqueApi {
  right: 4%;
  top: 53%;
  width: 100%;
}

.linesPlaqueApi {
  right: 12%;
  top: 36%;
  width: 78%;
}

.squareOnePlaqueApi {
  right: 6%;
  top: 50%;
  width: 90%;
}

.apiStyleOneApi {
  right: 81%;
  top: 76%;
  width: 30%;
}

.middlePlaqueApi {
  right: 4%;
  top: 27%;
  width: 100%;
}

.linksPlaqueApi {
  right: 21%;
  top: 2%;
  width: 57%;
}

.squareTwoPlaqueApi {
  right: 6%;
  top: 18%;
  width: 90%;
}

.apiStyleTwoApi {
  right: 82.5%;
  top: 34%;
  width: 30%;
}

.topPlaqueApi {
  right: 4%;
  top: -20%;
  width: 100%;
}

.squareThreePlaqueApi {
  right: 6%;
  top: -8%;
  width: 90%;
}

.apiStyleThreeApi {
  right: 82.5%;
  top: 8%;
  width: 30%;
}

.subtitleWrapper {
  position: absolute;
  right: 2%;
  bottom: 4%;
  width: 183px;
  z-index: 21;

  @media screen and (max-width: 1200px) {
    zoom: 0.8;
  }
}

.subtitle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: $color-ashy;
  border-radius: 5px;
  padding: 7px;
  width: 100%;
  margin-bottom: 8px;

  span {
    margin-left: 8px;
    font-family: $ff-sans-landingPage;
    color: $color-snow-in-april;
    font-weight: $fw-medium;
    font-size: 1.3rem;
    transform: translateY(15%);
  }
}

.communityTooltip {
  position: absolute;
  top: 4%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 14;

  img {
    position: relative;
    left: -8px;
    object-fit: contain;
  }

  @media only screen and (min-width: 1920px) and (min-height: 1080px) {
    top: 14%;
  }
}

.singleCommunityApiWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.singleCommunityApi {
  position: relative;
  width: 100%;
  height: 100%;

  @media only screen and (min-width: 1921px) {
    width: 100vh;
  }
}

.apiCommunityApi {
  right: 4%;
  top: 10%;
  width: 95%;
}

.ringApi {
  left: 10%;
  top: -4%;
  width: 80%;
}

.linkBaseApi {
  position: absolute;
  width: 75%;
  top: 20%;
  left: -5%;
  right: 0%;
  margin-left: auto;
  margin-right: auto;

  // @media only screen and (min-width: 1921px) {
  //   width: 100vh;
  // }
}

.linkApi {
  right: 44%;
  bottom: 65%;
  width: 9%;
  height: 56%;
}
