@import 'src/assets/styles/utils/index.module.scss';

.imageFloating {
  max-height: 100vh;
  position: absolute;
  z-index: 13;
}

.grayBox {
  position: absolute;
  background-color: $color-ashy;
  max-width: 255px;
  width: 100%;
  border-radius: 2px;
  bottom: 0%;
  left: -50vh;
  margin-left: auto;
  margin-right: auto;
  z-index: 14;

  p {
    font-size: 1.4rem;
    line-height: 2.4rem;

    a {
      cursor: pointer;
      border-bottom: 2px solid $color-snow-in-april;
    }
  }

  @media only screen and (min-width: 2547px) {
    left: -30vh;
    p {
      font-size: 1.9rem;
      line-height: 3rem;
    }
  }
}

.grayBoxText {
  margin: 6%;
  font-family: $ff-sans-landingPage;
  font-weight: $fw-regular;
  color: $color-snow-in-april;
}

.simulationWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.levelSimulation > div {
  z-index: 15;
}

.levelSimulation > div:first-child {
  z-index: 16;
}

.graycCommunity {
  position: relative;
  width: 95%;
  z-index: 14;
  margin-bottom: 10%;
}

.lightBarBlue {
  bottom: 20%;
  width: 20%;
  height: 100vh;
  z-index: 25;
  margin-bottom: 10%;
  z-index: 14;
}

.shadownBlue {
  position: relative;
  width: 100%;
  filter: brightness(0.4);
  margin-bottom: 10%;
}

.house {
  width: 30%;
  z-index: 14;
  position: relative;
  display: flex;
  margin-bottom: 10%;
}

.boxTitleCommSimulation {
  width: 80%;
  z-index: 15;
  bottom: 25%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.boxCreateCommSimulation {
  width: 80%;
  z-index: 15;
  bottom: -3%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.tooltipSimulation {
  top: 20%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 25%;
  z-index: 15;
}

.mobileScreen {
  width: 85%;
  z-index: 15;
  position: relative;
}

.addHouse {
  left: -20%;
  top: 12%;
  width: 45%;
  z-index: 15;
}

.singleLink {
  right: 32%;
  top: 55%;
  width: 12%;
  z-index: 15;
}

.lightBarBlueTwo {
  bottom: 5%;
  width: 20%;
  height: 100vh;
  z-index: 25;
  left: 50%;
  margin-bottom: 10%;
}

.links {
  position: relative;
  padding: 9%;
  margin-left: -1%;
  margin-top: -18%;
  z-index: 15;

  @media only screen and (min-width: 1921px) {
    width: 100vh;
  }
}

.chartNetEnergy {
  left: -3%;
  top: -4%;
  width: 30%;
  z-index: 16;
}

.netEnergy {
  left: -20%;
  top: -10%;
  width: 50%;
  z-index: 15;
}

.assets {
  left: -15%;
  top: 34%;
  width: 50%;
  z-index: 15;
}

.chartEnergyTrade {
  left: -20%;
  top: 60%;
  width: 50%;
  z-index: 15;
}

.simulationWrapperBox {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: -19%;
  left: 0px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: row;

  @media screen and (max-width: 1200px) {
    width: 80%;
    height: 80%;
    bottom: 8%;
    left: 90px;
  }

  @media only screen and (max-width: 1920px) {
    bottom: -32%;
  }
}

.simulationBoxContent {
  position: absolute;
  z-index: 21;
  width: 85%;
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 1920px) {
    width: 120%;
    height: 23%;
  }

  > div {
    height: 100%;
    display: flex;
    flex: 1;
    width: 100%;

    &:first-child {
      height: unset;
      max-width: 30%;
    }
    &:last-child {
      max-width: 80%;
    }
  }
}

.triangle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-right: 10px;
  background-color: $color-ashy;
  border-radius: 5px;

  img {
    width: 40%;
    padding: 5%;

    @media only screen and (max-width: 1920px) {
      width: 60%;
    }
  }
}

.triangle_2 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-right: 10px;
  background-color: $color-pretty-dark;
  border-radius: 5px;

  img {
    width: 40%;

    @media only screen and (max-width: 1920px) {
      width: 60%;
    }
  }
}

.boxSimulation {
  background-color: $color-ashy;
  width: 100%;
  max-width: 100%;
  border-radius: 6px;
  padding: 12px;
  display: flex;
  flex-direction: column;

  .boxTitle {
    color: $color-snow-in-april;
    font-size: 2rem;
    letter-spacing: 1px;
    font-weight: $fw-medium;
  }

  .simulation {
    display: flex;
    justify-content: space-between;
    height: 100%;
    margin-top: 2%;

    button {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: center;
      background-color: $color-pretty-dark;
      border-radius: 5px;
      max-height: 100%;
      padding: 12px;
      color: $color-metal-grey;
      opacity: 0.8;
      font-weight: $fw-medium;
      letter-spacing: 1px;
      margin-right: 10px;

      &:last-child {
        margin-right: 0px;
      }

      span {
        color: $color-snow-in-april;
        opacity: 0.4;
        font-size: 1.2rem;
        letter-spacing: 1px;
        font-weight: $fw-medium;
        margin-top: 3px;
      }
    }

    .button {
      display: flex;
      flex-direction: column;
    }

    .actived {
      background-color: $color-pretty-dark;
      opacity: 1;
      border: 1pt solid $color-snow-in-april;
      color: $color-snow-in-april;
    }

    .activedBtnSimulation_1 {
      background-color: $color-pretty-dark;
      opacity: 1;
      border: 1pt solid $color-singularity-red;
      color: $color-snow-in-april;
    }

    .activedBtnSimulation_2 {
      background-color: $color-pretty-dark;
      opacity: 1;
      border: 1pt solid $color-oh-so-green;
      color: $color-snow-in-april;
    }

    .activedBtnSimulation_3 {
      background-color: $color-pretty-dark;
      opacity: 1;
      border: 1pt solid $color-ace-blue;
      color: $color-snow-in-april;
    }
  }
}
