@import 'src/assets/styles/utils/index.module.scss';

.container {
  position: fixed;
  top: 100px;
  left: 56px;
  z-index: $z-breadcrumbs;
  display: flex;
  align-items: center;
  height: 60px;
  padding-left: 12px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: $color-white;
  transition: 0.3s;
}

.containerEmbed {
  top: 30px;
}

.controls {
  top: 10px;

  button {
    height: 40px;
  }
}

.closed {
  transform: translateX(-100%);

  .controls {
    background-color: $color-pretty-dark;

    svg {
      color: $color-oh-so-green;
      transform: rotate(180deg);
    }
  }
}
