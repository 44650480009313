@import 'src/assets/styles/utils/index.module.scss';

.container {

}

.baseBanner {
  display: flex;
  align-items: center;
  margin: 14px 0;
  padding: 12px 10px;
  border-radius: 10px;
  background: $color-black;
  color: $color-snow-in-april;

  p {
    color: $color-vape;
  }

  p.bannerHeading {
    margin-bottom: 2px;
    color: $color-snow-in-april;
    font-weight: bold;
    font-size: 14px;
  }

  .iconWrapper {
    margin-right: 10px;
    padding: 6px;
    border-radius: 5px;
    background: $color-ace-blue;
  }

  .text {
    flex: 1;
  }

  .bannerCTA {
    button {
      padding: 0 10px;
    }
  }
}
