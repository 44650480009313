@import 'src/assets/styles/utils/index.module.scss';

.container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: $z-modal;
  width: var(--container-width);
  transform: translate3d(0, 0, 0); // Fixes blurry content on Chrome
}

.modal-anchor {
  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    width: var(--anchor-width);
    height: var(--anchor-width);
  }

  &.modal--top::after {
    top: 100%;
    left: calc(var(--anchor-position) - var(--anchor-width) / 2);
    clip-path: polygon(0% 0%, 50% 100%, 100% 0%);
  }

  &.modal--right::after {
    top: calc(var(--anchor-position) - var(--anchor-width) / 2);
    left: calc(0% - var(--anchor-width));
    clip-path: polygon(0% 50%, 100% 100%, 100% 0%);
  }

  &.modal--bottom::after {
    top: calc(0% - var(--anchor-width));
    left: calc(var(--anchor-position) - var(--anchor-width) / 2);
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  }

  &.modal--left::after {
    top: calc(var(--anchor-position) - var(--anchor-width) / 2);
    left: 100%;
    clip-path: polygon(0% 0%, 100% 50%, 0% 100%);
  }

  &.modal-theme--dark::after {
    background-color: $color-pretty-dark;
  }

  &.modal-theme--light::after {
    background-color: $color-snow-in-april;
  }
}
