@import 'src/assets/styles/utils/index.module.scss';

.modal {
  position: relative;
  padding: 15px 12px;
  border-radius: 8px;
  background: linear-gradient(10.13deg, #211e21 0.29%, rgba(33, 30, 33, 0.94) 99.02%);
  backdrop-filter: blur(20.3526px);

  .closeButton {
    position: absolute;
    top: 9px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background-color: $color-metal-grey;
    color: $color-snow-in-april;
  }

  h4 {
    height: 36px;
    margin-bottom: 6px;
    color: $color-snow-in-april;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 1.9rem;
  }

  h6 {
    color: $color-snow-in-april;
    font-size: 1.3rem;
    line-height: 1.5rem;
  }

  p {
    margin-bottom: 18px;
    color: $color-metal-grey;
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 1.4rem;
  }
}

.info {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 11px;

  svg {
    margin-right: 21px;
    color: $color-oh-so-green;
  }
}

.separator {
  display: flex;
  align-items: center;
  margin: 11px 0;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    flex-grow: 1;
    height: 1px;
    background-color: $color-kinda-dirty;
  }
}

.separatorText {
  margin: 0 10px;
  color: $color-snow-in-april;
  font-weight: $fw-medium;
  font-size: 1.3rem;
  line-height: 1.4rem;
}

.randomContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 14px;
  cursor: pointer;

  svg {
    margin-right: 12px;
    color: $color-snow-in-april;
  }
}

.searchInput {
  margin-bottom: 11px;

  svg {
    color: $color-black;
  }
}
