@import 'src/assets/styles/utils/index.module.scss';

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

@media only screen and (max-width: 768px) {
  .container {
    width: 100%;
  }
}
