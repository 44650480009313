@import 'src/assets/styles/utils/index.module.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  z-index: 25;
  width: 100vw;
  left: 50%;
  top: $header-height + 15px;
  transform: translate(-50%, 0);
}

.doc {
  min-width: 60px;
  min-height: 6px;
  background-color: $color-ashy;
  border-radius: 21px;
}

.content {
  // background-color: #eee;
  min-width: 100vw;
}
