@import 'src/assets/styles/utils/index.module.scss';

.background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $z-modal-auth - 1;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(24px);
}

@-moz-document url-prefix() {
  .background {
    background: rgba(255, 255, 255, 0.7);
  }
}

.modal {
  top: 50%;
  left: 50%;
  z-index: $z-modal-auth;
  border-radius: 6px;
  background: linear-gradient(10.13deg, #211e21 0.29%, rgba(33, 30, 33, 0.94) 99.02%);
  transform: translate3d(-50%, -50%, 0);
}

.modalWrapper {
  padding: 28px 16px 16px;

  h2 {
    margin-bottom: 5px;
    color: $color-snow-in-april;
    font-weight: $fw-medium;
    font-size: 2.2rem;
    line-height: 2.6rem;
  }

  p {
    margin-bottom: 32px;
    color: $color-london-fog;
    font-size: 1.4rem;
    line-height: 1.6rem;
  }
}

.closeBtn {
  position: absolute;
  top: 16px;
  right: 16px;
}

.buttonsWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.back,
.finish {
  width: calc(50% - 5px);
}

.selectScroll {
  max-height: 250px;
}
