@import 'src/assets/styles/utils/index.module.scss';

.copyrightContainer {
  display: flex;
  align-items: center;

  &.phoenix {
    margin: 0 45px 10px 45px;
    position: absolute;
    bottom: 0;
    z-index: 1000;
  }

  &.scm {
    margin: 0 30px 0 30px;
  }

  .copyrightText {
    font-family: $ff-sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: $color-metal-grey;
    margin-right: 20px;

    @media only screen and (max-width: 900px) {
      font-size: 12px;
    }
  }

  .privacyPolicyLink {
    font-family: $ff-sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    cursor: pointer;

    @media only screen and (max-width: 900px) {
      font-size: 12px;
      text-wrap: nowrap;
    }

    &.scm {
      color: $color-snow-in-april;
    }

    &.phoenix {
      color: $color-metal-grey;
    }
  }

  .termsOfService {
    @extend .privacyPolicyLink;
    margin-left: 20px;

    &.scm {
      color: $color-snow-in-april;
    }

    &.phoenix {
      color: $color-metal-grey;
    }
  }
}
