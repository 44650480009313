@import 'src/assets/styles/utils/index.module.scss';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: 100%;
  background-color: $color-ashy;
  z-index: 1;

  .closeBtn {
    position: absolute;
    right: 10px;
    margin: 0;
    width: 22px;
    height: 22px;
  }

  .arrowButton {
    margin: 0 8px;
  }

  & > button {
    border: none;
    box-shadow: unset;
  }

  .stepContainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-width: 170px;
    border-left: 1px solid $color-metal-grey;
    border-right: 1px solid $color-metal-grey;
    color: $color-snow-in-april;

    & svg {
      color: $color-burn-my-eyes;
    }

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 4px;
      bottom: 0;
      background: $color-vape;
      opacity: 0.1;
    }

    .textTab {
      padding: 4px;
    }

    &.covered {
      &::after {
        opacity: 1;
        background: $color-burn-my-eyes;
      }
    }

    &.active {
      .textTab {
        border-radius: 3px;
        background: $color-burn-my-eyes;
        color: $color-ashy;
      }
    }
  }
}
