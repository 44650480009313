@import 'src/assets/styles/utils/index.module.scss';

.container {
  display: grid;
  grid-template-areas:
    'Percentages Graph Controls'
    'Details Details Controls';
  grid-template-rows: 72px 1fr;
  grid-template-columns: 90px auto 42px;
  width: 100%;
  height: 100%;
}

.percentages {
  display: flex;
  flex-direction: column;
  grid-area: Percentages;
  padding-top: 10px;
  padding-right: 10px;

  span:first-of-type {
    margin-bottom: 10px;
    font-size: 1.3rem;
  }

  span:last-of-type {
    font-weight: 600;
    font-size: 1.7rem;
  }
}

.graph {
  grid-area: Graph;
}

.controls {
  display: flex;
  flex-direction: column;
  grid-area: Controls;
  align-items: flex-end;

  & > * {
    margin-bottom: 4px;
  }
}

.details {
  display: flex;
  grid-area: Details;
  justify-content: center;
  padding-top: 5px;
  padding-right: 20px;

  .detailsItem {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33%;

    .detailsItemIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 29px;
      height: 29px;
      border: 1px solid $color-black;
      border-radius: 8px;
    }

    .detailsItemContent {
      display: flex;
      flex-direction: column;
      margin-left: 7px;

      & > span {
        font-weight: bold;
        font-size: 1.2rem;
        letter-spacing: 1.5px;
        text-transform: uppercase;
      }

      & > div {
        span:first-of-type {
          padding-right: 5px;
          font-weight: bold;
          font-size: 1.8rem;
        }
      }
    }
  }
}
