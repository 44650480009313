@import 'src/assets/styles/utils/index.module.scss';

.theme-dark {
  --color-icon: #{$color-metal-grey};
  --color-title: #{$color-snow-in-april};
  --color-subtitle: #{$color-vape};
  --color-background: #{$color-ashy};
}

.theme-light {
  --color-icon: #{$color-metal-grey};
  --color-title: #{$color-ashy};
  --color-subtitle: #{$color-kinda-dirty};
  --color-background: #{$color-vape};
}

.container {
  background: var(--color-background);
}

.topLeftComponent {
  padding-top: 7px;
  padding-left: 13px;
  float: left;
}

.bottomRight {
  position: absolute;
  bottom: 125px;
  right: 10px;
}

.blockOuter {
  position: relative;
}

.block {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 5px;
  padding-top: 16px;
  border-radius: 4px;
  text-align: center;
}

.detailsSize {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  min-height: 40px;
}

.title {
  color: var(--color-title);
  font-weight: $fw-medium;
  font-size: 1.2rem;
  line-height: 1.2rem;
}

.subtitle {
  margin-top: 5px;
  color: var(--color-subtitle);
  font-weight: $fw-medium;
  font-size: 0.9rem;
}

.dragButton {
  position: absolute;
  top: 4.5px;
  right: 4.5px;
  cursor: move;
}

.dragIcon {
  width: 10px;
  height: 10px;
  color: var(--color-icon);
}

.infoButton {
  position: absolute !important;
  top: 6px;
  left: 5px;
}

.infoIcon {
  width: 8px;
  height: 8px;
  color: var(--color-icon);
}

.mainIcon {
  width: 100%;
  padding: 0 10px;
}

.threeDots {
  position: absolute;
  top: 5px;
  right: 5px;
}

.rightIcon {
  position: absolute;
  top: 5px;
  right: 5px;
  background: $color-oh-so-green;
  border-radius: 2px;
  width: 14px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: $color-ashy;
  }
}
