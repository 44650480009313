@import 'src/assets/styles/utils/index.module.scss';

.container {
  background: #fbf9f9;
  /* GSy New Brand/BurnMyEyes */

  border: 1px solid $color-burn-my-eyes;
  box-sizing: border-box;
  /* Shadow Card Background */

  box-shadow: 0px -4px 4px rgba(255, 255, 255, 0.25), 10px 10px 20px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  padding: 16px 14px;

  .keyResultsTabs {
    margin-top: 28px;

    button {
      margin-left: 20px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-family: $ff-sans;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;

      display: flex;
      align-items: center;
      color: $color-ashy;
    }

    .toggle {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 52px;
      height: 21px;
      background: $color-white;
      border-radius: 5px;

      span {
        margin-right: 5px;
      }
    }
  }
}

.block {
  border-radius: 5px;
  background-color: $color-vape;
}

.assetInfo {
  position: relative;
  width: 100%;
  margin-bottom: 10px;
  padding: 18px 15px;
  min-height: 165px;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .earningStatus {
      color: $color-ashy;
      font-weight: 500;
      font-size: 16px;
      line-height: 14px;
    }

    .monthlyTag {
      height: 6px;
      font-family: $ff-sans;
      font-weight: 500;
      font-size: 9px;
      line-height: 18px;
      color: $color-ace-blue;
      display: flex;
      align-items: center;
      text-align: center;
      border: 1px solid $color-ace-blue;
      padding: 8px;
      border-radius: 5px;
    }
  }

  footer {
    .footerText {
      font-family: Roboto;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: $color-ashy;
      mix-blend-mode: normal;
      opacity: 0.6;
    }
  }
}

.cost {
  margin: 10px 0;
  color: $color-ashy;
  font-family: 'NB Akademie Std', sans-serif;
  font-weight: bold;
  font-size: 30px;
  line-height: 32px;

  svg {
    margin: 0 4px 0 8px;
    display: unset;
  }

  .savingsPercentage {
    font-family: $ff-sans;
    font-size: 14px;
    line-height: 16px;
    color: $color-ace-blue;
    margin-left: 6px;
  }

  .saving,
  .earning {
    color: $color-ace-blue;
  }

  .losses {
    color: $color-singularity-red;
  }
}

.differenceInfoTag {
  display: flex;
  align-items: center;
  color: $color-ashy;
  font-size: 1.1rem;

  p {
    font-family: 'NB Akademie Std', sans-serif;
    color: $color-ashy;
    font-weight: 500;
    font-size: 13px;
    line-height: 14px;
  }

  span {
    font-weight: $fw-bold;
  }
}
