@import 'src/assets/styles/utils/index.module.scss';

.background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $z-modal-auth - 1;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(57px);
}

@-moz-document url-prefix() {
  .background {
    background: rgba(255, 255, 255, 0.7);
  }
}

.modal {
  top: 50%;
  left: 50%;
  z-index: $z-modal-auth;
  border-radius: 6px;
  // background: linear-gradient(10.13deg, #211e21 0.29%, rgba(33, 30, 33, 0.94) 99.02%);
  background: #232023;
  transform: translate3d(-50%, -50%, 0);

  @media (max-width: 500px) {
    top: 0%;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
    transform: unset;
  }
}

.closeBtn {
  position: absolute;
  top: 25px;
  right: 15px;
}

.formSwitches {
  display: flex;
}

.formSwitch {
  &:first-child {
    padding-right: 15px;
  }

  & + & {
    margin-left: 30px;
  }
}

.formLogin {
  margin-top: 55px;
}

.formSignup {
  margin-top: 20px;
}

.toast {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: var(--min-toast-height);
  padding: 25px;
  color: $color-snow-in-april;
  font-size: 1.4rem;
  text-align: center;
}

.forgotPassword {
  margin-top: 1rem;
  color: $color-snow-in-april;

  .forgotPasswordCTA {
    color: $color-soft-ace-blue;
  }
}

.heading {
  margin-bottom: 40px;
  color: $color-snow-in-april;
  font-weight: $fw-bold;
  font-size: 1.4rem;
}

.heading2 {
  color: $color-snow-in-april;
  font-weight: $fw-bold;
  font-size: 2.4rem;
}

.termsWrapper {
  padding: 30px 25px 20px;
}

.termsHeader {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  .heading2 {
    color: #6e6;
  }
}

.gsLogo {
  width: 73px;
  height: 58px;
  margin-right: 25px;
}

.termsContent {
  height: 500px;
  max-height: calc(100vh - 200px);
  color: $color-snow-in-april;
  font-size: 1.2rem;
  line-height: 1.33;

  h3 {
    margin-bottom: 13px;
    font-size: 1.3rem;

    &:not(:first-child) {
      margin-top: 27px;
    }
  }

  ol {
    padding-left: 2em;
    list-style-type: decimal;
  }

  ul {
    // margin: 15px 0;
    padding-left: 14px;
    list-style-type: disc;
  }

  li {
    margin: 5px 0;
  }
}

.termsFooter {
  display: flex;
  align-items: center;
}

.termsBtn {
  flex-grow: 1;
  min-width: 270px;
  margin-top: 20px;

  & + & {
    margin-left: 22px;
  }
}

.headerSpacing {
  padding-top: 8rem !important;
}

.socialBtnsContainer {
  display: flex;
  justify-content: space-evenly;
}

.orDivider {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-snow-in-april;
  margin: 3rem 0;

  &::after,
  &::before {
    content: '';
    display: block;
    background: #4a4a4a;
    width: 100%;
    height: 1px;
    margin: 0 10px;
  }
}

.joinCommunityHeader {
  background: $color-ashy;
  height: 75px;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: $color-snow-in-april;
  }
}
