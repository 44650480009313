@import 'src/assets/styles/utils/index.module.scss';

.container {
}

.canvasContainer {
  // https://online-free-tools.com/en/css_pattern_generator_background
  background-image: radial-gradient(circle, rgba($color-metal-grey, 0.3) 10%, transparent 10%), radial-gradient(circle, rgba($color-metal-grey, 0.3) 10%, transparent 10%);
  background-position: 0 0, 0 0;
  background-size: 5px 5px;
}

.legend {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0 5px;
  padding-right: 5px;
  font-weight: $fw-medium;
  font-size: 11px;
  line-height: 13px;
  white-space: nowrap;
}

.legendItem {
  display: flex;
  align-items: flex-end;
}

.legendBadge {
  width: 2px;
  height: 12px;
  margin-right: 5px;
  background-color: var(--bg-color);
}

.legendLabel {
  margin-right: 10px;
  font-family: $ff-sans;
}
