@import 'src/assets/styles/utils/index.module.scss';

.box {
  display: flex;
  align-items: center;
  height: 30px;
  padding: 0 25px;
  border-radius: 5px;
  background-color: $color-vape;

  span {
    color: $color-pretty-dark;
    font-weight: 500;
    font-size: 1.3rem;
  }
}
