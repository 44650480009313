@import 'src/assets/styles/utils/index.module.scss';

.container {
  width: var(--container-width);
  height: auto;
  border: 1px solid rgba(52, 54, 63, 0.09);
  border-radius: 6px;
  background-color: rgba(251, 249, 249, 0.9);
  backdrop-filter: blur(8px);

  &.smallDropdown {
    .item::after {
      left: 13px;
      background-color: $color-london-fog;
    }

    button {
      padding: 0 15px 0 28px;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 1.5rem;
    }
  }

  &.bigDropdown {
    .item::after {
      right: 14px;
    }

    button {
      padding: 0 13px;
      font-weight: 900;
      font-size: 1.7rem;
      font-family: $ff-mono;
      line-height: 2rem;
    }
  }
}

.list {
  li {
    position: relative;
    height: 52px;

    .item {
      height: 100%;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        width: 7px;
        height: 7px;
        border-radius: 100%;
        transform: translateY(-50%);
      }

      &.itemActive::after {
        background-color: $color-oh-so-green;
      }
    }

    &:nth-of-type(2n +2) {
      background: rgba($color-ashy, 0.06);
    }

    button {
      width: 100%;
      height: 100%;
      text-align: left;
    }
  }
}
