@import 'src/assets/styles/utils/index.module.scss';

$padding: 10px;

.modal {
  position: relative;
  top: unset;
  left: unset;
  border-radius: 8px;
}

.container {
  margin-top: 1rem;
  border-radius: 10px;
  background-color: $color-snow-in-april;
}

.paddingLR {
  padding: 0 $padding 0 $padding;
}

header.communityAssetsHeader {
  display: flex;
  align-items: center;
  padding: $padding;

  h3 {
    flex: 1;
    font-weight: bold;
    font-size: 1.5rem;
  }
}

.viewActionsWrapper {
  button {
    margin-right: 0.5rem;
    padding: 0.5rem;
    border-radius: 50%;
    background-color: $color-metal-grey;
    color: $color-snow-in-april;

    &.bottomAligned {
      svg {
        transform: translateY(3px);
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
