@import 'src/assets/styles/utils/index.module.scss';

.container > * {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.bidsAndOfferTags {
  margin-bottom: 10px;
}

.select {
  min-width: 125px;
}
