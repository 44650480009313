/* GT Cinetype Mono */
@font-face {
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-family: 'GT Cinetype Mono';
  src: url('src/assets/fonts/GT-Cinetype-Mono.otf') format('opentype'),
    url('src/assets/fonts/GT-Cinetype-Mono.ttf') format('truetype');
}

/* GT Cinetype */
@font-face {
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  font-family: 'GT Cinetype';
  src: url('src/assets/fonts/GT-Cinetype-Bold.otf') format('opentype'),
    url('src/assets/fonts/GT-Cinetype-Bold.ttf') format('truetype');
}

@font-face {
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
  font-family: 'GT Cinetype';
  src: url('src/assets/fonts/GT-Cinetype-Bold-Italic.otf') format('opentype'),
    url('src/assets/fonts/GT-Cinetype-Bold-Italic.ttf') format('truetype');
}

@font-face {
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-family: 'GT Cinetype';
  src: url('src/assets/fonts/GT-Cinetype-Regular.otf') format('opentype'),
    url('src/assets/fonts/GT-Cinetype-Regular.ttf') format('truetype');
}

@font-face {
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
  font-family: 'GT Cinetype';
  src: url('src/assets/fonts/GT-Cinetype-Regular-Italic.otf') format('opentype'),
    url('src/assets/fonts/GT-Cinetype-Regular-Italic.ttf') format('truetype');
}

@font-face {
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  font-family: 'GT Cinetype';
  src: url('src/assets/fonts/GT-Cinetype-Light.otf') format('opentype'),
    url('src/assets/fonts/GT-Cinetype-Light.ttf') format('truetype');
}

@font-face {
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  font-family: 'GT Cinetype';
  src: url('src/assets/fonts/GT-Cinetype-Light-Italic.ttf') format('truetype');
}

/* NB Akademie Std */
@font-face {
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  font-family: 'NB Akademie Std';
  src: url('src/assets/fonts/NB Akademie Std Black.otf') format('opentype');
}

@font-face {
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
  font-family: 'NB Akademie Std';
  src: url('src/assets/fonts/NB Akademie Std Black Italic.otf') format('opentype');
}

@font-face {
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  font-family: 'NB Akademie Std';
  src: url('src/assets/fonts/NB Akademie Std Bold.otf') format('opentype');
}

@font-face {
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
  font-family: 'NB Akademie Std';
  src: url('src/assets/fonts/NB Akademie Std Bold Italic.otf') format('opentype');
}

@font-face {
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  font-family: 'NB Akademie Std';
  src: url('src/assets/fonts/NB Akademie Std Medium.otf') format('opentype');
}

@font-face {
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
  font-family: 'NB Akademie Std';
  src: url('src/assets/fonts/NB Akademie Std Medium Italic.otf') format('opentype');
}

@font-face {
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-family: 'NB Akademie Std';
  src: url('src/assets/fonts/NB Akademie Std Regular.otf') format('opentype');
}

@font-face {
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
  font-family: 'NB Akademie Std';
  src: url('src/assets/fonts/NB Akademie Std Italic.otf') format('opentype');
}

@font-face {
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  font-family: 'NB Akademie Std';
  src: url('src/assets/fonts/NB Akademie Std Light.otf') format('opentype');
}

@font-face {
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
  font-family: 'NB Akademie Std';
  src: url('src/assets/fonts/NB Akademie Std Light Italic.otf') format('opentype');
}
/* ROBOTO */
@font-face {
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  font-family: 'Roboto';
  src: url('src/assets/fonts/Roboto-Black.ttf') format('truetype');
}

@font-face {
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
  font-family: 'Roboto';
  src: url('src/assets/fonts/Roboto-BlackItalic.ttf') format('truetype');
}

@font-face {
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  font-family: 'Roboto';
  src: url('src/assets/fonts/Roboto-Bold.ttf') format('truetype');
}

@font-face {
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
  font-family: 'Roboto';
  src: url('src/assets/fonts/Roboto-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  font-family: 'Roboto';
  src: url('src/assets/fonts/Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
  font-family: 'Roboto';
  src: url('src/assets/fonts/Roboto-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-family: 'Roboto';
  src: url('src/assets/fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
  font-family: 'Roboto';
  src: url('src/assets/fonts/Roboto-Italic.ttf') format('truetype');
}

@font-face {
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  font-family: 'Roboto';
  src: url('src/assets/fonts/Roboto-Light.ttf') format('truetype');
}

@font-face {
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
  font-family: 'Roboto';
  src: url('src/assets/fonts/Roboto-LightItalic.ttf') format('truetype');
}

@font-face {
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
  font-family: 'Roboto';
  src: url('src/assets/fonts/Roboto-Thin.ttf') format('truetype');
}

@font-face {
  font-weight: 100;
  font-style: italic;
  font-stretch: normal;
  font-family: 'Roboto';
  src: url('src/assets/fonts/Roboto-ThinItalic.ttf') format('truetype');
}
