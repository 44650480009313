@import 'src/assets/styles/utils/index.module.scss';

// create a wrapper div for the bottom right corner copyright
.bottomRightCopyright {
  position: absolute;
  bottom: 0;
  right: 0;
  display: inline-block;
  height: 30px;
  padding: 10px;
  background: $color-snow-in-april;
  font-size: 12px;
  text-align: center;
  z-index: 1;
  color: $color-metal-grey;
  font-size: 12px;
  font-weight: bold;
}
