@import 'src/assets/styles/utils/index.module.scss';

.container {
  position: relative;
  z-index: $z-header;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 70px;
  padding: 0 15px;
  background-color: $color-white;
}

.pageTitle {
  position: absolute;
  left: 50%;
  font-weight: bold;
  transform: translateX(-50%);
}

.nav {
  button {
    margin-left: 12px;
  }
}
