@import 'src/assets/styles/utils/index.module.scss';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: $color-snow-in-april;

  @media (min-width: 500px) {
    height: 100%;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 68px;
  padding: 0 14px;
  margin-bottom: 0 !important;
  &.headerNonLogin {
    justify-content: left;
    button {
      margin-right: 1rem;
    }
  }
  h3 {
    font-size: 1.8rem;
  }

  & > div {
    flex-shrink: 0;
  }
}

.subtabHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 0 10px;
  margin-bottom: 0 !important;

  h3 {
    font-size: 1.4rem;
  }

  & > div {
    flex-shrink: 0;
  }
}

.graphsList,
.settingsList,
.registry {
  overflow-y: scroll;
  height: 100%;
  padding: 0 14px;
}

.graphsList {
  & > div {
    margin-bottom: 20px;
  }
}

.noResults {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px 0;
}

.settingsList {
  overflow-y: scroll;
  height: 100%;
  padding: 0 14px;
}

.headerNav {
  display: flex;
  font-size: 1.6rem;
  width: 100%;

  li {
    margin-right: 20px;
    padding: 4px 0;
    color: $color-metal-grey;

    &.communityName {
      overflow: hidden;

      button {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &.active {
      border-bottom: 2px solid $color-oh-so-green;
      color: $color-pretty-dark;
    }
    &.hasNotifications {
      color: $color-pretty-dark;
    }
  }
}

.sidebarIconButton {
  position: absolute;
  right: 8px;
}

.formButtonsWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 13px;
  margin-bottom: 28px;
}

.formButton {
  width: 156px;
}

.settingsActionButton {
  justify-content: center;
  width: 100%;
  padding: 0;
  background-color: transparent;
  color: $color-kinda-dirty;
  font-size: 1.4rem;
  line-height: 1.6rem;
}

.settingsActionButtonBorder {
  border-bottom: 1px solid $color-london-fog;
}

.boxSidebarAlert {
  display: flex;
  align-items: center;
  width: calc(100% - 30px);
  margin: 0 auto;
  padding: 12px;
  border-radius: 4px;
  background-color: $color-pretty-dark;
  margin-bottom: 15px;

  h3 {
    color: $color-snow-in-april;
    font-weight: $fw-bold;
    font-size: 1.25rem;
  }

  p {
    margin-top: 5px;
    color: $color-metal-grey;
    font-weight: $fw-regular;
    font-size: 1.1rem;
  }
}

.iconCanary {
  border-radius: 4px;
  box-shadow: 3px 0 12px rgba(22, 65, 247, 0.8);
}
.boxText {
  margin: 0 10px;
  flex-grow: 10;
}

.buttonGo {
  width: 36px;
  height: 33px;
  flex-grow: 1;
}
