@import 'src/assets/styles/utils/index.module.scss';

.container {
  position: relative;
  width: 100%;
  margin-right: -10px;
  margin-bottom: -10px;
}

.breadcrumbsWrapper {
  display: inline-flex;
  flex-wrap: wrap;
}

.searchLocation {
  position: absolute;
  top: 0;
  right: 12px;
}

.breadcrumbsDropdown {
  z-index: 2;
}

.button {
  max-width: 100%;
  height: 32px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 5px;
  border: solid 1px $color-vape;
  border-radius: 4px;
  background: $color-vape;
  font-weight: $fw-medium;
  font-size: 1.2rem;

  &:hover {
    border-color: $color-oh-so-green;
  }
}

.isActive {
  border-color: $color-oh-so-green;
  background: $color-oh-so-green;
}
