@import 'src/assets/styles/utils/index.module.scss';

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: fit-content;
}

.modalControlButtons,
.simActionButtons {
  display: flex;

  & > button {
    margin-left: 8px;
  }

  .actionButton {
    width: 28px;
    height: 28px;
  }
}
