@import 'src/assets/styles/utils/index.module.scss';

$modalBorderRadius: 6px;

.background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $z-modal-auth - 1;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(24px);
}

@-moz-document url-prefix() {
  .background {
    background: rgba(255, 255, 255, 0.7);
  }
}

.modal {
  top: 50%;
  left: 50%;
  z-index: $z-modal-auth;
  border-radius: 6px;
  background: $color-ashy;
  transform: translate3d(-50%, -50%, 0);
}

h2.modalTitle {
  margin-bottom: 5px;
  color: $color-snow-in-april;
  font-weight: $fw-bold;
  font-size: 3rem;
  line-height: 3rem;
  text-align: center;
}

.modalWrapper {
  padding-top: 3.4rem;
  padding-bottom: 3.4rem;

  .stepsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 2.5rem;
  }
}

.closeBtn {
  position: absolute;
  top: 16px;
  right: 16px;
}

.buttonsWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.modalContentWrapper {
  background: $color-pretty-dark;
  border-radius: $modalBorderRadius;
  border-radius: $modalBorderRadius;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 280px;
  align-content: center;
  justify-content: center;

  h3 {
    margin-bottom: 10px;
    color: $color-snow-in-april;
    font-size: 1.6rem;
    text-align: center;

    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
  }

  p {
    color: $color-snow-in-april;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
  }

  svg {
    color: $color-oh-so-green;
    margin-bottom: 13px;
  }
}
