@import 'src/assets/styles/utils/index.module.scss';

.modal {
  top: auto;
  bottom: 15px;
  left: 50%;
  color: $color-snow-in-april;
}

.modalLocationSearch {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  padding: 14px;
  border-radius: 8px;
  background: linear-gradient(10.13deg, $color-pretty-dark 0.29%, rgba(33, 30, 33, 0.94) 99.02%);
  backdrop-filter: blur(20.3526px);
}

.header {
  margin-bottom: 12px;
  font-weight: $fw-bold;
  font-size: 1.6rem;
  line-height: 1.7rem;
}

.title {
  margin-bottom: 12px;
  font-weight: $fw-medium;
  font-size: 1.3rem;
  line-height: 1.4rem;
}

.search {
  margin-bottom: 12px;

  svg {
    color: $color-black;
  }
}

.closeBtn {
  position: absolute;
  top: 15px;
  right: 14px;
}

.clickAnywhereTooltip {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 53px;
  padding: 0 19px;
  border-radius: 8px;
  background-color: $color-pretty-dark;

  p {
    flex: 1;
    font-size: 1.6rem;
    line-height: 1.7rem;
    text-align: center;
  }

  svg {
    color: $color-oh-so-green;
  }
}
