@import 'src/assets/styles/utils/index.module.scss';

.pillsWrapper {
  display: flex;
}

.pillButton {
  border-radius: 15px;
  background-color: $color-white;
  color: $color-metal-grey;
  font-weight: $fw-medium;
  font-size: 1.1rem;

  &.active {
    color: $color-ashy;
  }
}

.themeGreen {
  .pillButton {
    &.active {
      background-color: $color-oh-so-green;
    }
  }
}

.themeGray {
  .pillButton {
    &.active {
      background-color: $color-metal-grey;
    }
  }
}

.themeDarkGray {
  .pillButton {
    color: $color-kinda-dirty;

    &.active {
      background-color: $color-ashy;
      color: $color-snow-in-april;
    }
  }
}

.sizeLarge {
  .pillButton {
    width: 52px;
    height: 20px;

    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}

.sizeNormal {
  .pillButton {
    width: 42px;
    height: 20px;

    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}

.sizeMini {
  .pillButton {
    width: 34px;
    height: 17px;

    &:not(:last-child) {
      margin-right: 4px;
    }
  }
}

.sizeMicro {
  .pillButton {
    width: 31px;
    height: 16px;
    background-color: transparent;

    &:not(:last-child) {
      margin-right: 4px;
    }
  }
}
