@import 'src/assets/styles/utils/index.module.scss';

.content {
  overflow-y: scroll;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0 70px;
  -webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 3s; /* Firefox < 16 */
  -ms-animation: fadein 3s; /* Internet Explorer */
  -o-animation: fadein 3s; /* Opera < 12.1 */
  animation: fadein 3s;

  p {
    margin-top: 4% ;
  }

  @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }

  /* Firefox < 16 */
  @-moz-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }

  /* Internet Explorer */
  @-ms-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }

  /* Opera < 12.1 */
  @-o-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }

  transition: filter 0.2s;
  &:hover {
    color: $color-london-fog;
  }

  @media only screen and (min-width: 300px) and (max-width: 900px) {
    padding: 5% 30px 15% 30px;
  } 
}

.content::after {
  position: fixed;
  content: " ";
  bottom: 0;
  right: 0;
  width: 50%;
  z-index: 1;
  background-image: linear-gradient(transparent, $color-ace-blue);
  height: 90px;

  @media only screen and (min-width: 300px) and (max-width: 900px) {
    width: 100%;
  } 
}

.boxText {
  color:$color-snow-in-april;
  font-family: $ff-sans-landingPage;
  font-weight: $fw-regular;
  font-size: 2.4rem;
  line-height: 3.6rem;

  a {
    color: $color-oh-so-green;
    cursor: pointer;
    border-bottom: 2px solid $color-oh-so-green;

    transition: filter 0.2s;
    &:hover {
    filter: brightness(0.8);
    }
  }

  @media only screen and (min-width: 300px) and (max-width: 900px) {
    font-size: 2.2rem;
    line-height: 3.2rem;
  } 
}

.boxIcons {
  display: flex;
  min-width: 230px;
  align-items: center;
  justify-content: space-between;
  margin-top: 8%;
  margin-left: 0;
  align-self: flex-start;
  margin-bottom: 60px;
}

.icons {
  color: $color-snow-in-april;
  cursor: pointer;
}


