@import 'src/assets/styles/utils/index.module.scss';

.container {
  width: 100%;
  visibility: var(--visibility);
}

.inline {
  display: inline-flex;
  width: calc(50% - 5px);
}

.inline-left {
  margin-right: 5px;
}

.inline-right {
  margin-left: 5px;
}
