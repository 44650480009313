@import 'src/assets/styles/utils/index.module.scss';

.container {
  position: absolute;
  top: $header-height + 45px;
  right: 20px;
  z-index: 10;

  svg {
    position: relative;
  }

  button {
    z-index: 1000;
    position: relative;
  }
}

.containerEmbed {
  top: 30px;
}

.buttonBase {
  border-radius: 4px;
  background: $color-white;
  box-shadow: 6px 6px 14px rgba(0, 0, 0, 0.09);
}

.centerFlex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.aloneButton {
  width: 33px;
  height: 34px;
  composes: buttonBase centerFlex;

  & + & {
    margin-top: 6px;
  }

  &.compassButton {
    border-radius: 100%;
  }

  &.geolocateButton {
    z-index: 1002;
    position: relative;
  }

  &.onboardingButton {
    margin-bottom: 6px;

    &::after {
      content: '';
      position: absolute;
      top: 8px;
      right: -5px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: $color-oh-so-green;
    }
  }
}

.buttonGroup {
  margin-top: 9px;
  padding: 4px 3px;
  composes: buttonBase;
  z-index: 1000;
  position: relative;

  > div {
    position: relative;
    z-index: 1000;
  }
}

.groupedBtnSize {
  width: 28px;
  height: 28px;
}

.buttonInsideGroup {
  position: relative;
  transition: color 0.2s $ease-in-out-quad;
  composes: centerFlex groupedBtnSize;
}

.buttonInsideGroupActive {
  color: $color-white;
}

.buttonAddMore {
  margin-top: 6px;

  .active {
    background-color: $color-pretty-dark;
    color: $color-oh-so-green;

    &::after {
      display: none;
    }
  }
}

.separator {
  width: 64%;
  margin: 6px auto;
  border: thin solid $color-metal-grey;
}

.btnBackground {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
  background: $color-pretty-dark;
  transition: transform 0.2s $ease-in-out-quad;
  composes: groupedBtnSize;
}
