@import 'src/assets/styles/utils/index.module.scss';

.maxHeight {
  max-height: 250px;
}

.assetIcon {
  height: auto;
  top: 80px;
}

.topLeftTag {
  p {
    margin: 0 4px;
  }

  span {
    font-weight: $fw-bold;
  }

  .badge {
    display: flex;
    align-items: center;
    height: 23px;
    padding: 0 5px;
    border-radius: 8px;
    color: $color-ashy;
    font-size: 1.1rem;
    margin: 10px 0;

    &.sold,
    &.trade {
      background-color: $color-oh-so-green;
    }

    &.bought {
      background-color: $color-singularity-red;
      color: $color-white;
    }
  }
}

.bottomRight {
  width: 55px;
  height: 55px;
}
