@import 'src/assets/styles/utils/index.module.scss';

.container {
  overflow-y: scroll;
  padding: 0 35px;
  -webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 3s; /* Firefox < 16 */
  -ms-animation: fadein 3s; /* Internet Explorer */
  -o-animation: fadein 3s; /* Opera < 12.1 */
  animation: fadein 3s;

  @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }

  /* Firefox < 16 */
  @-moz-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }

  /* Internet Explorer */
  @-ms-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }

  /* Opera < 12.1 */
  @-o-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }
  
  .watchMoreContainer {
    position: absolute;
    left: 35px;
    bottom: 40px;
    display: flex;
    align-items: center;
    z-index: 10;

    @media only screen and (max-width: 600px) {
      position: fixed;
      bottom: 50px;
    }
  
    .text {
      font-family: $ff-sans-landingPage;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 155%;
      color: $color-snow-in-april;
    }

    .youtubeLink {
      margin: 0 5px;
      font-weight: 500;
      font-size: 16px;
      line-height: 155%;
      color: $color-oh-so-green;
    }
  }

  @media only screen and (min-width: 300px) and (max-width: 900px) {
    padding: 0 30px;
  } 
}

.container::after {
  position: fixed;
  content: " ";
  bottom: 0;
  right: 0;
  width: 50%;
  z-index: 1;
  background-image: linear-gradient(transparent, $color-ace-blue);
  height: 120px;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }  
}

.videoContainer {
  overflow: hidden;
  position: relative;
  width:100%;
  margin-bottom: 50px;
}

.videoContainer::after {
  padding-top: 56.25%;
  display: block;
  content: '';
}

.videoContainer iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}