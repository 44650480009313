@import 'src/assets/styles/utils/index.module.scss';

html,
body,
#app-root {
  overflow: hidden;
  height: 100vh;
}

.landscape {
  transform: rotate(-90deg);
  transform-origin: left top;
  width: 100vh;
  overflow-x: hidden;
  position: absolute;
  top: 100%;
  left: 0;
}

.parallaxLayerLandscape {
  max-height: 100vw;
  overflow: hidden;
  width: 100%;
  display: flex;
  z-index: 3;
}

.parallaxLayer {
  max-height: 100vh;
  overflow: hidden;
  width: 100%;
  display: flex;
  z-index: 3;
}

.parallaxText {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-white;
  z-index: 11 !important;
}

.wrapper div {
  // z-index: 10;
}

.backgroundLayerLandscape {
  background-color: $color-pretty-dark;
  height: 100vw;
  width: 100vh;
  max-width: 100%;
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 0;
  top: 0px;
}

.backgroundLayer {
  background-color: $color-pretty-dark;
  height: 100vh;
  width: 100%;
  max-width: 100%;
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 0;
  top: 0px;
}

.waveBackground {
  height: 100vh;
  width: 100%;
  max-width: 100%;
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left 50vh bottom 60%;
  z-index: 1;
  top: 0px;

  @media only screen and (min-width: 1300px) and (max-width: 1500px) {
    background-position: left -47% bottom 64%;
    background-size: 1800px 1200px !important;
  }

  @media only screen and (min-width: 900px) and (max-width: 1299px) {
    background-position: left 25% bottom 54%;
  }
}

.layerTitle {
  z-index: 10;
  // max-width: 50%;
  // max-height: 35%;
  // overflow: hidden;
}

.bottomToTopFade {
  position: fixed;
  left: 50%;
  z-index: 21 !important;
  bottom: 20px;
  -webkit-animation: slideInFade 6s forwards;
  -moz-animation: slideInFade 6s forwards;
  animation: slideInFade 6s forwards;
}

.scrollBtn {
  background: none;
  transform: translateX(-50%) translateY(-50%);

  span {
    font-family: 'NB Akademie Std', sans-serif;
    font-weight: $fw-medium;
    letter-spacing: 1px;
    font-size: 2.7rem;
    margin-right: 16px;
    transition: filter 0.2s;
  }
}

.buttonScroll {
  color: $color-snow-in-april;
}

.boxActionsTop {
  top: 30vw !important;
}

.boxActions {
  justify-content: space-between;
  position: absolute;
  display: flex;
  left: 20px;
  top: 60vw;
  flex-wrap: wrap;
  z-index: 21;

  .action {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: $color-snow-in-april;
    margin-bottom: 10px;

    span {
      font-size: $ff-sans-secondary;
      color: $color-snow-in-april;
      font-size: 16px;
      line-height: 16px;
      margin: 0 8px;
    }
  }
}

.colorOposite {
  color: $color-snow-in-april !important;
}

.boxCustomSize {
  height: 106px;
}
