.react-datepicker {
  padding: 10px;
}
.react-datepicker .react-datepicker__navigation {
  position: absolute;
  top: 18px;
}
.react-datepicker .react-datepicker__navigation--previous {
  left: 8px;
}
.react-datepicker .react-datepicker__navigation--next {
  right: 8px;
}
.react-datepicker .react-datepicker__header {
  display: flex;
  flex-direction: column;
}
.react-datepicker .react-datepicker__header .react-datepicker__current-month {
  height: 30px;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
}
.react-datepicker .react-datepicker__header .react-datepicker__day-names {
  display: flex;
}
.react-datepicker .react-datepicker__header .react-datepicker__day-names > * {
  flex: 1;
  height: 30px;
  line-height: 30px;
  text-align: center;
}
.react-datepicker .react-datepicker__month {
  display: flex;
  flex-direction: column;
}
.react-datepicker .react-datepicker__month .react-datepicker__week {
  display: flex;
}
.react-datepicker .react-datepicker__month .react-datepicker__week > * {
  flex: 1;
  height: 30px;
  line-height: 30px;
  text-align: center;
}

.themeLight .react-datepicker {
  position: relative;
  border: thin solid #efeff1;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #fbf9f9;
  color: #34363f;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.03);
}
.themeLight
  .react-datepicker
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day {
  font-weight: 700;
  cursor: pointer;
}
.themeLight
  .react-datepicker
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day--disabled {
  color: #d1cfcf;
  font-weight: 400;
  cursor: not-allowed;
}
.themeLight
  .react-datepicker
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day--selected {
  position: relative;
}
.themeLight
  .react-datepicker
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day--selected::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  border: thin solid #6e6;
  border-radius: 100%;
  transform: translate(-50%, -50%);
}

.themeDark .react-datepicker {
  position: relative;
  border: thin solid #efeff1;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #fbf9f9;
  color: #34363f;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.03);
}
.themeDark
  .react-datepicker
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day {
  font-weight: 700;
  cursor: pointer;
}
.themeDark
  .react-datepicker
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day--disabled {
  color: #d1cfcf;
  font-weight: 400;
  cursor: not-allowed;
}
.themeDark
  .react-datepicker
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day--selected {
  position: relative;
}
.themeDark
  .react-datepicker
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day--selected::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  border: thin solid #6e6;
  border-radius: 100%;
  pointer-events: none;
  transform: translate(-50%, -50%);
}

.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker__input-container {
  height: 100%;
}
.react-datepicker-popper {
  top: 100% !important;
  z-index: 150;
  width: 100%;
  transform: none !important;
}
.react-datepicker {
  padding: 10px;
}
.react-datepicker .react-datepicker__navigation {
  position: absolute;
  top: 18px;
}
.react-datepicker .react-datepicker__navigation--previous {
  left: 8px;
}
.react-datepicker .react-datepicker__navigation--next {
  right: 8px;
}
.react-datepicker .react-datepicker__header {
  display: flex;
  flex-direction: column;
}
.react-datepicker .react-datepicker__header .react-datepicker__current-month {
  height: 30px;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
}
.react-datepicker .react-datepicker__header .react-datepicker__day-names {
  display: flex;
}
.react-datepicker .react-datepicker__header .react-datepicker__day-names > * {
  flex: 1;
  height: 30px;
  line-height: 30px;
  text-align: center;
}
.react-datepicker .react-datepicker__month {
  display: flex;
  flex-direction: column;
}
.react-datepicker .react-datepicker__month .react-datepicker__week {
  display: flex;
}
.react-datepicker .react-datepicker__month .react-datepicker__week > * {
  flex: 1;
  height: 30px;
  line-height: 30px;
  text-align: center;
}
