@import 'src/assets/styles/utils/index.module.scss';

.container {
}

.buttonReset {
  width: 100%;
}

.inputEmail {
  margin: 23px 0;
}

.errorMessage {
  margin: 1rem 0;
  color: $color-singularity-red;
}

.successMessage {
  margin: 1rem 0;
  color: $color-oh-so-green;
}
